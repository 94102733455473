/*
 * generic styles for the table component ProductItemTable used for
 * > cart
 * > label list
 * should be used for:
 * > order list
 */

.productItemTableHeadingWrapper {
  @media (max-width: @screen-sm-max) {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: @spacing-sm;
  }
}

.productItemTableHeadingAside {
  @media (max-width: @screen-sm-max) {
    flex-wrap: wrap;

    > * {
      width: 100%;
      margin-bottom: @spacing-s;
    }
  }

  @media (min-width: @screen-md-min) {
    > * + * {
      margin-left: @spacing-s;
    }
  }
}

.productItemTableHeading {
  display: flex;
  margin-bottom: @spacing-s;

  .productItemTableHeadingWrapper {
    order: 2;
  }

  .productItemTableHeadingAside {
    display: flex;
    flex-direction: column;
    order: 1;
    margin-bottom: @spacing-s;
  }

  @media (max-width: @screen-sm-max) {
    flex-wrap: wrap;

    .productItemTableHeadingAside {
      width: 100%;
      flex-direction: column;
      margin-bottom: 0;
    }
  }

  @media (min-width: @screen-md-min) {
    justify-content: space-between;

    .productItemTableHeadingWrapper {
      order: 1;
    }

    .productItemTableHeadingAside {
      flex-direction: row;
      align-items: flex-start;
      order: 2;
    }
  }
}

.productItemTableHeadline {
  margin-bottom: 0;

  @media (max-width: @screen-sm-max) {
    display: inline-block;
  }
}

.productItemTableHeadInteractions {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .button__add-all-to-cart {
    margin-left: 10px;
  }

  @media (max-width: @screen-sm-max) {
    flex-wrap: wrap;
  }
}

.productItemTable {
  margin-bottom: @spacing-l;

  tr[data-row-key^="deadline-"].isExpandableRow.ant-table-row-level-0 {
    display: none;
  }

  // generic table header
  .@{ant-prefix}-table-thead .@{ant-prefix}-table-cell {
    padding: @spacing-xs @spacing-s;
    background-color: @primary-color;
    color: @white;
    font-size: @text-default;
    font-weight: @font-weight-bold;
  }

  // generics for header and body
  .@{ant-prefix}-table-thead,
  .@{ant-prefix}-table-tbody {
    tr {
      th,
      td {
        // align text by default to the right
        text-align: right;

        // align the first td left, if not a checkbox
        &:first-child:not(.@{ant-prefix}-table-selection-column) {
          text-align: left;

          & + .@{ant-prefix}-table-cell:not(:empty) {
            text-align: left;
          }
        }

        // align the cell after the checkbox left as well
        &.@{ant-prefix}-table-selection-column {
          text-align: center;

          & + .@{ant-prefix}-table-cell {
            text-align: left;
          }
        }
      }
    }
  }

  .@{ant-prefix}-table-tbody {
    .@{ant-prefix}-table-row {
      td {
        &.@{ant-prefix}-table-cell {
          &.@{ant-prefix}-table-selection-column {
            padding: @spacing-s 0 @spacing-s @spacing-s;
          }

          &:not(.@{ant-prefix}-table-selection-column) {
            padding: @spacing-s;
          }
        }
      }

      &[data-row-key^="deadline"] {
        // hide the checkbox in deadline headline ;O)
        .@{ant-prefix}-table-selection-column label {
          display: none;
        }

        td {
          background: @white;

          h3 {
            margin: @spacing-sm 0 @spacing-xxs 0;
          }
        }
      }

      //mixin
      .tdProductGroup;
    }
  }

  // align items top, but not the grouping rows
  .@{ant-prefix}-table-tbody
    .@{ant-prefix}-table-row:not(.isExpandableRow)
    .@{ant-prefix}-table-cell {
    vertical-align: top;
  }

  // overwrite display property of checkbox to keep th height aligned with inline text elements
  .@{ant-prefix}-table-selection {
    display: flex;
  }

  // remove margin & font-size of actual selection checkbox to prevent content bloating
  .@{ant-prefix}-checkbox-wrapper {
    margin: 0;
    font-size: 0;
  }

  /*
   * workaround to show spacings before product grouping rows
   */

  &.tableGroupSpacing {
    table {
      border-collapse: collapse;
    }

    [data-row-key^="productgroup"] {
      border-bottom: 0;

      @media (max-width: @screen-md-max) {
        border-top: @spacing-xs solid transparent;
      }

      @media (min-width: @screen-lg-min) {
        border-top: @spacing-m solid transparent;
      }
    }
  }
}

/* expandable rows */
.isExpandableRow {
  cursor: pointer;

  tr&.@{ant-prefix}-table-row-level-0 > .@{ant-prefix}-table-cell {
    background-color: @light-grey;
  }

  .iconToggle {
    display: inherit;
    margin-left: auto;
    color: @primary-color;

    .transitionWithReducedMotion();
  }

  &.isExpanded {
    .iconToggle {
      transform: rotate(180deg);
    }
  }

  .sumPrice {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    white-space: nowrap;
  }

  .totalPrice {
    font-size: @text-sm;
    line-height: normal;
  }
}
