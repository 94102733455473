.contact-page {
  display: flex;
  min-height: calc(100vh - @footer-height-desktop - @header-height-desktop);
  flex-direction: column;

  h1 {
    margin: var(--spacing-xl) auto;
    text-align: center;
  }

  .content-block {
    max-width: 870px;
  }

  .divider {
    background-color: var(--disabled-bg);
  }

  &__contact-person {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-m);

    picture.picture {
      img {
        width: 120px;
        min-width: 100px;
        height: 120px;
        min-height: 100px;
        border-radius: 50%;
      }
    }

    > .icon.user svg {
      width: 120px;
      min-width: 100px;
      height: 120px;
      min-height: 100px;
      background-color: var(--disabled-text);
      border-radius: 50%;
      color: var(--white);
    }

    > div:first-of-type {
      display: flex;
      flex-direction: column;

      > span {
        display: flex;
        gap: var(--spacing-s);

        .icon {
          width: var(--icon-size-medium);
          height: var(--icon-size-medium);

          path {
            fill: var(--disabled-text);
          }
        }

        .icon.phone {
          .iconCustomerDots path {
            fill: var(--white);
          }
        }
      }
    }

    @media screen and (max-width: @screen-md-max) {
      flex-direction: column;
    }
  }

  &__link {
    display: flex;
    width: 100%;
    max-width: 500px;
    // stylelint-disable-next-line
    height: max-content;
    align-items: center;
    justify-content: space-evenly;
    border: 0;
    margin: 0;
    color: var(--primary-color);
    font-size: var(--text-sm);

    img {
      width: 62px;
      height: 62px;
    }

    &__link-text {
      text-align: center;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  &__support {
    padding: var(--spacing-sm);
    margin-top: var(--spacing-sm);
    background-color: var(--secondary-color);

    .container-layout {
      padding-right: 0;
      padding-left: 0;
    }

    .content-block__icon {
      display: flex;
      justify-content: center;
      padding-bottom: var(--spacing-m);

      svg {
        max-height: calc(3 * var(--icon-size-large));
        color: var(--white);
      }
    }

    .content-block__header {
      border-color: var(--white);
    }

    .content-block__title {
      color: var(--white);
    }

    .content-block__content {
      color: var(--white);

      p:last-of-type .quick-link {
        display: flex;
        max-width: 400px;
        justify-content: center;
        padding: var(--spacing-s) var(--spacing-m);
        border-color: var(--white);
        margin: 0 auto;
        background-color: var(--white);
        color: var(--secondary-color);
        text-decoration: none;

        &:hover,
        :focus {
          border-color: var(--white);
          background-color: transparent;
          color: var(--white);
        }
      }
    }

    @media screen and (min-width: @screen-md-min) {
      padding: var(--spacing-xl) var(--spacing-2xl);
    }
  }

  @media screen and (max-width: @screen-md-max) {
    min-height: unset;
  }
}
