.badge-already-ordered {
  display: inline-flex;
  height: 17px;
  align-items: center;
  padding: 2px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  font-size: @text-xxs;
  line-height: 1;
}

.productCount {
  position: relative;
  width: 70px;
  max-width: 100%;
  grid-area: productCount;

  .productCountInner {
    display: flex;
    width: 100%;
    height: @input-height-base;
    align-items: center;
    border: 2px solid @primary-color;
    border-radius: 0;
    color: @text-dark-color;
    font-size: @text-sm;

    .setFixedInputHeight();

    &:hover,
    &:focus,
    &.ant-input-number-focused {
      border-right-width: 2px !important;
      // do not remove a box-shadow for UX reasons: we want to allow tab navigation asap and then we need that
      //box-shadow: none !important;
    }

    &.isHighlighted {
      border-color: @secondary-color;

      &:hover,
      &:focus {
        border-color: @secondary-color;
      }
    }

    &.disabledInput .ant-input-number-input {
      color: fade(@text-dark-color, 20%);
    }
  }

  &Msg {
    width: 215px;

    .ant-tooltip-arrow {
      display: none;
    }

    .ant-tooltip-inner {
      position: relative;
      padding: (@spacing-sm / 2) @spacing-sm;
      border: @border-width-base solid @warning-border;
      background-color: @warning-bg;
      box-shadow: 0 3px 6px rgb(0 0 0 / 16%);
      color: @primary-color;
    }

    &Close {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      background: transparent;
      color: @text-dark-color;
      cursor: pointer;

      .icon {
        position: absolute;
        top: @spacing-s;
        right: @spacing-s;
        width: var(--icon-size-extra-small);
        height: var(--icon-size-extra-small);
      }
    }
  }

  .amountNotAvailableMsg {
    position: absolute;
    z-index: @zIndex-menu;
    right: -50%;
    bottom: 60px;
    width: 215px;
    border-color: @warning-border;
    background-color: @warning-bg;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%);

    .ant-alert-close-icon {
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
      margin: 0;

      .anticon-close {
        position: absolute;
        top: @spacing-s;
        right: @spacing-s;
      }
    }
  }

  @media screen and (min-width: @screen-xs-min) {
    .amountNotAvailableMsg {
      left: -50%;
    }
  }

  &.weekplannerCellProductCount {
    flex-shrink: 0;

    .ant-input-number-input {
      width: 3ch;
      padding: 0;
      margin: 0 @spacing-s;
    }

    .setFixedInputHeight(@input-height-sm);

    .weekplannerCellProductCountInner {
      font-size: @text-default;

      &.isHighlighted {
        background-color: @highlight-color;
      }
    }
  }
}

.productCount.with-addons {
  display: flex;
  width: 100%;
  place-items: center;

  .badge-already-ordered {
    margin-left: var(--spacing-xs);
  }

  .ant-input-number-group {
    border: 0;
  }

  .ant-input-number-input {
    text-align: right;
  }

  .ant-input-number-group-addon {
    padding: 0;
    border: 0;
    margin: 0;
    background-color: unset;

    button {
      width: 100%;
      height: 100%;
      border: 0;
      margin: 0;
      background-color: var(--primary-color);
      border-radius: 0;
      color: var(--white);
      cursor: pointer;
      font-size: 16px;
    }
  }
}

.addToCart {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  grid-area: addToCart;

  .remember-cart {
    display: flex;

    .node-cart {
      display: flex;
      min-width: @btn-height-base;
      max-width: @btn-height-base;
      align-items: center;
      justify-content: center;
      background-color: @primary-color;

      .buttonActionForInput {
        // width and height minus borders from parent
        width: calc(@btn-height-base - @border-width-base);
        height: calc(@btn-height-base - 2 * @border-width-base);
      }

      .badge-cart {
        display: inline-flex;
      }
    }

    &.remember-cart-green {
      .node-cart {
        background-color: @secondary-color;
      }

      .productCountInner {
        border-color: @secondary-color;
      }
    }
  }

  /* preorder styles */
  &.addToCartPreorder {
    width: 95px;
    height: 40px;
    justify-content: center;
    background-color: var(--white);

    .badgeCountPreorder {
      background-color: @secondary-color-lighter;
    }

    .productCountPreorder {
      &.productListProductBasket {
        width: 92px;
        height: 35px;

        button {
          background-color: #bec093;
        }

        &.product-basket--not-loading {
          div {
            height: 35px;
          }
        }

        input {
          width: 3ch;
          height: 35px;
          padding: 0;
          margin: 0 10px;
          font-size: 16px;
        }
      }

      &.product-basket--loading {
        .ant-spin-nested-loading {
          .ant-spin-container {
            > div {
              height: 35px;
            }

            .ant-input-number-wrapper {
              height: 35px;

              > div {
                height: 35px;
              }
            }
          }
        }
      }

      .productCountInner {
        border-color: @secondary-color-lighter;
      }

      + .node-cart {
        border-top: 2px solid @secondary-color-lighter;
        border-right: 2px solid @secondary-color-lighter;
        border-bottom: 2px solid @secondary-color-lighter;
        background-color: @white;
      }
    }
  }
}
