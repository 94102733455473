.delivery-line {
  overflow: hidden;
  padding: var(--spacing-sm) var(--spacing-m);
  background-color: var(--disabled-bg);
  border-radius: 10px;
  color: var(--disabled-text);

  .delivery-line__header {
    .delivery-line__order-number {
      padding: var(--spacing-xxs) var(--spacing-xs);
      background-color: var(--table-grey);
      color: var(--white);
      font-size: var(--text-s);
      font-weight: bold;
      letter-spacing: 1px;
    }
  }

  .delivery-line__content {
    display: grid;
    align-content: center;
    align-items: center;
    grid-gap: 20px;
    // stylelint-disable-next-line
    grid-template-columns: 50px repeat(3, 1fr);

    @media only screen and (max-width: @screen-xs-max) {
      display: block;
    }

    svg {
      min-width: 50px;
      min-height: 50px;
      color: var(--table-grey);
    }

    .delivery-line__item {
      .delivery-line__label {
        display: block;
        margin-bottom: var(--spacing-xs);
        letter-spacing: 1px;
      }

      .delivery-line__value {
        display: block;
        color: var(--primary-color);
        font-weight: bold;
      }

      @media only screen and (max-width: @screen-xs-max) {
        margin-bottom: var(--spacing-s);
      }
    }
  }
}

.delivery-line--complete {
  // stylelint-disable-next-line
  background-color: tint(@secondary-color, 94%);

  .delivery-line__content svg {
    color: var(--secondary-color);
  }

  .delivery-line__content .delivery-line__item .delivery-line__date {
    color: var(--secondary-color);
  }
}

.delivery-line--incomplete {
  background-color: #fffcf4;

  .delivery-line__content svg {
    color: #f8c63e;
  }
}
