.foldoutMenu {
  position: fixed;
  z-index: @zIndex-menu;
  top: @header-height-mobile;
  bottom: 0;
  left: 0;
  width: 100%;
  height: calc(100vh - @header-height-mobile);
  background-color: @white;

  .foldoutMenuUser {
    left: unset;
  }

  &.foldoutMenuArrow {
    position: relative;
  }

  @media only screen and (min-width: @screen-md-min) {
    position: absolute;
    top: @header-height-desktop + @spacing-s;
    bottom: auto;
    left: auto;
    width: @spacing-7xl + @spacing-3xl;
    height: auto;
    padding: 0;
    box-shadow: 0 1px 10px rgb(@black 0.35);

    &:before {
      position: absolute;
      top: -@spacing-xs;
      width: 0;
      height: 0;
      border-width: 0 10px 5px;
      border-style: solid;
      border-color: transparent transparent @white transparent;
      content: "";
      transform: rotate(360deg);
    }
  }
}

.ant-popover {
  &:not(.contactPersonFooter, .product-basket-preorder__calendar_popover, .stocktaking-popconfirm, .cart-checkout-popconfirm, .expired-cart-line-delete-cart-popconfirm) {
    position: fixed;
    top: @spacing-2xl + @spacing-s !important;
  }

  .ant-popover-arrow {
    top: @spacing-xs + @spacing-xxs !important;
  }

  &.scrolledDownNavigation {
    top: @spacing-3xl !important;
    padding-top: 0;

    &.weekPlannerCustomNav {
      .ant-popover-arrow {
        top: -3px !important;
        transform: rotate(45deg) !important;
      }
    }

    .ant-popover-arrow {
      top: -2 * @spacing-xxs !important;
      box-shadow: none;
      transform: rotate(225deg);
    }
  }
}

@media only screen and (min-width: @screen-md-min) {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.foldoutMenuList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.foldoutMenuListEntry {
  &:not(:last-child) {
    border-bottom: 1px solid @primary-color;
  }

  .foldoutMenuItemIcon {
    display: flex;
    width: 25%;
    align-content: center;
    justify-content: center;

    .cls-1 {
      // stylelint-disable-next-line
      clip-path: unset;
    }
  }

  .foldoutMenuItemLink {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: @spacing-sm @spacing-s;
    background-color: transparent;
    color: @primary-color;

    .transitionWithReducedMotion();

    .icon {
      max-width: @spacing-2xl + @spacing-s;
      max-height: @spacing-l + @spacing-s;
    }

    &:focus,
    &:hover,
    &.isActive {
      background-color: @primary-color;
      color: @white;
      text-decoration: none;

      .iconTwoTone {
        --svg-primary-tone: @white;
        --svg-secondary-tone: @primary-color-lighter;
      }
    }
  }
}

.foldoutMenuItemTitle {
  font-size: @text-m;
}
