.expired-cart-mixin(@color) {
  // stylelint-disable-next-line
  background-color: tint(@color, 94%);

  a:active,
  a:hover,
  a:focus {
    // stylelint-disable-next-line
    background-color: tint(@color, 88%);
  }

  svg {
    color: @color;
  }

  .expired-cart-line__value {
    color: @color;
  }
}

.expired-cart-line {
  overflow: hidden;
  padding: var(--spacing-sm);
  background-color: var(--light-grey);
  border-radius: 10px;
  color: var(--disabled-text);

  svg {
    width: calc(@icon-size-default * 2);
    height: calc(@icon-size-default * 2);
    align-self: center;
    color: var(--table-grey);
    grid-area: cart;

    @media only screen and (max-width: @screen-xs-max) {
      width: @icon-size-large;
      height: @icon-size-large;
    }
  }

  .expired-cart-line__content {
    display: grid;
    grid-gap: 10px;
    grid-template-areas:
      "cart deliveryDate amount actions"
      "table table table table";
    // stylelint-disable-next-line
    grid-template-columns: 60px minmax(auto, 180px) auto auto;
    row-gap: 0;
    text-align: left;

    @media only screen and (max-width: @screen-xs-max) {
      // stylelint-disable-next-line
      grid-template-columns: 30px auto auto auto;
      row-gap: 0;
    }

    .ant-skeleton-active {
      grid-column: 1/-1;
    }
  }

  .expired-cart-line__delivery-date {
    grid-area: deliveryDate;

    @media only screen and (max-width: @screen-xs-max) {
      margin-bottom: var(--spacing-s);
    }
  }

  .expired-cart-line__amount {
    grid-area: amount;

    @media only screen and (max-width: @screen-xs-max) {
      margin-bottom: var(--spacing-s);
    }
  }

  .expired-cart-line-actions {
    display: flex;
    justify-content: right;
    grid-area: actions;

    svg {
      width: @icon-size-default;
      min-width: @icon-size-default;
      height: @icon-size-default;
      min-height: @icon-size-default;
      color: var(--primary-color);
    }

    .collapsed {
      transform: rotate(180deg);
    }

    @media only screen and (max-width: @screen-xs-max) {
      .ant-btn {
        max-width: 30px;
      }
    }
  }

  .expired-cart-line-table {
    margin-top: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
    grid-area: table;
  }

  .expired-cart-line__label {
    display: block;
    margin-bottom: var(--spacing-xs);
    letter-spacing: 1px;
  }

  .expired-cart-line__value {
    display: block;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .ant-skeleton {
    .ant-skeleton-content {
      ul {
        display: none !important;
      }

      .ant-skeleton-title {
        width: 100% !important;
        margin-bottom: 0 !important;
      }
    }
  }

  .expired-cart-line__table__sku,
  .expired-cart-line__table__amount {
    width: 8%;
    white-space: nowrap;
    @media only screen and (max-width: @screen-xs-max) {
      width: 20%;
    }
  }

  @media only screen and (max-width: @screen-xs-max) {
    grid-gap: 5px;
    // stylelint-disable-next-line
    grid-template-columns: 35px auto auto;
  }
}

.expired-cart-line--not-empty {
  .expired-cart-mixin(@orange);
}

.expired-cart-line-delete-cart-popconfirm {
  position: unset;
  top: unset;
  max-width: 250px;

  .ant-popover-inner-content {
    padding: var(--spacing-s) !important;
  }

  .ant-popover-arrow {
    top: -1px !important;
  }
}
