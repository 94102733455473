.deadline-widget-section {
  display: grid;
  overflow: hidden;
  width: 100%;
  height: 100%;
  padding: var(--spacing-sm) var(--spacing-m);
  margin-bottom: 0;
  border-radius: 10px;
  color: var(--disabled-text);
  grid-gap: 20px;
  // stylelint-disable-next-line
  grid-template-columns: 2fr 1fr 1fr;
  // stylelint-disable-next-line
  background-color: tint(@tertiary-color, 94%);

  @media only screen and (max-width: @screen-xs-max) {
    display: block;
  }

  .deadline-widget-section__item {
    @media only screen and (max-width: @screen-xs-max) {
      margin-bottom: var(--spacing-s);
    }
  }

  .ant-statistic {
    height: 100%;
    justify-self: center;

    @media only screen and (max-width: @screen-xs-max) {
      height: auto;
      justify-self: flex-start;
    }
  }

  .ant-statistic-title {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: var(--text-default);
    letter-spacing: 1px;
    line-height: initial;
  }

  .ant-statistic-content-value,
  .ant-statistic-content-suffix {
    font-size: var(--text-sm);
    font-weight: bold;
  }
}
