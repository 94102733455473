.impulse-top-menu {
  margin-top: var(--spacing-m);

  .ant-radio-button-wrapper {
    overflow: hidden;
    width: 120px;
    border: 3px solid var(--white) !important;
    color: black !important;
    font-weight: bold;
    letter-spacing: 1px;
    text-align: center;
    text-overflow: ellipsis;
    text-wrap: nowrap;

    @media screen and (min-width: @screen-md-max) {
      width: 170px;
    }
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):before {
    background-color: black;
  }

  .ant-radio-button-wrapper-checked {
    border: 3px solid black !important;
  }
}
