// padding-top
.pt-0 {
  padding-top: 0;
}

.pt-sm {
  padding-top: @spacing-sm;
}

.pt-l {
  padding-top: @spacing-l;
}

// padding
.p-0 {
  padding: 0;
}

.p-xs {
  padding: @spacing-xs;
}

.p-s {
  padding: @spacing-s;
}

.p-m {
  padding: @spacing-m;
}

// padding bottom
.pb-0 {
  padding-bottom: 0 !important;
}

// padding-left
.pl-0 {
  padding-left: 0;
}

.pl-xs {
  padding-left: @spacing-xs;
}

.pl-s {
  padding-left: @spacing-s;
}

.pl-m {
  padding-left: @spacing-m;
}

// padding-right
.pr-0 {
  padding-right: 0;
}

.pr-xs {
  padding-right: @spacing-xs;
}

.pr-s {
  padding-right: @spacing-s;
}

.pr-m {
  padding-right: @spacing-m;
}

.sm-pr-0 {
  @media only screen and (max-width: @screen-sm-max) {
    padding-right: 0;
  }
}

.sm-pr-m {
  @media only screen and (max-width: @screen-sm-max) {
    padding-right: @spacing-m;
  }
}

// margin-bottom
.mb-0 {
  margin-bottom: 0;
}

.mb-xs {
  margin-bottom: @spacing-xs;
}

.mb-s {
  margin-bottom: @spacing-s;
}

.mb-sm {
  margin-bottom: @spacing-sm;
}

.mb-m {
  margin-bottom: @spacing-m;
}

.mb-l {
  margin-bottom: @spacing-l;
}

.mb-xl {
  margin-bottom: @spacing-xl;
}

.mb-2xl {
  margin-bottom: @spacing-2xl;
}

.mb-3xl {
  margin-bottom: @spacing-3xl;
}

.mb-4xl {
  margin-bottom: @spacing-3xl;
}

.xs-mb-0 {
  @media only screen and (max-width: @screen-xs-max) {
    margin-bottom: 0;
  }
}

.sm-mb-0 {
  @media only screen and (max-width: @screen-sm-max) {
    margin-bottom: 0;
  }
}

.sm-mb-m {
  @media only screen and (max-width: @screen-sm-max) {
    margin-bottom: @spacing-m!important;
  }
}

.m-rl-auto {
  margin-right: auto;
  margin-left: auto;
}

// margin-left
.ml-0 {
  margin-left: 0;
}

.ml-xs {
  margin-left: @spacing-xs;
}

.ml-s {
  margin-left: @spacing-s;
}

.ml-m {
  margin-left: @spacing-m;
}

// margin-right
.mr-0 {
  margin-right: 0;
}

.mr-xs {
  margin-right: @spacing-xs;
}

.mr-s {
  margin-right: @spacing-s;
}

.mr-sm {
  margin-right: @spacing-sm;
}

.mr-m {
  margin-right: @spacing-m;
}

.sm-mr-0 {
  @media only screen and (max-width: @screen-sm-max) {
    margin-right: 0 !important;
  }
}

// margin-top
.mt-0 {
  margin-top: 0;
}

.mt-xxs {
  margin-top: @spacing-xxs;
}

.mt-xs {
  margin-top: @spacing-xs;
}

.mt-s {
  margin-top: @spacing-s;
}

.mt-sm {
  margin-top: @spacing-sm;
}

.mt-m {
  margin-top: @spacing-m;
}

.mt-l {
  margin-top: @spacing-l;
}

.mt-xl {
  margin-top: @spacing-xl;
}

.mt-2xl {
  margin-top: @spacing-2xl;
}

.mt-3xl {
  margin-top: @spacing-2xl;
}

.mt-4xl {
  margin-top: @spacing-4xl;
}

.height-m {
  height: @spacing-m;
}

.height-xl {
  height: @spacing-xl;
}

.height-full {
  height: 100%;
}

.width-m {
  width: @spacing-m;
}

.width-full {
  width: 100%;
}

.sm-with-full {
  @media only screen and (max-width: @screen-sm-max) {
    width: 100%;
  }
}

// used to remove outer paddings from layout.
.edgeToEdge {
  position: relative;
  right: -@grid-padding-mobile;
  left: -@grid-padding-mobile;
  min-width: calc(100% + @grid-padding-mobile + @grid-padding-mobile);
  @media only screen and (min-width: @screen-md-min) {
    position: static;
    min-width: auto;
    padding: 0;
  }
  @media only screen and (min-width: @screen-lg-min) {
    padding: 0 @grid-padding-mobile;
  }
}

.defaultInputHeight {
  line-height: @default-input-line-height;
}
