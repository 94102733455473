.editableUserProfile {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding-top: @spacing-m;

  .button_container {
    position: absolute;
    top: @spacing-m;
    right: 0;
  }
}

.buttonDeleteCompanyUser {
  svg {
    margin-right: @spacing-s;
  }

  button {
    padding-right: 0;
    padding-left: 0;
    font-size: @text-default;
  }
}

.buttonChangeUser {
  button {
    font-size: @text-sm;
  }
}

.buttonPasswordSend {
  button {
    font-size: @text-sm;
  }
}

.companyUserProfileForm {
  .ant-checkbox-wrapper-in-form-item .anticon {
    color: var(--disabled-text);
    cursor: help;
    font-size: var(--text-s);
  }
}

.companyUserProfileForm__delivery-dates {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}
