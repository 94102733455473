.weekplanner-items-header-pagination {
  display: flex;
  min-width: @weekplanner-item-product-width;
  flex: 1 0 0;
  flex-direction: row;
  gap: var(--spacing-xs);

  > div > span {
    padding-right: var(--spacing-s);
  }

  .ant-btn {
    display: flex;
    align-items: center;
    border: 0;

    &:first-of-type {
      rotate: 90deg;
    }

    &:last-of-type {
      rotate: -90deg;
    }

    &:hover,
    &:focus {
      background-color: unset;
    }
  }
}
