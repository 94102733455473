.container {
  &-navigation {
    display: flex;
    align-items: center;
    margin-top: @spacing-sm;
    margin-bottom: @spacing-sm;

    .icon {
      width: var(--icon-size-medium);
      height: var(--icon-size-medium);
      color: var(--primary-color);
    }

    &__controls {
      display: flex;
      width: 70%;
      justify-content: flex-end;
      margin-left: auto;

      @media only screen and (min-width: @screen-md-min) {
        width: 250px;
      }

      & > * + * {
        margin-left: @spacing-ml;
      }
    }
  }

  &-news {
    &__title {
      font-size: @text-l;
      font-weight: @font-weight-bold;
      letter-spacing: 2.4px;
      line-height: 38px;

      &--desktop {
        display: none;
      }
    }

    &__content {
      margin-top: 25px;
      font-size: @text-sm;
      font-weight: @font-weight-regular;
      letter-spacing: 0.36px;
      line-height: 24px;
    }

    &__card {
      .card-content {
        font-size: @text-sm;
        font-weight: @font-weight-regular;
        letter-spacing: 0.36px;
        line-height: 24px;
      }

      .card-no-content {
        .ant-card-body {
          padding: 0;

          .info-card--button {
            padding: 0 0 @spacing-ml @spacing-ml;
          }
        }
      }

      .button_container {
        margin: @spacing-m 0 0;
        font-size: @text-default;
        font-weight: @font-weight-regular;
        letter-spacing: 0;
        line-height: 26px;

        button {
          color: @primary-color;
        }
      }
    }
  }

  &-content {
    padding-top: @spacing-l;
  }

  &-slider {
    margin-top: @spacing-2xl;
  }
}

@media only screen and (min-width: @screen-md-min) {
  .container {
    &-news {
      display: flex;

      &__card {
        order: 1;
      }

      &__title {
        display: none;
        order: 2;
        font-size: @text-2xl;

        &--desktop {
          display: block;
        }
      }

      &__content {
        order: 3;
        margin-top: 0;
      }

      .date__no-card {
        text-align: right;
      }
    }
  }
}

.publish-date-no-card {
  font-weight: @font-weight-bold;
  text-align: right;
}
