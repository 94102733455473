/*
 * position this modal over the messages
 * layer (back to front)
 * page -> scannerViewModal -> messages -> scannerProductEditModal
 */

.scannerProductEditModalWrapper {
  z-index: @zindex-popover;
}

.scannerProductEditModal {
  .ant-modal-body {
    padding: @spacing-m @spacing-m 0 !important;
    line-height: @text-default-line-height !important;
  }

  .ant-modal-footer {
    padding: 0 @spacing-m @spacing-m !important;
    border-top: none !important;
    line-height: @text-default-line-height !important;
  }

  /*
   * push the modal down to reveal the underlying header
   * of the scanner modal
   */
  @media (min-width: @screen-md-min) {
    margin-top: @header-height-desktop;
  }
}

.scannerProductEditModalFooter {
  display: flex;

  &.scannerProductEditModalFooterWithButton {
    justify-content: flex-end;
  }

  &.scannerProductEditModalFooterWithButtons {
    justify-content: space-between;

    // shift container a bit to the right, exactly as wide as the image and grid is
    @media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
      margin-left: @product-image-width-md + @product-gap-md;
    }

    @media (min-width: @screen-lg-min) {
      margin-left: @product-image-width-lg + @product-gap-md;
    }
  }
}
