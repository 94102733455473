.impulseButton() {
  display: flex;
  height: 100px;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: var(--text-m);
  font-weight: bold;
  line-height: var(--text-m);
  text-align: unset;
  text-transform: uppercase;
  text-wrap: balance;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  svg {
    min-width: var(--button-icon-default-size);
    max-width: var(--button-icon-default-size);
    min-height: var(--button-icon-default-size);
    max-height: var(--button-icon-default-size);
    margin-right: var(--spacing-sm);

    .cls-1 {
      // stylelint-disable-next-line
      clip-path: view-box;
    }
  }
}
