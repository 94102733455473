.weekplanner-header-navigation {
  width: 100%;
  gap: var(--spacing-xs) !important;

  &__child-categories-first-level,
  &__child-categories-second-level {
    button {
      overflow: hidden;
      min-width: 120px;
      max-width: 250px;
      font-weight: var(--font-weight-bold);
      letter-spacing: 1px;
      text-transform: uppercase;

      > span {
        overflow: hidden;
        width: 100%;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      &:hover,
      &:focus {
        background-color: var(--primary-color);
        color: var(--white);
      }

      &.is-active {
        border-color: var(--primary-color);
        background-color: var(--primary-color);

        > span {
          color: var(--white);
        }
      }
    }
  }

  &__child-categories-first-level {
    button {
      color: var(--primary-color);
    }

    @media screen and (min-width: @screen-xl) {
      max-width: 40vw;
    }
  }

  &__child-categories-second-level {
    button {
      border-color: var(--table-borders);
      background-color: var(--table-borders);
      color: var(--white);
    }

    @media screen and (min-width: @screen-xl) {
      max-width: 60vw;
    }
  }
}
