.suggestItem {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  line-height: @line-height-base;

  .suggestItemLink {
    &:hover,
    &:focus {
      text-decoration: none;
    }

    display: flex;
    width: 100%;
  }

  .suggestItemCartInfo {
    white-space: break-spaces;
  }

  .suggestItemProductNameWrapper {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);

    svg.icon {
      width: var(--icon-size-extra-small);
      min-width: var(--icon-size-extra-small);
      height: var(--icon-size-extra-small);
      min-height: var(--icon-size-extra-small);
    }
  }

  .suggestItemProductName {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .suggestItemProductDetails {
    display: block;
    overflow: hidden;
    font-size: var(--text-s);
    text-overflow: ellipsis;
  }

  .suggestItemProductInfo {
    overflow: hidden;
    flex: 0 1 auto;
  }

  .productImageSuggest {
    align-self: center;
    margin-right: @spacing-xs;
  }
}

.searchIntendItem {
  display: flex;
  align-items: center;
  line-height: @line-height-custom;

  .searchIntendItemLink {
    &:hover,
    &:focus {
      color: var(--primary-color);
      text-decoration: none;
    }

    display: flex;
    width: 100%;
    color: var(--white);
  }

  .innerSearchIntendItemLabel {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .innerSearchIntendItem {
    overflow: hidden;
    flex: 0 1 auto;
  }

  .innerSearchIntendItemInfo {
    color: var(--white);
    font-size: var(--text-xxs);
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.suggest-title {
  padding: 5px 12px;
  color: var(--primary-color);
  cursor: default;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.36px;
  line-height: 26px;
}

.suggest-body {
  > div {
    &:hover,
    &:focus {
      background-color: #ede5ee;
    }

    a {
      padding: 0 12px;
    }
  }

  .suggest-item-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .suggest-item {
      display: flex;
      justify-content: space-between;
      color: gray;

      span {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-badge {
        align-content: center;
      }
    }
  }
}

.badge-suggestion-count {
  .ant-badge-count {
    background-color: var(--primary-color);
  }
}

.suggestDropdown {
  padding-top: 0;
  padding-bottom: 0;

  hr {
    width: 97%;
    margin: auto;
  }

  .ant-select-item-empty {
    text-align: center;
  }

  .ant-select-item-option-content {
    line-height: unset;
  }

  .searchIntendItem {
    padding: 5px 12px;
    background-color: var(--primary-color);
    inset-block-end: 0;

    &:hover,
    :focus {
      background-color: #ede5ee;
      color: var(--primary-color);

      .innerSearchIntendItemInfo,
      .searchIntendItemLink {
        color: var(--primary-color);
        .transitionWithReducedMotion();
      }
    }
  }

  .search_intend_brand {
    bottom: 50.5px;
  }

  .ant-select-item {
    padding: inherit;
  }

  .ant-select-item-option-active {
    background-color: inherit;
  }

  .suggestAddToCart {
    align-content: center;
    margin-left: auto;
  }

  .buttonAddToCart {
    width: 30px;
    min-width: 30px;
    height: 30px;

    svg {
      width: 20px;
    }

    .badge-cart {
      max-height: 32px;
    }

    .ant-badge-count {
      right: -5px;
      min-width: 15px;
      height: 15px;
      padding: 0 4.5px;
      line-height: 15px;
    }
  }
}
