.iframeContainer {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 1 / 1;

  &.iframeContainer--16-9 {
    aspect-ratio: 16 / 9;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
}
