.support-contact {
  padding: var(--spacing-sm);
  background-color: var(--secondary-color);

  .container-layout {
    padding-right: 0;
    padding-left: 0;
  }

  .content-block__icon {
    display: flex;
    justify-content: center;
    padding-bottom: var(--spacing-m);

    img {
      max-height: calc(3 * var(--icon-size-large));
    }
  }

  .content-block__header {
    border-color: var(--white);
  }

  .content-block__title {
    color: var(--white);
  }

  .content-block__content {
    color: var(--white);

    p:last-of-type .quick-link {
      display: flex;
      max-width: 400px;
      justify-content: center;
      padding: var(--spacing-s) var(--spacing-m);
      border-color: var(--white);
      margin: 0 auto;
      background-color: var(--white);
      color: var(--secondary-color);
      text-decoration: none;

      &:hover,
      :focus {
        border-color: var(--white);
        background-color: transparent;
        color: var(--white);
      }
    }
  }

  @media screen and (min-width: @screen-md-min) {
    padding: var(--spacing-xl) var(--spacing-2xl);
  }
}
