@businessUnitBoxWidth: 280px;
@businessUnitBoxHeight: 40px;

.business-unit-box {
  overflow: hidden;
  // stylelint-disable-next-line
  width: fit-content;
  max-width: @businessUnitBoxWidth;
  height: @businessUnitBoxHeight;
  padding: var(--spacing-xs) var(--spacing-s);
  border: var(--white) 2px solid;
  margin-left: var(--spacing-xs);
  background-color: var(--primary-color);
  border-radius: 5px;
  color: var(--white);

  &__inner {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: calc(
      @businessUnitBoxHeight - var(--spacing-xs) - var(--spacing-xs)
    );
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--spacing-s);

    &__separator {
      border-right: 2px solid var(--white);
    }

    &__left,
    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      > div:first-of-type {
        height: var(--text-xs);
        font-size: var(--text-xs);
        font-weight: bold;
        line-height: var(--text-xs);
      }

      > div:last-of-type {
        overflow: hidden;
        width: 100%;
        height: var(--text-s);
        font-size: var(--text-s);
        line-height: var(--text-s);
        text-overflow: ellipsis;
      }
    }

    &__left {
      max-width: calc(@businessUnitBoxWidth / 2);

      > div:last-of-type {
        height: var(--text-default);
        font-size: var(--text-default);
        font-weight: bold;
        line-height: var(--text-default);
      }
    }

    &__right {
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &:hover,
  &:focus {
    border: var(--white) 2px solid;

    .business-unit-box__inner__separator {
      border-color: var(--primary-color);
    }
  }

  &:disabled {
    background-color: var(--primary-color);
    color: var(--white);

    &:hover,
    &:focus {
      background-color: var(--primary-color);
      color: var(--white);

      .business-unit-box__inner__separator {
        border-color: var(--white);
      }
    }
  }

  &.is-foreign {
    background-color: var(--secondary-highlight-color);

    &:hover,
    &:focus {
      background-color: var(--white);
    }
  }

  &.small.has-no-customer-name {
    svg {
      width: var(--icon-size-default);
      height: var(--icon-size-default);
    }
  }

  &.small.has-customer-name {
    span {
      display: block;
      font-size: var(--text-sm);
      font-weight: bold;
    }
  }

  &.large.has-no-customer-name:not(.is-foreign) {
    .business-unit-box__inner__right > div:first-of-type {
      height: unset;
      font-size: var(--text-s);
      font-weight: normal;
      line-height: unset;
    }
  }

  &.large.is-foreign {
    .business-unit-box__inner__right > div:last-of-type {
      text-align: left;
    }
  }

  @media screen and (min-width: @screen-sm) {
    margin-left: var(--spacing-s);
  }

  @media screen and (min-width: @screen-lg) {
    margin-left: var(--spacing-sm);
  }
}
