@import "../mixins";

.impulse-intro {
  h2 {
    margin-top: var(--spacing-ml);
  }

  .button {
    .impulseButton();

    height: 80px;
    justify-content: flex-start;
  }
}
