@import "PortalDashboardStoresClassifiedAdsItem/index";

.portal-dashboard-stores-classified-ads {
  &--disabled {
    // stylelint-disable-next-line
    cursor: not-allowed !important;

    img {
      filter: grayscale(1);
    }

    .picture-tile__overlay {
      background-color: var(--table-grey);
    }
  }
}

.portal-dashboard-stores-classified-ads-modal {
  .ant-space {
    gap: var(--spacing-s) !important;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding-top: var(--spacing-xl) !important;
    padding-bottom: var(--spacing-xl) !important;

    button {
      width: 100%;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (min-width: @screen-md-min) {
        width: 50%;
        max-width: 300px;
      }
    }
  }
}
