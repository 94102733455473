.checkbox-filter {
  display: block;
}

.checkbox-filter .ant-checkbox-wrapper {
  margin-bottom: 0;
}

.checkbox-filter--filter-bar {
  .checkbox-filter__label {
    display: block;
    overflow: hidden;
    font-size: 16px;
    line-height: 25px;
    text-align: left;
    white-space: nowrap;
  }
}

.checkbox-filter--inline,
.checkbox-filter--sidebar {
  .checkbox-filter__label {
    font-size: var(--text-default);
  }
}
