.product-preorder-modal-inner-info {
  display: flex;
  flex-direction: column;
  margin-top: var(--spacing-m);
  gap: var(--spacing-m);

  & > div > div {
    color: var(--disabled-text);
  }

  .ant-tag {
    margin-bottom: var(--spacing-s);
  }

  &--cart .ant-tag {
    border-color: rgb(@primary-color-lighter 0.2);
    background-color: rgb(@primary-color-lighter 0.2);
    color: var(--primary-color);
  }

  &--order .ant-tag {
    border-color: rgb(@secondary-color 0.2);
    background-color: rgb(@secondary-color-lighter 0.2);
    color: var(--secondary-color);
  }
}
