.not-found-page {
  display: flex;
  min-width: 100vw;
  height: calc(100vh - 117px);
  flex-direction: column;
  align-items: center;
  background-image: url("/static/images/Login-background-cut.png");
  background-position-x: 160%;
  background-position-y: 104%;
  background-repeat: no-repeat;
  background-size: 75vw;

  &__head {
    margin-top: 20vh;
    font-size: var(--text-mega);
    letter-spacing: 2.74px;
  }

  &__button {
    padding-right: var(--spacing-m);
    padding-left: var(--spacing-m);
    margin-top: var(--spacing-l);

    &:hover,
    &:focus {
      background-color: var(--white);
      color: var(--primary-color);
    }
  }

  h5 {
    width: 75vw;
    max-width: 300px;
    color: var(--primary-color);
    font-size: var(--text-m);
    letter-spacing: 1.2px;
    line-height: 1.2;
    text-align: center;
  }

  ul {
    position: absolute;
    bottom: 0;
    left: -15px;
    font-size: var(--text-sm);
    line-height: 1.5;
    list-style-type: none;

    li a {
      color: var(--text-dark-color);
      text-decoration: none;
    }
  }

  @media only screen and (min-width: @screen-md-min) {
    background-size: 60vw;

    ul {
      right: unset;
      bottom: 15px;
      left: unset;
      display: flex;
      width: 300px;
      justify-content: space-evenly;
      padding: 0;
      margin: 0;
    }

    &__head {
      margin-top: 20vh;
    }
  }

  @media only screen and (min-width: @screen-lg-min) {
    background-position-x: 115%;
    background-size: 45vw;
  }

  @media only screen and (min-width: @screen-xxl-min) {
    background-position-x: 108%;
    background-size: 40vw;
  }
}
