/*
 * unfortunately, there is no separate variable to style the font size
 * also there is no way to add a className globally
   there is an option to add a className to an instance, but that has to be done on every config object and is therefore not suitable
 */

.ant-message {
  z-index: @zindex-tooltip;
  font-size: @text-default;

  // overwrite the margin if the default icon, it is also not possible by variable
  .anticon {
    margin-right: @spacing-s;
  }

  .iconUnavailable {
    width: var(--icon-size-small);
    height: var(--icon-size-small);
    margin-right: @spacing-s;
  }
}
