.detailText {
  grid-area: detailText;

  .breakline {
    border: 1px solid var(--primary-color);
  }

  .detailTextInner {
    position: relative;
    padding: @spacing-sm @spacing-2xl @spacing-sm @spacing-sm;
    border-width: 0 2px 2px;
    border-style: solid;
    border-color: var(--primary-color);
    background-color: var(--white);

    > .icon {
      position: absolute;
      top: @spacing-m;
      right: @spacing-sm;
      cursor: pointer;
    }
  }

  .detailTextTitleShort {
    display: block;
    padding: 0;
    border: none;
    margin: 0;
    color: @text-color;
  }

  p {
    margin-bottom: 0;
  }

  .collapsableTitle {
    margin-top: @spacing-sm;
  }

  .ant-tabs-tab-btn {
    font-size: @text-m;
  }

  .brand-portrait {
    display: flex;
    align-items: center;

    &.has-valid-picture {
      p {
        width: 70%;
      }

      img {
        width: 30%;
        height: 30%;
        padding-left: var(--spacing-s);
      }
    }

    @media only screen and (max-width: @screen-xs-max) {
      display: block;
      text-align: center;

      &.has-valid-picture {
        p {
          width: 100%;
          text-align: left;
        }

        img {
          width: 40%;
          height: 40%;
          padding-top: var(--spacing-s);
        }
      }
    }
  }
}
