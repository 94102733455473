.list--unstyled {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.list--inline {
  .listEntry {
    display: inline;
  }
}
