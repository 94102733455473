@import "scannerView";
@import "scannerMessage";
@import "scannerProductEditModal";

.ant-modal-root {
  .scannerOverlay {
    // overwrite ant-modal
    @media (max-width: @screen-sm-max) {
      top: 0;
      width: 100vw !important;
      max-width: 100vw;
      height: 100vh;
      margin: 0;
    }

    .ant-modal-header,
    .ant-modal-body {
      padding: 0;
      border: 0;
    }

    .ant-modal-title {
      padding: inherit;
      border-top: inherit;
      border-bottom: inherit;
      font-weight: inherit;
      text-align: inherit;
      text-transform: inherit;
    }

    .scandit-laser img {
      filter: hue-rotate(111deg) brightness(0.9);
    }
  }

  .scannerOverlayHeading {
    display: flex;
    width: 100%;
    height: @header-height-desktop;
    align-items: center;
    padding-right: @grid-padding-mobile;
    padding-left: @grid-padding-mobile;
    background-color: @primary-color;
    color: @white;

    .buttonText {
      display: flex;
      align-items: center;
    }
  }

  .scannerOverlayDay {
    margin-right: @spacing-m;
    margin-left: auto;
  }
}
