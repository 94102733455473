.back-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding-left: 0;
  margin-bottom: @spacing-sm;
  color: @text-dark-color;

  .icon {
    margin-right: @spacing-xs;
    color: @text-dark-color;
  }
}
