@import "text";
@import "colors";
@import "flex";
@import "sizes";
@import "iframeContainer";

.transparent {
  opacity: 0;
}

.transparent-half {
  opacity: 0.59;
}

.hidden {
  display: none !important;
}

.overflow-hidden {
  overflow: hidden;
}

.visible {
  display: block;
}

.visible-inline {
  display: inline-block;
}

.blurred {
  filter: blur(5px);
  user-select: none;
}

.z-overall {
  z-index: @zIndex-overall;
}

/* stylelint-disable plugin/no-unsupported-browser-features */
.isDraggable {
  cursor: grab;

  &.isDragging {
    cursor: grabbing;
    user-select: none;
  }
}
/* stylelint-enable plugin/no-unsupported-browser-features */

//reduced motion wrapper for transitions
.transitionWithReducedMotion (@transition: 0.3s all ease) {
  @media (prefers-reduced-motion: no-preference) {
    transition: @transition;
  }
}

// set height for ant inputs
.setFixedInputHeight(@height: @input-height-base) {
  [class*="@{ant-prefix}-input-"]:not([class*="-handler"]) {
    height: @height;
  }
}
