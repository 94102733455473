// preventing iOS from zooming in on focused form elements
select,
textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    font-size: @text-default !important;
  }
}

.labeledFormItem {
  label {
    color: @text-color;
    font-size: @text-default;
    font-weight: @font-weight-bold;
  }

  .iconLabel {
    width: auto;
    height: @text-default;
    margin-left: @spacing-s;
  }
}

label.ant-checkbox-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: @spacing-s;
  font-size: @text-sm;
  font-weight: normal;
}

.ant-checkbox {
  top: 0;

  + span {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.ant-checkbox-group-item {
  span {
    color: @text-color;
    font-size: inherit;
  }
}

.ant-select-arrow {
  top: 51%;
  right: @spacing-sm;
  width: auto;
  height: auto;
  color: @primary-color;
}

.titleFormMyAccount {
  .ant-typography {
    margin-top: @spacing-sm;
    font-size: @text-ml;
    font-weight: @font-weight-regular;
    text-transform: none;
  }
}
