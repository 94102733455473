.print-view {
  width: 100vw;

  &__content {
    width: 100vw;

    h3 {
      color: var(--primary-color);
    }

    &__order {
      display: flex;
      margin-bottom: var(--spacing-sm);
      gap: var(--spacing-s);

      &__order-info {
        width: 50vw;
        color: var(--primary-color);
        font-size: var(--text-m);

        &__dates {
          display: flex;
          justify-content: space-between;
          font-size: var(--text-default);

          svg {
            margin-right: var(--spacing-xs);
          }

          & > span:first-of-type {
            display: flex;
            width: 49%;
            height: 50px;
            align-items: center;
            justify-content: center;
            border-width: 2px;
            border-style: solid;
            border-color: var(--secondary-color);
            margin-bottom: var(--spacing-s);
            color: var(--secondary-color);

            &.cart-delivery-date {
              width: 100%;
            }

            & > span {
              // stylelint-disable-next-line
              width: min-content;
            }
          }

          & > span:last-of-type {
            display: flex;
            width: 49%;
            align-items: center;
            justify-content: center;
            border-width: 2px;
            border-style: solid;
            border-color: var(--primary-color);
            margin-bottom: var(--spacing-s);
            color: var(--primary-color);

            & > span {
              // stylelint-disable-next-line
              width: min-content;
            }
          }
        }

        .totalSum {
          width: 50vw;
          margin-left: -5px;
        }
      }

      &__customer-info {
        width: 50vw;
        padding: var(--spacing-m);
        border-style: solid;
        border-color: var(--primary-color);
        border-radius: 2px;
        color: var(--primary-color);
        font-size: var(--text-m);

        &__address {
          display: flex;
          align-items: center;
        }
      }
    }

    &__product-table {
      table {
        width: 100vw;

        th {
          background-color: var(--primary-color);
          color: var(--white);
        }

        th:nth-child(-n + 4) {
          text-align: left;
        }

        th:nth-child(n + 5) {
          text-align: right;
        }

        th:nth-child(-n + 6) {
          padding-right: var(--spacing-s);
        }

        td {
          color: var(--primary-color);
          vertical-align: top;
        }

        td:nth-child(-n + 4) {
          text-align: left;
        }

        // VPE
        td:nth-child(4) {
          white-space: nowrap;
        }

        td:nth-child(n + 5) {
          text-align: right;
        }

        td:nth-child(-n + 6) {
          padding-right: var(--spacing-s);
        }

        // totalSum
        tr:last-child {
          td:nth-last-child(2) {
            padding: 0;
            font-size: var(--text-m);
          }

          td:last-child {
            padding: 0;
            font-size: var(--text-m);
            text-align: right;
          }
        }
      }
    }

    &__product-table-favorite-list {
      table {
        width: 100vw;

        th {
          background-color: var(--primary-color);
          color: var(--white);
        }

        th:nth-child(-n + 4) {
          text-align: left;
        }

        th:nth-child(-n + 5) {
          padding-right: var(--spacing-s);
        }

        th:nth-child(n + 5) {
          text-align: right;
        }

        td {
          color: var(--primary-color);
          vertical-align: top;
        }

        td:nth-child(-n + 4) {
          text-align: left;
        }

        td:nth-child(-n + 5) {
          padding-right: var(--spacing-s);
        }

        td:nth-child(n + 5) {
          text-align: right;
        }

        // VPE
        td:nth-child(4) {
          white-space: nowrap;
        }
      }
    }
  }
}
