.productBundleList {
  padding: 0;
  margin: 0;

  button.ant-btn-link {
    height: var(--text-sm);
    padding: 0;
    border: 0;
    margin: 0;
    font-size: var(--text-sm);
    line-height: var(--text-sm);

    & > span {
      height: var(--text-sm);
      font-size: var(--text-sm);
      line-height: var(--text-sm);
    }

    &:hover,
    &:focus {
      & > span {
        text-decoration: underline;
      }
    }
  }
}

.productBundleItem {
  list-style: none;
}
