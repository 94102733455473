.dashboard-sub-header {
  margin-bottom: var(--spacing-sm);

  .sub-header__content {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;

    .ant-badge-count {
      background-color: var(--tertiary-color);
    }

    .quick-link {
      padding: var(--spacing-xxs) var(--spacing-l);
      margin-bottom: 0;

      svg {
        width: 40px;
        height: 40px;
      }
    }
  }
}
