.addFavouriteListModal {
  .ant-modal-title {
    font-size: @text-sm;
    font-weight: @font-weight-bold;
    text-transform: uppercase;
  }

  .addFavouriteListWrapper {
    width: 100%;
    padding: @spacing-sm @spacing-ml;
    background-color: @white;
  }

  &.addProductsToFavouriteLists {
    .ant-modal-footer {
      padding: 0;
      border: none;

      .ant-divider {
        margin: 0;
      }
    }

    .productAddFooterContainer {
      width: 100%;
      padding: @spacing-sm @spacing-m;

      .productAddSubmitButtons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .ant-modal-body {
    padding: 0;
    background-color: @light-grey;
    overflow-y: scroll;
  }
}
