.shopCategoryNavigation {
  position: sticky;
  z-index: @zIndex-fixed;
  top: @header-height-desktop;
  left: 0;
  display: flex;
  width: 100%;
  min-height: @shop-category-nav-height;
  align-items: center;
  background-color: @white;
  box-shadow: 0 1px @border-width-base rgb(@black 0.29);
  font-size: @text-default;
  letter-spacing: 0.64px;
  //Stops antd select animation
  transition: none;

  @media (max-width: @screen-xs-max) {
    top: @header-height-mobile;
    min-height: @shop-category-nav-height;
  }

  a:hover,
  a:focus {
    color: @primary-color;
    cursor: pointer;
  }

  a:active {
    color: @text-color;
  }

  * {
    font-size: @text-default;
    letter-spacing: 0.64px;
  }

  .shopCategoryNavigationMenu {
    display: flex;
    align-items: center;
  }

  .menuItem {
    padding-right: calc(@spacing-ml / 2) !important;

    .anticon svg {
      color: var(--primary-color);
    }

    // hide default border of ant component
    &:after {
      content: none;
    }

    &:not(:first-child) {
      padding-left: calc(@spacing-ml / 2) !important;
    }

    &--end {
      margin-left: auto;
    }
  }

  .sortiment-item {
    @media (max-width: @screen-xs-max) {
      display: none;
    }
  }

  .navlink {
    padding: 0;
    border: none;
    margin: 0;
    background: none;
    cursor: pointer;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }

  .navlink-active {
    position: relative;
    color: @tertiary-color !important;

    &:before {
      position: absolute;
      top: calc(50% + @font-size-base / 2);
      width: 100%;
      height: 2px;
      background-color: @primary-color;
      content: "";
    }

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  .ant-menu {
    border: none;
    box-shadow: none;
  }

  // hide the and border at the bottom of the list entry
  .ant-menu-item-active,
  .ant-menu-item-selected {
    border: none !important;
  }

  .ant-menu-item:hover,
  .ant-menu-item:focus {
    border-bottom: none !important;
  }

  // mobile overflow icon
  @media only screen and (max-width: @screen-sm-max) {
    .ant-menu-submenu-horizontal {
      width: @button-icon-default-size;
      height: @button-icon-default-size;

      /*
       * move the icon to the grid border but keep the click area
       * ignore the default margin top to keep icon aligned
       */
      margin-top: 0 !important;
      margin-right: -@icon-arrow-height;
      margin-left: auto;
    }

    // persist icon click area
    .ant-menu-submenu-title {
      display: flex;
      width: @button-icon-default-size;
      height: @button-icon-default-size;
      align-items: center;
    }

    // custom icon
    .iconArrow {
      width: @icon-arrow-width;
      height: @icon-arrow-height;
      color: @table-borders;
      transform: translateX(50%) rotate(-90deg);
    }
  }
}
