.preOrderIcon {
  display: inline-flex;
  align-items: center;
  margin-right: @spacing-xs;

  .icon {
    width: var(--icon-size-default);
    height: var(--icon-size-default);
  }

  .iconTwoTone {
    --svg-primary-tone: @primary-color;
    --svg-secondary-tone: @light-grey;

    &.iconTwoTone--customPreorder {
      --svg-primary-tone: @white;
      --svg-secondary-tone: @search-color;
    }
  }
}
