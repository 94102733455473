.downloadModal {
  .@{ant-prefix}-modal-close {
    top: calc(@modal-header-padding-vertical / 2);
  }

  .@{ant-prefix}-modal-body {
    padding: 0;
  }
}

.downloadModalHeader {
  display: flex;
  max-width: calc(
    100% - @modal-header-close-size + @modal-header-padding-horizontal
  );
  align-items: center;
  justify-content: space-between;
}

.downloadModalList {
  padding: 0;
  margin: 0;
  background-color: @light-grey;
  list-style: none;
}

.downloadModalListEntry {
  display: flex;
  justify-content: space-between;
  padding: @spacing-sm @spacing-ml;

  &:not(:last-child) {
    border-bottom: @border-width-thin solid @primary-color;
  }
}
