.productPriceInfo {
  display: flex;
  flex-direction: row;
  grid-area: productPrice;

  @media (min-width: @screen-md-min) {
    align-self: center;
  }

  .productPrice {
    color: @text-dark-color;
    font-size: @text-ml;
    font-weight: @font-weight-bold;
  }

  .isStrikeThroughPrice {
    margin-right: @spacing-s;
  }
}

.isStrikeThroughPrice {
  color: @text-color;
  font-size: @text-s;
  line-height: @text-s;
  text-decoration: line-through;
}

.hasStrikeThroughPrice.hasStrikeThroughPrice {
  color: @primary-color;
}
