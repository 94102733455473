@import "../mixins";

.impulse-download {
  display: flex;
  justify-content: center;

  .button {
    .impulseButton();

    max-width: 800px;
  }
}
