body {
  margin: 0;
  font-family: chaparral-pro, Georgia, "Times New Roman", serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Fira Sans", "Droid Sans";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &.body-overflow-hidden {
    overflow-y: hidden;

    @supports (-webkit-touch-callout: none) {
      /* CSS specific to iOS devices */
      min-height: 100vh;
      padding-bottom: env(safe-area-inset-bottom);
    }
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container-layout {
  width: 100%;
  margin: 0 auto;
  background: transparent;
}

html,
body,
#root {
  height: 100%;
}

#root > * {
  min-height: 100%;
}

// main with min content height
.container-layout--content {
  min-height: calc(100vh - @header-height-mobile - @footer-height-mobile);

  @media (min-width: @screen-md-min) {
    min-height: calc(
      100vh - @header-height-desktop - @shop-category-nav-height -
        @footer-height-desktop
    );
  }

  h1 {
    overflow-wrap: break-word;
  }
}

// inner container layout
.container-layout--inner {
  position: relative;
  padding-right: @grid-padding-mobile;
  padding-left: @grid-padding-mobile;

  &.container-layout--fullWidthMobile {
    @media (max-width: @screen-md-max) {
      padding-right: 0;
      padding-left: 0;
      margin-top: 0;
    }

    @media (min-width: @screen-lg-min) {
      margin-top: @spacing-xl;
    }
  }

  @media (min-width: @screen-md-min) {
    max-width: calc(@screen-xxl + 2 * @grid-padding-desktop);
    padding-right: @grid-padding-desktop;
    padding-left: @grid-padding-desktop;
  }
}

.container-layout--inner:fullscreen {
  padding: 0;
  margin: 0;
  background-color: var(--light-grey);
  overflow-y: scroll;

  .filter-bar {
    margin: 0;
  }

  .filters-desktop {
    margin: 0;
  }

  .ant-row {
    margin-bottom: 0;
  }

  .tableWrapper__fullscreen-menu {
    position: sticky;
    z-index: var(--z-index-overall);
    top: 0;
    justify-content: end;
    background-color: var(--primary-color);
  }

  .selected-filters {
    padding-left: 20px;
    margin-bottom: 0;
    background-color: var(--light-grey);
  }
}
// global spacing between header / shop navigation and content
main {
  & > [class~="container-layout"]:first-child,
  .shopCategoryNavigation + .container-layout--inner {
    &:not([class*="fullWidth"]) {
      margin-top: @spacing-m;
    }
  }
}
