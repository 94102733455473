.container-layout--header {
  @media (min-width: @screen-md-min) {
    height: @header-height-desktop;
    line-height: @line-height-base;
  }
}

.header {
  width: 100%;

  &__sticky {
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    position: sticky;
    z-index: @zIndex-menu;
    top: 0;
    left: 0;
    height: auto;
  }

  @media only screen and (min-width: @screen-lg-min) {
    width: 100%;
  }

  @media only screen and (min-width: @screen-lg-min) {
    height: @header-height-desktop;
  }

  &__wrapper {
    display: flex;
    width: 100%;
    min-height: @header-height-mobile;
    align-items: center;
    justify-content: space-between;
    background-color: @primary-color;
    line-height: @line-height-base;

    @media screen and (min-width: @screen-lg-min) {
      height: @header-height-desktop;
      min-height: @header-height-desktop;
      max-height: @header-height-desktop;
      align-items: flex-end;

      svg {
        width: auto;
        max-height: 55px;
      }
    }

    .header__container {
      display: flex;
      align-items: center;
    }

    .header__logo-container {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      grid-area: logo;

      @media screen and (max-width: @screen-md-max) {
        justify-content: center;
      }

      @media screen and (max-width: @screen-xs-max) {
        justify-content: center;
      }
    }

    .header__user-navigation-container {
      position: relative;
      display: flex;
      align-items: flex-end;
      grid-area: user;
      justify-self: flex-end;

      svg {
        position: relative;
        color: var(--primary-color);
        fill: none;
        stroke: #ffffff;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 1.7;
        vertical-align: text-bottom;

        @media screen and (max-width: @screen-md-max) {
          top: 5px;
        }

        &:hover,
        &:focus,
        &.header__user-navigation--open {
          cursor: pointer;
          fill: var(--white) !important;

          .accountIcon {
            fill: var(--white);
          }
        }
      }

      @media screen and (min-width: @screen-lg-min) {
        align-self: center;
      }
    }

    .header__cart {
      flex-direction: row;
      justify-content: space-between;

      @media only screen and (max-width: @screen-md-max) {
        justify-content: flex-end;
      }

      @media only screen and (min-width: @screen-lg-min) {
        justify-content: flex-end;
      }

      &-mobile-invisible {
        @media only screen and (max-width: @screen-sm-max) {
          display: none;
        }
      }

      p {
        margin-bottom: 0;
        color: @white;
        font-size: @text-s;
        font-weight: @font-weight-regular;
        letter-spacing: 0.28pt;
        line-height: @line-height-custom;

        &.cart-total__line {
          font-size: @text-default;
        }

        strong {
          font-weight: @font-weight-bold;
        }
      }

      svg {
        path {
          stroke: @white;
        }
      }

      .header__cart-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        grid-area: cart;

        a {
          position: relative;
        }

        svg {
          path {
            stroke: @white;
          }
        }

        .badge-cart {
          .ant-badge-count {
            top: 3px;
            right: 3px;
          }
        }
      }

      a {
        display: flex;
        width: auto;
        justify-content: space-between;
      }
    }

    .header__cart-wrapper {
      sup.ant-badge-count {
        right: 8px;
        padding: 0 4px;
        font-size: var(--text-xs);
      }
    }

    .header__search-mobile {
      @media screen and (min-width: @screen-md-min) {
        display: flex;
        align-items: center;
        margin: 0;
      }

      .ant-input-affix-wrapper {
        display: none;
        border: none;
        background-color: @search-color;
        color: @white;

        svg path {
          fill: @table-borders;
        }

        input {
          background-color: @search-color;
          color: @white;
        }
      }

      &-mobile-invisible {
        @media only screen and (max-width: @screen-sm-max) {
          display: none;
        }
      }
    }
  }

  .header__order-summary {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .cart-total__container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }
}

.main-logo {
  width: auto;
  max-width: 100%;
  height: 45px;
  max-height: 100%;
  margin-left: @spacing-xs;
  color: @white;

  @media only screen and (min-width: @screen-lg-min) {
    margin-left: 0;
  }

  @media screen and (max-width: @screen-xs-max) {
    position: absolute;
  }
}

.burger-menu {
  width: auto;
  height: 17pt;
  cursor: pointer;

  path,
  line {
    stroke: @white;
  }

  &.close-menu {
    margin-right: @spacing-xs;
  }
}

.noscroll {
  position: fixed;
  overflow: hidden;

  @media only screen and (min-width: @screen-md-min) {
    position: relative;
    overflow: visible;
  }
}

.header-img {
  position: relative;
  display: block;
  margin-top: 0;
  margin-bottom: @spacing-sm;

  img {
    display: block;
    width: 100%;
  }
}

/* stylelint-disable plugin/no-unsupported-browser-features */
.headerInner {
  display: grid;
  height: @header-height-mobile;
  column-gap: 0;
  grid-template-areas:
    "headerMenu logo logo logo pricesSwitch favoriteList user cart"
    "search search search search date date date businessUnit";
  row-gap: var(--spacing-xs);

  @media screen and (max-width: @screen-md-max) {
    grid-template-columns: @button-icon-default-size 122px 1fr 120px 70px 48px 48px 60px;
  }

  @media screen and (max-width: @screen-xs-max) {
    grid-template-columns: @button-icon-default-size 71px auto 50px 0 0 48px 56px;
  }

  .search__container {
    margin-top: @spacing-xs;
    grid-area: search;
  }

  .date__container {
    display: flex;
    justify-content: flex-end;
    margin-top: @spacing-xs;
    grid-area: date;
  }

  .business-unit-box__container {
    display: flex;
    margin-top: @spacing-xs;
    grid-area: businessUnit;

    @media screen and (max-width: @screen-xs-max) {
      margin-right: 5px;
    }
  }

  .headerMenu {
    display: flex;
    align-items: flex-end;
    grid-area: headerMenu;
  }

  .header__cart-wrapper {
    display: flex;
    align-items: flex-end;
    margin: 0 @spacing-s;
    grid-area: cart;
  }

  @media only screen and (min-width: @screen-lg-min) {
    height: 70px;
    align-items: center;
    margin-top: 10px;
    grid-template-areas: "headerMenu logo search date businessUnit pricesSwitch favoriteList user cart";
    grid-template-columns: 60px 1fr auto 152px minmax(100px, 350px) 1fr 48px 48px 46px;

    .search__container {
      display: flex;
      flex-shrink: 2;
      justify-content: center;
      margin-top: 0;
    }

    .date__container {
      margin-top: 0;
    }

    .business-unit-box__container {
      margin-top: 0;
    }

    .headerMenu {
      display: block;
    }
  }

  .link--favoriteList {
    display: flex;
    align-items: flex-end;
    margin-left: var(--spacing-sm);
    grid-area: favoriteList;

    > a {
      width: var(--button-icon-small-size);
      height: var(--button-icon-small-size);

      svg {
        height: var(--button-icon-small-size);
        color: var(--white);

        &.has-favorite-list {
          fill: var(--white);
        }

        &.has-no-favorite-list {
          fill: var(--primary-color);
        }
      }
    }
  }

  .contactPersonTeaserWrapper {
    @media screen and (max-width: @screen-md-max) {
      margin-top: calc(var(--spacing-m) + 2px);
    }

    .contactPersonImageTeaser {
      width: 30px;
      height: 30px;
      border: 2px solid white;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  .header__user-navigation-container {
    padding-bottom: var(--spacing-xs);

    @media only screen and (min-width: @screen-lg-min) {
      align-self: center;
      padding-bottom: 0;
    }
  }

  .prices-switch {
    display: flex;
    align-self: flex-end;
    justify-content: flex-end;
    padding-bottom: calc(var(--spacing-xs) + var(--spacing-xxs));
    grid-area: pricesSwitch;

    @media only screen and (min-width: @screen-lg-min) {
      align-self: center;
      padding-bottom: 0;
    }
  }
}

/* stylelint-enable plugin/no-unsupported-browser-features */
