.check-price-change-button {
  display: flex;
  height: 23px;
  align-items: center;
  padding: var(--spacing-xxs) var(--spacing-xs);
  font-size: var(--text-xs);

  & > svg {
    width: var(--icon-size-small);
    margin-right: var(--spacing-xs);
  }

  & > span {
    line-height: calc(var(--text-xs) + 1px);
  }
}
