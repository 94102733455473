.headingWithAddon {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: space-between;
  gap: @spacing-sm;

  .newsTitle {
    text-transform: uppercase;
  }

  .itemCount {
    font-style: italic;
    font-weight: @font-weight-regular;
  }

  @media only screen and (min-width: @screen-sm-min) {
    align-items: baseline;
    justify-content: flex-start;
  }
}
