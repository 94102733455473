@font-face {
  font-display: auto;
  font-family: chaparral-pro;
  font-stretch: normal;
  font-style: normal;
  font-weight: 400;
  src: url("../../static/fonts/chaparral-pro-regular-400.woff2") format("woff2"),
    url("../../static/fonts/chaparral-pro-regular-400.woff") format("woff"),
    url("../../static/fonts/chaparral-pro-regular-400.otf") format("opentype");
}

@font-face {
  font-display: auto;
  font-family: chaparral-pro;
  font-stretch: normal;
  font-style: italic;
  font-weight: 400;
  src: url("../../static/fonts/chaparral-pro-italic-400.woff2") format("woff2"),
    url("../../static/fonts/chaparral-pro-italic-400.woff") format("woff"),
    url("../../static/fonts/chaparral-pro-italic-400.otf") format("opentype");
}

@font-face {
  font-display: auto;
  font-family: chaparral-pro;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  src: url("../../static/fonts/chaparral-pro-regular-600.woff2") format("woff2"),
    url("../../static/fonts/chaparral-pro-regular-600.woff") format("woff"),
    url("../../static/fonts/chaparral-pro-regular-600.otf") format("opentype");
}

.tk-chaparral-pro {
  font-family: chaparral-pro, serif;
}

.tk-chaparral-pro-caption {
  font-family: chaparral-pro-caption, serif;
}

.tk-chaparral-pro-subhead {
  font-family: chaparral-pro-subhead, serif;
}

.tk-chaparral-pro-display {
  font-family: chaparral-pro-display, serif;
}
