@import "WeekplannerGroupHeader/index";
@import "WeekplannerItem/index";

.weekplanner-items {
  display: flex;
  flex-grow: 1;
  border-right: 1px solid var(--disabled-bg);
  border-left: 1px solid var(--disabled-bg);

  &__body {
    display: flex;
    height: 250px !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--disabled-text);

    &--loading {
      display: flex;
      flex-direction: column;
    }
  }
}
