.print-view-support {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-m);
  margin: var(--spacing-m) 0;
  background-color: var(--secondary-color);
  color: var(--white);
  font-size: var(--text-m);

  &__support-icon {
    width: 40px;
    height: 40px;
    align-items: flex-start;
    margin-right: var(--spacing-s);
  }

  span {
    display: inline-block;
  }
}
