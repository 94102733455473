.product-show-order-items-button {
  .product-order-items-button {
    display: flex;
    width: 38px;
    height: 100%;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 0 none;
    background-color: transparent;

    &--has-items,
    &--has-no-items {
      width: calc(var(--icon-size-large) + 2px);
      height: calc(var(--icon-size-large) + 2px);
    }

    &--has-items {
      color: var(--primary-color);
      cursor: pointer;

      &:hover,
      &:focus {
        background-color: var(--primary-color);
        border-radius: 50%;

        .cls-1 {
          color: var(--white);
        }
      }
    }

    &--has-no-items {
      color: var(--table-borders);
      // stylelint-disable-next-line
      cursor: not-allowed;
    }
  }
}

.product-show-order-items-tooltip {
  color: var(--primary-color);

  h5 {
    margin-bottom: 0;
    font-weight: unset;
  }

  .order-item__items {
    margin-top: var(--spacing-s);

    .ant-timeline-item {
      padding-bottom: var(--spacing-s);

      .ant-timeline-item-content {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 2px;
        margin-left: var(--spacing-m);
        gap: var(--spacing-s);
      }
    }

    .ant-timeline-item-last {
      min-height: unset !important;
    }

    .ant-timeline-item-last > .ant-timeline-item-content {
      min-height: unset !important;
    }

    &__item {
      .ant-badge-count {
        min-width: 30px;
        background-color: var(--secondary-color);
      }

      &--outdated {
        .ant-badge-count {
          background-color: var(--table-grey);
        }
      }
    }
  }
}
