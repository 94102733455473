@import "HolidayAlertAssets/index";
@import "HolidayAlertDeadlineModal/index";

.holiday-alert {
  @keyframes pulse-arrow-icon {
    0% {
      transform: rotate(-90deg) scale(0.9);
    }

    70% {
      transform: rotate(-90deg) scale(1.2);
    }

    100% {
      transform: rotate(-90deg) scale(0.9);
    }
  }

  button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    background-color: var(--secondary-highlight-color);
    color: var(--white);
    font-size: var(--text-sm);
    gap: var(--spacing-s);

    .icon {
      width: var(--icon-size-small);
      height: var(--icon-size-small);
      transform: rotate(-90deg);

      @media (prefers-reduced-motion: no-preference) {
        animation: pulse-arrow-icon 1.5s infinite;
      }
    }

    &:hover,
    &:focus {
      // stylelint-disable-next-line
      background-color: darken(@secondary-highlight-color, 5%);
      color: var(--white);
    }
  }
}

.holiday-alert-modal {
  .ant-modal-content:has(.holiday-alert-modal__deadline-warning)
    .ant-modal-close-x {
    line-height: unset;
  }

  .ant-modal-header:has(.holiday-alert-modal__deadline-warning) {
    padding: 0 !important;
  }

  .ant-modal-title {
    padding: 0 !important;
    border-top: 0 !important;
    border-bottom: 0 !important;

    .holiday-alert-modal__deadline-warning {
      padding: var(--spacing-sm);
      margin-bottom: var(--spacing-sm);
      background-color: var(--secondary-highlight-color);
      color: var(--white);
      font-size: var(--text-sm);
      font-weight: normal;
      text-align: center;
      text-transform: none;
    }
  }

  .ant-modal-body {
    font-size: var(--text-default);

    h2 {
      padding-bottom: var(--spacing-s);
      border-bottom: 1px solid var(--primary-color);
      margin-bottom: var(--spacing-m);
      letter-spacing: 1px;
      text-align: center;
    }

    h3 {
      margin-bottom: var(--spacing-s);
    }

    .ant-form,
    .ant-radio-wrapper-in-form-item,
    .ant-checkbox-wrapper {
      font-size: var(--text-default);
    }

    .ant-form .ant-form-item {
      margin-bottom: var(--spacing-s);
    }

    .ant-form .ant-checkbox-group {
      display: flex;
      flex-wrap: wrap;
      gap: var(--spacing-sm);

      .ant-checkbox-wrapper {
        display: flex;
        align-items: flex-start;

        .ant-checkbox {
          margin-top: var(--spacing-xs);
        }

        @media screen and (max-width: @screen-sm-max) {
          margin-left: 0;
        }
      }
    }

    .ant-form > div:first-of-type {
      padding: var(--spacing-sm);
      background-color: var(--disabled-bg);
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding-top: var(--spacing-xl) !important;
    padding-bottom: var(--spacing-xl) !important;

    button {
      width: 100%;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (min-width: @screen-md-min) {
        width: 50%;
        max-width: 500px;
      }
    }
  }

  &__deadline-warning {
    padding: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
    background-color: var(--secondary-highlight-color);
    color: var(--white);
    text-align: center;
  }

  &__delivery-date-note {
    font-size: var(--text-s);
  }
}
