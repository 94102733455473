/* stylelint-disable declaration-block-no-redundant-longhand-properties */
.productTile {
  display: grid;
  width: 100%;
  margin: 0 0 @spacing-m 0;
  /* stylelint-disable plugin/no-unsupported-browser-features */
  column-gap: @product-gap-sm;
  grid-template-areas:
    "infoBadge       infoBadge       infoBadge       infoBadge        infoBadge        infoBadge"
    "title           title           title           title            insignias            insignias"
    "img             attributes      attributes      attributes       attributes       attributes"
    "productBasket   productBasket   productBasket   productBasket    productBasket    productBasket"
    "productInfoIcon productInfoIcon productInfoIcon .                .                ."
    "advertBadge     advertBadge     advertBadge     advertBadge      advertBadge      advertBadge"
    "divider         divider         divider         divider          divider          divider";
  grid-template-columns: @product-image-width-sm auto;
  grid-template-rows: auto auto auto 1fr auto auto;
  row-gap: 0;

  @media screen and (min-width: @screen-md-min) {
    position: relative;
    column-gap: @product-gap-md;
    grid-template-areas:
      "img title           title           title           title         title         title         title         insignias         insignias"
      "img attributes      attributes      attributes      attributes    volumePrices  volumePrices  volumePrices  volumePrices  volumePrices"
      "img productBasket   productBasket   productBasket   productBasket productBasket productBasket productBasket productBasket productBasket"
      "img productInfoIcon productInfoIcon productInfoIcon .             .             .             .             .             ."
      "img advertBadge     advertBadge     advertBadge     advertBadge   advertBadge   advertBadge   advertBadge   advertBadge   advertBadge"
      "img divider         divider         divider         divider       divider       divider       divider       divider       divider";
    grid-template-columns: @product-image-width-md auto;
    grid-template-rows: auto;
  }

  @media screen and (min-width: @screen-lg-min) {
    grid-template-areas:
      "img title           title           title           title         title         title         title         insignias         insignias"
      "img attributes      attributes      attributes      attributes    volumePrices  volumePrices  volumePrices  volumePrices  volumePrices"
      "img productInfoIcon productInfoIcon productInfoIcon productBasket productBasket productBasket productBasket productBasket productBasket"
      "img advertBadge     advertBadge     advertBadge     advertBadge   advertBadge   advertBadge   advertBadge   advertBadge   advertBadge"
      "img divider         divider         divider         divider       divider       divider       divider       divider       divider";
    grid-template-columns: @product-image-width-lg auto;
  }

  &.isUnavailable,
  &.isNextMonthCampaign {
    &:not(.hasNextAvailability) {
      grid-template-areas:
        "title           title           title           title            insignias            insignias"
        "img             attributes      attributes      attributes       attributes       attributes"
        "productInfoIcon productInfoIcon productInfoIcon alternateProduct alternateProduct alternateProduct"
        "advertBadge     advertBadge     advertBadge     advertBadge      advertBadge      advertBadge"
        "divider         divider         divider         divider          divider          divider";

      @media screen and (min-width: @screen-md-min) {
        grid-template-areas:
          "img title           title           title           title           title           title            title            insignias            insignias"
          "img attributes      attributes      attributes      attributes      attributes      attributes       attributes       attributes       attributes"
          "img productInfoIcon productInfoIcon productInfoIcon productInfoIcon productInfoIcon alternateProduct alternateProduct alternateProduct alternateProduct"
          "img advertBadge     advertBadge     advertBadge     advertBadge     advertBadge     advertBadge      advertBadge      advertBadge      advertBadge"
          "img divider         divider         divider         divider         divider         divider          divider          divider          divider";
      }
    }

    &.hasNextAvailability,
    &.isNextMonthCampaign {
      grid-template-areas:
        "title                 title                 title                 title                 insignias                 insignias"
        "img                   attributes            attributes            attributes            attributes            attributes"
        "productBasketPreorder productBasketPreorder productBasketPreorder productBasketPreorder productBasketPreorder productBasketPreorder"
        "productInfoIcon       productInfoIcon       productInfoIcon       alternateProduct      alternateProduct      alternateProduct"
        "advertBadge     advertBadge     advertBadge     advertBadge      advertBadge      advertBadge"
        "divider               divider               divider               divider               divider               divider";

      @media screen and (min-width: @screen-md-min) {
        grid-template-areas:
          "img title                 title                 title                 title                 title                 title                 title                 title                 insignias"
          "img attributes            attributes            attributes            attributes            volumePrices          volumePrices          volumePrices          volumePrices          volumePrices"
          "img productBasketPreorder productBasketPreorder productBasketPreorder productBasketPreorder productBasketPreorder productBasketPreorder productBasketPreorder productBasketPreorder productBasketPreorder"
          "img productInfoIcon       productInfoIcon       productInfoIcon       productInfoIcon       productInfoIcon       alternateProduct      alternateProduct      alternateProduct      alternateProduct"
          "img advertBadge     advertBadge     advertBadge     advertBadge     advertBadge     advertBadge      advertBadge      advertBadge      advertBadge"
          "img divider               divider               divider               divider               divider               divider               divider               divider               divider";
      }
    }
  }
  /* stylelint-enable plugin/no-unsupported-browser-features */
  .productAttributes {
    padding-bottom: @spacing-xs;
  }
}
/* stylelint-enable declaration-block-no-redundant-longhand-properties  */
