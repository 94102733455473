@import "PortalDashboardDepartmentNewsItem/index";

.portal-dashboard-department-news {
  .content-block__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
  }

  &__more {
    align-self: center;
    margin-top: var(--spacing-s);
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}
