.productAlternativesButton {
  grid-area: alternateProduct;
  justify-self: flex-end;
}

.productViewer {
  .@{ant-prefix}-drawer-header {
    padding-right: @spacing-s;
  }

  .@{ant-prefix}-drawer-body {
    @media (max-width: @screen-xs-max) {
      padding-bottom: @footer-height-mobile;
    }
  }
}
