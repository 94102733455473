@import "StandingOrderDetails/index";

@keyframes item-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .standing-order-request-item {
    animation: none;
  }
}

.standing-order-request-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  animation: item-fade-in 1s forwards;
  gap: var(--spacing-m);

  .quick-order-product-tile,
  .standing-order-details,
  .quick-order-product-prices {
    display: flex;
    flex: 1 1 0;
  }

  &__info {
    display: flex;
    gap: var(--spacing-s);
  }

  &__info > div {
    width: 75px;
    flex-shrink: 1;
    padding: var(--spacing-s);
    border: 1px solid var(--primary-color);
    text-align: center;
  }

  &__info > button {
    color: var(--primary-color);

    svg {
      width: var(--icon-size-default);
      height: var(--icon-size-default);
    }

    &:hover,
    &:focus {
      color: var(--primary-color);
    }
  }

  @media screen and (min-width: @screen-xl) {
    flex-direction: row;
  }
}
