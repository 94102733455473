.scrollable-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: var(--spacing-s);

  &__button-left {
    > svg {
      rotate: 90deg;
    }

    &:after {
      position: absolute;
      z-index: 1;
      right: 0;
      width: 30px;
      height: 100%;
      box-shadow: 10px 0 15px 1px rgb(255 255 255 / 90%);
      content: "";
    }
  }

  &__button-right {
    > svg {
      rotate: -90deg;
    }

    &:after {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      width: 30px;
      height: 100%;
      box-shadow: -10px 0 15px 1px rgb(255 255 255 / 90%);
      content: "";
    }
  }

  &__button-left,
  &__button-right {
    display: flex;
    align-items: center;
    padding: 0;
    border: 0;

    > svg {
      color: var(--table-borders);
    }

    &:hover,
    &:focus {
      background-color: unset;

      > svg {
        color: var(--primary-color);
      }
    }
  }

  &__content {
    overflow-y: scroll;
    scrollbar-width: none;
  }
}
