.weekplanner-item-price {
  display: flex;
  width: @weekplanner-item-price-width;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;

  .productPrices {
    align-self: center;
    font-size: var(--text-s);
    justify-self: flex-end;
    text-align: center;

    .price {
      font-weight: var(--font-weight-bold);
      white-space: initial;
    }

    .base-price {
      color: gray;
    }

    .price,
    .base-price,
    .product-UVP {
      line-height: calc(var(--text-s) * 1.1);
    }

    .is-strike-through-price + .has-strike-through-price {
      line-height: 1;
    }
  }

  .volume-prices {
    display: flex;
    margin-top: var(--spacing-xxs);
    background-color: white;

    .secondVolumePrice {
      padding: 0 var(--spacing-xxs);
      border-width: 1px;
      border-style: solid;
      font-size: var(--text-s);
    }

    .allVolumePrices {
      padding: 0 var(--spacing-xxs);
      border-width: 1px;
      border-style: solid;
      margin-left: var(--spacing-xxs);
      font-size: var(--text-s);
    }
  }
}
