@import "variables";

@import "WeekplannerHeader/index";
@import "WeekplannerItems/index";
@import "WeekplannerItemsHeader/index";
@import "WeekplannerUnsupportedDevice/index";

.weekplanner-new {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: calc(100vh - 80px - 45px);
  flex-direction: column;
  padding-top: var(--spacing-s);

  .container-layout--inner {
    @media screen and (max-width: @screen-xl) {
      padding-right: 16px !important;
      padding-left: 16px !important;
    }
  }

  &--not-visible {
    height: unset;
  }

  @media screen and (min-width: @screen-sm) {
    .customBackTop {
      right: 25px;
    }
  }
}
