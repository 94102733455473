.print-button {
  svg {
    height: var(--icon-size-default);
  }

  &:hover,
  &:focus {
    svg {
      color: var(--primary-color);
      .transitionWithReducedMotion();
    }
  }
}
