.button {
  .btn();

  cursor: pointer;
  font-size: @text-default;
  font-weight: @font-weight-regular;

  &--rounded {
    border-radius: 10px;
  }
}

.buttonPrimary {
  .btn-primary();

  &:not([disabled]) {
    &:focus,
    &:hover {
      background-color: @white;
      color: @primary-color;
    }
  }

  &.buttonPrimary--inverted {
    &:not([disabled]) {
      background-color: @white;
      color: @primary-color;

      &:focus,
      &:hover,
      &.buttonPrimary--active {
        background-color: @primary-color;
        color: @white;
        text-decoration: none;
      }
    }
  }
}

.buttonGrey {
  .btn-primary();

  &:not([disabled]) {
    border-color: @table-grey;
    background-color: @table-grey;
    color: @white;

    &:focus,
    &:hover {
      border-color: @table-grey;
      background-color: @white;
      color: @table-grey;
      text-decoration: none;
    }
  }

  &.buttonSecondary--inverted {
    &:not([disabled]) {
      background-color: @white;
      color: @table-grey;

      &:focus,
      &:hover {
        border-color: @table-grey;
        background-color: @table-grey;
        color: @white;
        text-decoration: none;
      }
    }
  }
}

.buttonSecondary {
  .btn-primary();

  &:not([disabled]) {
    border-color: @secondary-color;
    background-color: @secondary-color;
    color: @white;

    &:focus,
    &:hover {
      border-color: @secondary-color;
      background-color: @white;
      color: @secondary-color;
      text-decoration: none;
    }
  }

  &.buttonSecondary--inverted {
    &:not([disabled]) {
      background-color: @white;
      color: @secondary-color;

      &:focus,
      &:hover {
        border-color: @secondary-color;
        background-color: @secondary-color;
        color: @white;
        text-decoration: none;
      }
    }
  }
}

.buttonText {
  .btn-text();

  padding-right: 0;
  padding-left: 0;
  cursor: pointer;
  text-decoration: underline;

  &:not([disabled]) {
    &:focus,
    &:hover {
      background-color: transparent;
      color: @primary-color;
      text-decoration: none;
    }
  }

  &.buttonText--inverted {
    &:not([disabled]) {
      color: @white;

      &:focus,
      &:hover {
        color: @white;
      }
    }
  }

  &.buttonTextDecoration--inverted {
    text-decoration: none;

    &:not([disabled]) {
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.buttonTextDecoration--always {
    text-decoration: underline;

    &:not([disabled]) {
      &:focus,
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.buttonTextDecoration--never {
    text-decoration: none;

    &:not([disabled]) {
      &:focus,
      &:hover {
        text-decoration: none;
      }
    }
  }

  &.buttonText--primary {
    color: @primary-color;
  }
}

.buttonText--menu {
  display: inline-flex;
  width: @button-icon-default-size;
  align-items: center;
  justify-content: center;
  padding: @spacing-xs;
  font-size: @text-m;
  font-weight: @font-weight-bold;
  text-decoration: none;
  transform: rotate(90deg);

  &:not([disabled]) {
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  @media screen and (min-width: @screen-md-min) {
    font-size: @text-l;
  }
}

.buttonWithIcon {
  display: flex;
  min-width: @btn-height-base;
  align-items: center;
  justify-content: center;

  &:not(.buttonWithIcon--withoutText) {
    .icon,
    svg {
      margin-right: @spacing-s;
    }
  }

  .icon,
  svg {
    width: @button-icon-input-size;
    height: @button-icon-input-size;
  }

  &:not([disabled]) {
    &:focus,
    &:hover {
      .icon,
      svg {
        color: @primary-color;
      }
    }
  }

  &.buttonPrimary--inverted,
  &.buttonSecondary--inverted {
    &:not([disabled]) {
      &:focus,
      &:hover {
        .icon,
        svg {
          color: @white;
        }
      }
    }
  }

  &.filterButton {
    &:not([disabled]) {
      &:focus,
      &:hover {
        .icon,
        svg {
          color: @white;
        }
      }
    }
  }
}

.buttonBackToTop {
  display: flex;
  width: @button-icon-default-size;
  height: @button-icon-default-size;
  align-items: center;
  justify-content: center;
  //icon slightly offcenter without padding
  padding-right: @spacing-xxs;
  background-color: @text-color;
  border-radius: @border-radius-round;
  color: @white;
  transform: rotate(180deg);
}

.customBackTop {
  //button centered. 20px because half of the button width
  right: calc(50% - @button-icon-default-size / 2);
  bottom: @spacing-2xl + @spacing-sm;
  opacity: 0.55;
  @media screen and (min-width: @screen-sm-min) {
    right: @spacing-xs;
    bottom: @spacing-s;
  }
}

.buttonActionForInput {
  display: flex;
  width: @btn-height-base;
  min-width: @btn-height-base;
  height: @btn-height-base;
  align-items: center;
  align-self: center;
  justify-content: space-evenly;
  padding: 0;
  border: none;
  background-color: @primary-color;
  cursor: pointer;
  justify-self: center;

  .transitionWithReducedMotion(0.3s all ease-in-out);

  &[disabled] {
    background-color: @disabled-bg;
    /* stylelint-disable-next-line  plugin/no-unsupported-browser-features */
    cursor: not-allowed;

    &:focus,
    &:hover {
      background-color: @disabled-bg;
      box-shadow: none;
    }
  }

  &:not([disabled]) {
    &:focus,
    &:hover {
      background-color: @primary-color;
      box-shadow: -1px -1px 10px 0 @primary-color;
      outline: none;
    }
  }

  .iconCart {
    width: var(--icon-size-large);

    path,
    circle {
      stroke: @white;
    }
  }

  &.buttonActionForInput--filled {
    background-color: @secondary-color;

    &:focus,
    &:hover {
      background-color: @secondary-color;
      box-shadow: -1px -1px 10px 0 @secondary-color;
    }
  }

  &.buttonActionForInput--preorder {
    background-color: @white;

    &:not([disabled]) {
      &:focus,
      &:hover {
        background-color: @white;
        box-shadow: none;
      }
    }

    .iconCart--preorder {
      path,
      circle {
        stroke: @secondary-color-lighter;
      }
    }
  }

  @media screen and (prefers-reduced-motion: reduce) {
    &.shake {
      animation: none;
      animation-iteration-count: infinite;
    }
  }

  &.shake {
    animation: shake 0.5s;
  }

  @keyframes shake {
    0% {
      transform: translate(1px, 1px) rotate(0deg);
    }

    10% {
      transform: translate(-1px, -2px) rotate(-1deg);
    }

    20% {
      transform: translate(-3px, 0) rotate(1deg);
    }

    30% {
      transform: translate(3px, 2px) rotate(0deg);
    }

    40% {
      transform: translate(1px, -1px) rotate(1deg);
    }

    50% {
      transform: translate(-1px, 2px) rotate(-1deg);
    }

    60% {
      transform: translate(-3px, 1px) rotate(0deg);
    }

    70% {
      transform: translate(3px, 1px) rotate(-1deg);
    }

    80% {
      transform: translate(-1px, -1px) rotate(1deg);
    }

    90% {
      transform: translate(1px, 2px) rotate(0deg);
    }

    100% {
      transform: translate(1px, -2px) rotate(-1deg);
    }
  }
}

// used in infoCenter
.buttonFolder {
  width: 100%;
  height: calc(100% - @spacing-m);
  min-height: 95px;
  padding: @spacing-ml;
  margin-bottom: @spacing-m;
  font-size: @text-ml;
  white-space: normal;
  word-break: break-word;

  @media (max-width: @screen-xs-max) {
    min-height: 75px;
    font-size: @text-m;
  }
}

// used in infoCenter
.buttonAsset {
  display: flex;
  height: initial;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  padding: 0;

  &:focus,
  &:hover {
    .assetTitle {
      text-decoration: underline;
    }
  }

  .assetContent {
    display: flex;
    overflow: hidden;
    width: 100%;
    height: 250px;
    flex-direction: column;
    justify-content: space-between;
    padding: var(--spacing-s);
    margin: 0;
    background-color: var(--light-grey);

    figcaption {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }

  .assetImage,
  .iconAssetPlaceholder {
    overflow: hidden;
    max-height: 200px;
    margin-bottom: var(--spacing-s);
    background-color: var(--light-grey);
    object-fit: contain;
  }

  .iconAssetPlaceholder {
    width: 175px;
    height: 175px;
    align-self: center;
  }

  .assetText {
    display: block;
    overflow-wrap: anywhere;
    white-space: initial;

    & + .assetText {
      margin-top: @spacing-s;
    }
  }
}

.buttonPlain {
  padding: 0;
  border: none;
  margin: 0;
  background: none;
  cursor: pointer;

  .icon {
    margin: 0;
  }
}

.buttonWithSpin {
  // overwrite default of antD div container, use "
  .@{ant-prefix}-spin-nested-loading {
    width: 100%;
  }

  .@{ant-prefix}-spin-container {
    display: flex;
  }
}
