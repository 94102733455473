.sidebar {
  &__content {
    margin-top: var(--spacing-s);
  }
}

.sidebar--product-list {
  .category-tree-block-container {
    padding: 0;
  }

  .select-filter__select {
    height: unset;
  }
}
