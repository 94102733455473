.standing-orders-table {
  .ant-table-column-sorter-inner .active svg {
    color: var(--white);
  }

  .ant-table-cell.ant-table-column-has-sorters:hover {
    color: var(--primary-color) !important;
  }

  .ant-table-cell.ant-table-column-has-sorters:focus {
    color: var(--white);
  }

  button.ant-btn-link {
    height: var(--text-sm);
    padding: 0;
    border: 0;
    margin: 0;
    font-size: var(--text-sm);
    line-height: var(--text-sm);

    & > span {
      height: var(--text-sm);
      font-size: var(--text-sm);
      line-height: var(--text-sm);
    }

    &:hover,
    &:focus {
      & > span {
        text-decoration: underline;
      }
    }
  }
}
