.product-ribbon {
  .ant-ribbon-wrapper {
    // stylelint-disable-next-line
    width: fit-content;

    .ant-ribbon {
      top: 0;
      right: 0;
      background-color: var(--table-grey);

      &.ribbon-productList {
        display: flex;
        align-items: center;
        font-size: 8px;

        @media only screen and (min-width: @screen-lg-min) {
          display: block;
          font-size: var(--text-s);
        }
      }
    }
  }
}
