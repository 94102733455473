@import "WeekplannerItemActions/index";
@import "WeekplannerItemAttribute/index";
@import "WeekplannerItemCarts/index";
@import "WeekplannerItemPrice/index";
@import "WeekplannerItemProduct/index";
@import "WeekplannerItemProductSimple/index";
@import "WeekplannerItemSku/index";
@import "WeekplannerItemVolumePrice/index";

.weekplanner-item {
  display: flex;
  overflow: hidden;
  flex-direction: row;
  border-right: 2px solid var(--white);
  border-bottom: 1px solid var(--disabled-bg);

  &--simple.even {
    background-color: var(--lightest-grey);
  }

  &-cell {
    overflow: hidden;
    padding: var(--spacing-xxs);
    border-right: 1px solid var(--disabled-bg);
  }
}
