.orders-page {
  &__header {
    margin-top: var(--spacing-l);
    margin-bottom: var(--spacing-l);
    gap: var(--spacing-m);

    @media only screen and (min-width: @screen-md-min) {
      gap: 0;
    }

    .datepicker {
      width: 100%;

      @media only screen and (min-width: @screen-md-min) {
        width: unset;
      }
    }
  }

  &__header__title {
    margin-bottom: 0;
    font-size: var(--text-l);
  }

  &__header__num-results {
    display: none;
    align-items: center;
    padding-left: var(--spacing-sm);
    margin: 0;

    @media only screen and (min-width: @screen-md-min) {
      display: flex;
    }
  }

  &__header__inputs {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-m);

    .cartButton {
      background-color: var(--primary-color);
      color: var(--white);

      @media only screen and (min-width: @screen-lg) {
        width: auto;
      }
    }

    .cartButton:hover,
    .cartButton:focus {
      background-color: var(--white);
      color: var(--primary-color);
      .transitionWithReducedMotion(all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1));
    }

    .cartButton:disabled {
      background-color: var(--disabled-bg);
      color: var(--disabled-text);
    }

    @media only screen and (min-width: @screen-md-min) {
      flex-direction: row;
      justify-content: end;
    }
  }

  &__header__inputs__invoice-search,
  &__header__inputs__note-search {
    height: 100%;

    .ant-input-group-wrapper,
    .ant-input-wrapper,
    .ant-input-affix-wrapper {
      height: 100%;
    }

    .ant-input-group-addon {
      background-color: var(--disabled-bg);
      vertical-align: bottom;
    }

    .ant-input-group-addon svg {
      width: var(--icon-size-medium);
      height: var(--icon-size-medium);
      color: var(--white);
    }
  }
}
