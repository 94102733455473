.sort-dropdown {
  display: block;

  .ant-dropdown {
    box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%),
      0 9px 28px 8px rgb(0 0 0 / 5%);
  }

  .ant-radio {
    top: 0;
  }
}

.sort-dropdown__container {
  display: block;
}

.sort-dropdown__button {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border: 0;
}

.sort-dropdown__label {
  font-size: 16px;
  line-height: 25px;
}

.sort-dropdown__label--active {
  font-weight: bold;
}

.sort-dropdown__arrow {
  width: 1em;
  height: 1em;
}

.sort-dropdown__arrow--up {
  transform: rotate(180deg);
}

.sort-dropdown__radio-group {
  display: block;
  min-width: 300px;
  padding: 0;
  background: var(--white);
}

.sort-dropdown__radio-element {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-s);
  margin: 0;

  span {
    display: flex;
    align-items: center;
  }

  &:hover,
  &:focus {
    background-color: var(--hover-background-color);
  }
}

.sort-dropdown__radio-label {
  display: flex;
  align-items: center;
  font-size: 16px;
  gap: var(--spacing-xs);
}

.sort-dropdown__radio-label-sort-icon {
  width: 1.4em;
  height: 1.4em;
}
