@webshopCategoryDownloadsHeight: 40px;
@webshopCategoryDownloadsMaxWidth: 200px;

.webshop-category-downloads {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-s) !important;

  & > .ant-space-item {
    width: 100%;
  }

  &__download {
    display: flex;
    overflow: hidden;
    height: @webshopCategoryDownloadsHeight;
    background-color: var(--light-grey);
    box-shadow: 0 0 0 1.5px var(--disabled-bg);
    color: var(--primary-color);
    cursor: pointer;

    & > .ant-space-item:last-of-type {
      overflow: hidden;
    }

    & > .ant-space-item {
      display: flex;
      height: @webshopCategoryDownloadsHeight;
      align-items: center;

      img {
        width: @webshopCategoryDownloadsHeight;
        height: @webshopCategoryDownloadsHeight;
        background-color: var(--white);
      }

      div {
        overflow: hidden;
        max-width: 100%;
        padding-top: var(--spacing-xs);
        padding-right: var(--spacing-xs);
        padding-bottom: var(--spacing-xs);
        font-weight: bold;
        text-overflow: ellipsis;
        white-space: nowrap;

        @media screen and (min-width: @screen-md-min) {
          max-width: @webshopCategoryDownloadsMaxWidth;
        }
      }
    }

    &:hover,
    &:focus {
      background-color: var(--primary-color);
      box-shadow: 0 0 0 1.5px var(--primary-color);
      color: var(--white);
      .transitionWithReducedMotion;
    }
  }

  @media screen and (min-width: @screen-md-min) {
    flex-direction: row;
    gap: var(--spacing-sm) !important;
  }
}
