@import "QuickOrderActions/index";
@import "QuickOrderForm/index";
@import "QuickOrderItems/index";
@import "QuickOrderItems/QuickOrderItem/index";
@import "QuickOrderItems/QuickOrderItem/QuickOrderProductAlternatives/index";
@import "QuickOrderItems/QuickOrderItem/QuickOrderProductTile/index";
@import "QuickOrderItems/QuickOrderItem/QuickOrderProductPrices/index";
@import "QuickOrderUpload/index";

.quick-order-page {
  &__body {
    display: unset;
  }
}
