.loader-component {
  position: fixed;
  z-index: 999;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: var(--primary-color);

  &__spin {
    position: absolute;
    top: 50%;
    left: 49%;
    color: var(--text-dark-color);

    .ant-spin-dot-item {
      background-color: var(--white);
    }
  }
}
