.standing-order-request-modal-form {
  .ant-form button[type="submit"] {
    width: 100%;
    margin-top: var(--spacing-m);

    @media screen and (min-width: @screen-xl) {
      width: unset;
      margin-top: calc(2 * var(--spacing-m));
    }
  }

  .ant-form-item {
    margin-bottom: var(--spacing-xs);
  }

  .ant-form-item-label > label {
    color: var(--text-color) !important;
    font-weight: bold;
  }

  .ant-form-item-required:before {
    display: none !important;
  }

  .ant-form-item-label > label:after,
  .ant-form-item-required:after {
    display: none !important;
  }

  .ant-form-item-row {
    flex-direction: column;
    align-items: start;
  }

  &__sku .ant-input-affix-wrapper {
    padding-left: 7px !important;
    border-radius: 0 !important;
  }

  &__sku .ant-form-item-control-input {
    .ant-input-group-addon {
      padding: 0;
      border: 1px solid var(--disabled-bg);

      button {
        height: 36px;
        border: 0;
      }
    }

    @media screen and (min-width: @screen-xl) {
      min-width: 200px;
    }
  }

  &__quantity .ant-form-item-control-input {
    max-width: 120px;
  }

  &__result {
    padding: var(--spacing-s);
    margin-top: var(--spacing-s);
    background-color: var(--light-grey);

    & > div:first-of-type {
      margin-bottom: var(--spacing-s);
      color: var(--disabled-text);
      font-size: var(--text-xs);
      font-weight: bold;
      text-transform: uppercase;
    }
  }
}
