@import "WeekplannerHeaderNavigation/index";

.weekplanner-header-wrapper {
  display: grid;
  grid-template-rows: 1fr;
  .transitionWithReducedMotion(grid-template-rows 0.8s ease-in);

  .weekplanner-header {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    margin-bottom: var(--spacing-s);
    gap: var(--spacing-s);

    &-navigation {
      width: 60%;
      flex-grow: 1;
    }

    &__top {
      display: flex;
      height: 35px;
      align-items: center;
      justify-content: space-between;

      &__left {
        display: flex;
      }

      &__right {
        display: flex;

        .ant-btn {
          display: flex;
          gap: var(--spacing-xxs);

          svg {
            width: var(--icon-size-medium);
            height: var(--icon-size-medium);
          }
        }
      }
    }

    &__title {
      max-width: unset;
      padding: 0;
      margin-bottom: 0;

      .webShopTitle {
        align-self: unset;
      }

      .desktopTabletDateSelectWrapper {
        padding-right: 18px !important;
      }
    }

    &__bottom {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      gap: var(--spacing-3xl);

      &__right {
        display: flex;
        flex-direction: row;
        gap: var(--spacing-s);
      }
    }

    &__filter {
      .filter-bar {
        margin-bottom: unset;
        grid-template: auto / minmax(180px, auto) 140px 1fr 140px !important;
        grid-template-areas: "search sort quickFilters filterToggle" !important;
      }

      .filter-bar__quick-filters {
        justify-content: flex-start;
      }

      .filter-bar__col {
        min-height: unset;
        padding: unset;
      }

      .filters-desktop {
        position: absolute;
        z-index: 26;
        top: var(--spacing-xs);
        overflow: auto;
        width: calc(100% - 2 * 48px);
        height: 100%;
      }

      .sort-dropdown,
      .search-filter,
      .filter-bar__col {
        background-color: unset;
      }

      .selected-filters {
        margin-bottom: unset;
      }
    }
  }

  &--is-closed {
    grid-template-rows: 0fr;
  }
}
