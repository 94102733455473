@textList: text-xs @text-xs, text-s @text-s, text-sm @text-sm,
  text-default @text-default, text-sm @text-sm, text-m @text-m, text-ml @text-ml,
  text-l @text-l, text-xl @text-xl, text-2xl @text-2xl,
  text-3xl @text-3xl text-mega @text-mega;

.makeTextClasses(@list) {
  .iter(length(@list));
  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);
    .@{key} {
      font-size: @value;
      line-height: @value * @line-height-normal;
    }
  }
}
.makeTextClasses(@textList);

body,
.text-base {
  color: @text-color;
  font-size: @text-default;
  letter-spacing: 0.32px;
  line-height: @text-default-line-height;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: @spacing-s;
}

h1,
.ant-typography.like-h1,
.like-h1 {
  font-size: @text-l;
  font-weight: @font-weight-bold;
  letter-spacing: 2.4px;
  line-height: 36px;
  text-transform: uppercase;

  a {
    color: inherit;
  }

  @media only screen and (min-width: @screen-md-min) {
    font-size: @text-xl;
    letter-spacing: 3.2px;
    line-height: 42px;
  }

  @media only screen and (min-width: @screen-lg-min) {
    font-size: @text-2xl;
    letter-spacing: 3.2px;
    line-height: 46px;
  }
}

h2,
.ant-typography.like-h2,
.like-h2 {
  font-size: @text-ml;
  font-weight: @font-weight-bold;
  letter-spacing: 2.4px;
  line-height: 32px;
  text-transform: uppercase;

  a {
    color: inherit;
  }

  @media only screen and (min-width: @screen-md-min) {
    font-size: @text-l;
    letter-spacing: 2.4px;
    line-height: 38px;
  }
}

h3,
.ant-typography.like-h3,
.like-h3 {
  font-size: @text-m;
  font-weight: @font-weight-regular;
  letter-spacing: 0.4px;
  line-height: 28px;

  a {
    color: inherit;
  }

  @media only screen and (min-width: @screen-md-min) {
    font-size: @text-ml;
    letter-spacing: 0.48px;
    line-height: 32px;
  }
}

h4,
.ant-typography.like-h4,
.like-h4 {
  font-size: @text-default;
  font-weight: @font-weight-regular;
  letter-spacing: 0.32px;
  line-height: 24px;

  a {
    color: inherit;
  }

  @media only screen and (min-width: @screen-md-min) {
    font-size: @text-sm;
    letter-spacing: 0.36px;
    line-height: 26px;
  }
}

h1,
.ant-typography.like-h1,
.like-h1,
h2,
.ant-typography.like-h2,
.like-h2,
h3,
.ant-typography.like-h3,
.like-h3,
h4,
.ant-typography.like-h4,
.like-h4 {
  color: @primary-color;

  &.inverted {
    color: @white;

    &:hover,
    &:focus {
      color: @primary-color;
    }
  }

  a {
    &:active,
    &:focus,
    &:hover {
      color: @primary-color;
      text-decoration: underline;
    }
  }
}

h5,
.ant-typography.like-h5,
.like-h5 {
  font-size: @text-default;
  font-weight: @font-weight-bold;
  letter-spacing: 0.32px;

  a {
    color: inherit;
  }
}

.text-large.text-large {
  font-size: @text-sm;
  font-weight: @font-weight-regular;
  letter-spacing: 0.36px;
  line-height: 22px;

  @media only screen and (min-width: @screen-lg-min) {
    font-size: @text-m;
    letter-spacing: 0.4px;
    line-height: 24px;
  }
}

.text-medium.text-medium {
  font-size: @text-default;
  letter-spacing: 0.32px;
  line-height: 22px;

  @media only screen and (min-width: @screen-md-min) {
    font-size: @text-sm;
    letter-spacing: 0.36px;
    line-height: 24px;
  }
}

p {
  a {
    color: @text-color;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: @primary-color;
      text-decoration: underline;
    }
  }
}

.text-bold {
  font-weight: @font-weight-bold;
}

.text-normal {
  font-weight: @font-weight-regular;
}

.no-Wrap {
  white-space: nowrap;
}

.pointer {
  cursor: pointer;
}

.text-underline {
  text-decoration: underline;
}

.noUnderline {
  &&,
  &:hover,
  &:focus {
    text-decoration: none;
  }
}

.text-uppercase {
  text-transform: uppercase;
}

.lh-normal {
  line-height: normal;
}

.text-dark {
  color: var(--text-dark-color);
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-disabled {
  color: @disabled-text;
}

.text-primary {
  color: @primary-color;
}
