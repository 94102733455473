.support-portal-tips {
  .container-layout {
    padding-right: 0;
    padding-left: 0;
  }

  .content-block__icon {
    display: flex;
    justify-content: center;
    padding-bottom: var(--spacing-m);

    img {
      max-height: calc(3 * var(--icon-size-large));
    }
  }

  .ant-collapse-item {
    border-bottom: 2px solid var(--table-borders);
  }

  .ant-collapse-header {
    flex-direction: row-reverse;
    padding-top: var(--spacing-sm) !important;
    padding-right: var(--spacing-s) !important;
    padding-bottom: var(--spacing-sm) !important;

    .ant-collapse-header-text {
      font-size: var(--text-m);
      font-weight: bold;
    }

    .ant-collapse-extra {
      max-width: var(--icon-size-large);
      margin-right: var(--spacing-s);

      svg {
        width: var(--icon-size-large);
        height: var(--icon-size-large);
      }
    }

    .ant-collapse-expand-icon {
      display: flex;
      flex-direction: column;
      align-self: center;

      .ant-collapse-arrow {
        position: unset;
        color: var(--table-grey) !important;
      }
    }
  }

  .ant-collapse-content-box {
    font-size: var(--text-default);

    img {
      max-width: 100%;
    }
  }

  .ant-result-title {
    color: var(--table-grey);
    font-size: var(--text-default);
  }
}
