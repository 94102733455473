@import "PortalDashboardBioladen/index";
@import "PortalDashboardDepartmentNews/index";
@import "PortalDashboardLinks/index";
@import "PortalDashboardNews/index";
@import "PortalDashboardStores/index";
@import "PortalDashboardTeaser/index";

.portal-dashboard-page {
  overflow: hidden;

  .webshop-teasers-widget {
    margin-bottom: 0 !important;
  }

  .portal-dashboard-department-news,
  .portal-dashboard-teaser {
    padding: 0;
  }
}
