.noteWrapper {
  display: grid;
  padding-bottom: @spacing-m;
  /* stylelint-disable plugin/no-unsupported-browser-features */
  column-gap: @spacing-s;
  grid-template-areas:
    "title"
    "edit";
  grid-template-rows: auto;
  row-gap: @spacing-xs;

  @media only screen and (max-width: @screen-md-max) {
    padding-bottom: @spacing-xs;
    margin: @spacing-xs 0 @spacing-m 0;
  }

  .title {
    margin: 0;
    line-height: @text-ml;
  }

  textarea {
    height: 100% !important;
    border: @border-width-thin solid @disabled-bg;
    box-shadow: none;
    font-size: @text-default;
  }

  .noteText {
    grid-area: edit;
  }

  &:last-child {
    padding-bottom: 0;
  }

  .@{ant-prefix}-input-textarea-show-count:after {
    font-size: @text-xs;
  }
}
