.contentCategoriesContainer {
  position: fixed;
  z-index: @zIndex-overall;
  top: 0;
  left: 0;
  display: none;
  width: 100vw;
  height: calc(100% - @footer-height-mobile);
  margin: 0;
  background-color: @white;

  &.contentCategoriesContainer--isVisible {
    display: flex;

    @media screen and (min-width: @screen-xl-min) {
      display: none;
    }
  }
}

.categoriesComponent {
  display: flex;
  width: 100%;

  img {
    position: relative;
    z-index: -1;
  }

  .categoriesContainer {
    width: 100%;
    margin: 0;
    background-color: @light-grey;

    &.categoriesContainer--subMenu {
      background-color: @white;
    }

    .categoriesContainerCloseButton {
      display: flex;
      width: var(--icon-size-default);
      height: var(--icon-size-default);
      align-items: center;
      justify-content: center;
      padding: 0;
      border: none;
      margin: 0;
      background: transparent;
      cursor: pointer;

      .closeIcon {
        width: 100%;
        margin: 0;
        color: @text-color;
        transform-origin: center;

        .transitionWithReducedMotion(0.5s transform ease-in-out);

        line {
          stroke-width: @border-width-thick;
        }
      }

      &:hover,
      &:focus {
        .closeIcon {
          transform: rotate(270deg);
        }
      }
    }

    .categoriesHeading {
      display: flex;
      height: @categoriesHeading;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: @spacing-sm;
      border-bottom: @border-width-thin solid @primary-color;
      background-color: @white;
      color: @primary-color;

      &.categoriesHeading--has-arrow {
        padding-left: @spacing-2xl;
      }

      .categoriesHeadingTitle {
        overflow: hidden;
        width: 100%;
        margin: 0;
        margin-right: @spacing-xs;
        color: @primary-color;
        font-size: @text-m;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .categoriesColumn {
      height: calc(100% - @footer-height-mobile-extra - @spacing-ml);
      overflow-y: auto;
      scrollbar-width: thin;

      .categoriesContent {
        padding: 0 0 var(--spacing-ml) 0;
        margin: 0;

        .category {
          position: relative;
          display: flex;
          min-height: @categories-item-height;
          align-items: center;
          justify-content: space-between;
          padding: @spacing-s @spacing-xs @spacing-s @spacing-sm;
          cursor: pointer;

          .categoryIconContainer {
            display: flex;
            width: @nav-icon-size;
            align-items: center;
            justify-content: center;
            padding: 0;
            border: none;
            background: transparent;
            cursor: pointer;

            .categoryIcon {
              width: 100%;
              max-height: var(--icon-size-large);
              align-items: center;
              justify-content: center;
              color: @primary-color;
              pointer-events: none;
            }
          }

          .categoriesArrowNextContainer {
            display: flex;
            width: @button-icon-default-size;
            align-items: center;
            justify-content: center;

            .categoriesArrowNext {
              display: flex;
              width: 100%;
              align-items: center;
              justify-content: center;
              padding: 0;
              border: none;
              background: transparent;
              cursor: pointer;

              .transitionWithReducedMotion(0.3s all ease-in-out);

              &:focus,
              &:hover {
                transform: translateX(calc(@spacing-xs / 2));
              }

              .arrowNext {
                position: relative;
                color: @primary-color;
                transform: rotate(-90deg);
              }
            }
          }

          &:first-child {
            padding-top: @spacing-ml;
          }

          .categoryTitle {
            width: 100%;
            margin-right: @spacing-xs;
            font-size: @text-sm;
            font-weight: @font-weight-regular;
            letter-spacing: 0.36px;

            &.hasMarginLeft {
              margin-left: @spacing-s;
            }
          }
        }
      }
    }

    .categoriesArrowBack {
      position: absolute;
      left: @spacing-xs;
      display: inline-flex;
      padding: 0;
      border: none;
      background: transparent;
      cursor: pointer;
      .transitionWithReducedMotion(0.3s all ease-in-out);

      .arrowBack {
        color: @text-color;
      }

      &:focus,
      &:hover {
        transform: translateX(calc(@spacing-xs / 2 * -1));
      }
    }
  }
}

@media (min-width: @screen-sm-min) {
  .contentCategoriesContainer {
    // position: header, meta navigation and reduce by the width of the shadow
    top: @header-height-mobile;
    height: calc(100% - @header-height-mobile + @spacing-xxs);
    margin-top: -@spacing-xxs;
    background-color: rgb(@text-color 0.7);

    .categoriesComponent {
      width: @categories-sidebar-width;
      margin-top: 0;

      .categoriesContainer {
        .categoriesHeading {
          padding: @spacing-sm @spacing-m @spacing-sm @spacing-2xl;
          border-bottom: @border-width-thin solid @primary-color;

          .categoriesHeadingTitle {
            font-size: @text-ml;
            letter-spacing: 0.48px;
          }
        }

        .categoriesColumn {
          .categoriesContent {
            .category {
              padding: @spacing-s @spacing-sm @spacing-s @spacing-2xl;

              &:first-child {
                padding-top: @spacing-l;
              }
            }

            &__spin {
              left: 145px;
            }
          }
        }
      }
    }

    &.topAdjustmenCart {
      top: @header-height-mobile;
    }
  }
}

@media (min-width: @screen-lg-min) {
  .contentCategoriesContainer {
    top: @header-height-desktop;
    height: calc(100% - @header-height-desktop + @spacing-xxs);

    &.topAdjustmenCart {
      top: @header-height-desktop;
    }
  }
}
