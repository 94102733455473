/* item rows @see at cartItem.less */
.orderDetailListHeading {
  margin-bottom: 0;
}

.orderDetailList {
  align-items: center;
  justify-content: space-between;
}

.reorderModal {
  .calendarWrapper {
    // position it in the middle
    width: 270px;
    margin: 0 auto;

    button {
      border: none;
      background: transparent;
      cursor: pointer;

      &:disabled {
        /* stylelint-disable-next-line  plugin/no-unsupported-browser-features */
        cursor: not-allowed;
      }
    }
  }

  .textWrap {
    overflow: hidden;
    min-height: @spacing-3xl;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
  }

  .modalHighlighted {
    text-decoration: underline;
  }
}

.cartItemGroupHeading {
  font-size: @text-default;
  font-weight: @font-weight-bold;
}

.cartItemsNotInStockMessage {
  background-color: @warning-bg;
}

.cartItemGroup--small {
  display: flex;

  .cartItemGroupHeading {
    flex-grow: 1;
    align-self: center;
  }

  .sumPrice {
    align-self: center;
    margin-right: var(--spacing-s);
  }

  .iconToggle {
    align-self: center;
  }
}
