.seminar {
  padding: @spacing-m 0;
  padding-top: 0;
  margin: 0 auto;
  margin-bottom: @spacing-l;

  @media only screen and (min-width: @screen-xxl-min) {
    padding: @spacing-m 0;
  }

  .seminar-error {
    padding-top: @spacing-m;
    margin: 0 auto;
  }

  .ant-result-title {
    font-size: @text-m;
  }

  p {
    margin: 0;
  }

  h1 {
    margin: 0;
  }

  .seminar-topic-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
}

.seminar-tile {
  display: flex;
  width: 100%;
  height: 73px;
  align-items: stretch;
  border: 2px solid @primary-color;
  background-color: @primary-color;
  color: @white;
  cursor: pointer;

  @media only screen and (max-width: @screen-xs-max) {
    margin-bottom: @spacing-s;
  }

  &-left {
    display: flex;
    width: 25%;
    align-items: center;
    justify-content: flex-end;

    .seminar-svg {
      width: 65px;

      g path,
      g polygon {
        fill: @white;
        stroke-width: 0;
      }
    }
  }

  &-right {
    display: flex;
    width: 75%;
    align-items: center;
    padding-left: @spacing-m;
    text-align: left;
  }

  .tile-nosvg {
    width: 100%;
    justify-content: center;
  }

  &:hover,
  &:active,
  &:focus {
    background-color: @white;
    color: @primary-color;

    .seminar-svg {
      width: 65px;

      g path,
      g polygon {
        fill: @primary-color;
        stroke-width: 0;
      }
    }
  }

  @media only screen and (min-width: @screen-lg-min) {
    .seminar-tile {
      width: 49%;

      &-left {
        width: 15%;
      }

      &-right {
        width: 85%;
      }
    }
  }
}
