.webshop-product-information {
  overflow: hidden;
  height: 100%;
  padding: var(--spacing-l) 0;
  border-bottom: 1px solid var(--primary-color);

  img {
    border-radius: 10px;
  }

  h4 {
    display: flex;
    font-size: var(--text-m);
    font-weight: bold;

    .ant-tag {
      align-self: center;
      background-color: var(--secondary-color);
      border-radius: 10px;
      color: var(--white);
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }

  .webshop-product-information__content {
    @media screen and (min-width: @screen-md) {
      padding-left: var(--spacing-sm);
    }

    .webshop-product-information__content__date {
      margin-bottom: var(--spacing-s);
      color: var(--table-grey);
    }
  }

  .webshop-product-information__buttons {
    align-self: self-end;
    text-align: center;

    button {
      width: 100%;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (min-width: @screen-md-min) {
        width: 50%;
        max-width: 300px;
      }
    }
  }
}
