.delivery-value-widget-section {
  overflow: hidden;
  padding: var(--spacing-sm) var(--spacing-m);
  background-color: var(--light-grey);
  border-radius: 10px;
  color: var(--disabled-text);
  grid-gap: 20px;

  .delivery-value-widget-section__deficit:not(:last-of-type) {
    margin-bottom: var(--spacing-s);
  }

  .delivery-value-widget-section__deficit__label {
    color: var(--disabled-text);
    font-weight: bold;
  }

  .delivery-value-widget-section__deficit__details {
    margin-left: var(--spacing-s);
    color: var(--disabled-text);
    font-size: var(--text-s);

    @media only screen and (max-width: @screen-xs-max) {
      display: block;
      margin-left: 0;
    }
  }

  .ant-progress-bg {
    background-color: var(--table-grey);
  }

  .ant-progress-inner {
    // stylelint-disable-next-line
    background-color: tint(@table-grey, 75%);
  }

  .ant-progress-text {
    color: var(--disabled-text);
    font-weight: bold;
  }
}
