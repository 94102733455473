.collapseAlert {
  border-color: @warning-border;
  margin-bottom: @spacing-l;
  background-color: @warning-bg;
  color: @warning-text;
  font-size: @text-default;
  line-height: @line-height-custom;

  .iconCollapse {
    color: @warning-text;
  }

  .ant-collapse-header {
    .collapseAlertHeading {
      padding: @spacing-s 0;
    }
  }
}

.collapseAlertHeading {
  display: inline-flex;
  min-height: @btn-height-base;
  align-items: center;
  color: @warning-text;
}

.collapseAlertHeadingArticle {
  font-weight: @font-weight-bold;
}

.collapseAlertButton {
  color: @text-color;

  @media (min-width: @screen-md-min) {
    margin-top: @spacing-xxs;
  }
}

.collapseAlertItem {
  border-color: inherit !important;

  .ant-collapse-content {
    border-color: inherit !important;
  }
}

/* This CSS prevents the "Alle Alternativen in den Warenkorb" Button in cart
   from overflowing the wrapping component in mobile view. */

.ant-collapse-extra {
  width: 100%;

  @media (max-width: @screen-sm-min) {
    padding: 0 0 @spacing-s 0 !important;

    .collapseAlertButton {
      text-align: left;
      white-space: normal;
    }
  }

  @media (min-width: @screen-md-min) {
    width: auto;
  }
}
