@import "SupportClient/index";
@import "SupportContact/index";
@import "SupportFaq/index";
@import "SupportIntro/index";
@import "SupportPortalTips/index";
@import "SupportTutorials/index";
@import "SupportTutorials/TutorialVideo/index";

.support-page {
  h1 {
    margin: var(--spacing-xl) auto;
    text-align: center;
  }

  .content-block {
    max-width: 870px;
  }
}
