// grid padding calculated, based on ant paddings:
@grid-padding-mobile: @padding-md;
@grid-padding-desktop: @padding-lg * 2;

// icons
@icon-size-default: 25px;
@icon-size-extra-small: 12px;
@icon-size-small: 16px;
@icon-size-medium: 18px;
@icon-size-large: 30px;
@nav-icon-size: 32px;
@icon-productlist-weekplanner-button: 24px;
@button-icon-default-size: 40px;
@button-icon-small-size: 35px;
@button-icon-input-size: 22px;
@slider-arrow-size: 50px;
//icon size for product attributes
@federation-icon-width: @icon-size-large;
@federation-icon-height: 15px;
//icon size for weekplanner, pdp and product list insignias
@federation-icon-height-insignias: 30px;
@federation-icon-width-insignias-default: 45px;
@federation-icon-width-insignias-large: 70px;
// default size for arrow used in navigation etc.
@icon-arrow-width: 20px;
@icon-arrow-height: 15px;
@icon-arrow-down-width: 13px;
// checkbox
@checkbox-size: 20px;

// header
@header-height-mobile: 120px;
@header-height-desktop: 70px;

@shop-category-nav-height-mobile: 30px;
@shop-category-nav-height: 50px;

//caption
@caption-height-mobile: @text-l * @line-height-normal;
@caption-height-tablet: @text-xl * @line-height-normal;
@caption-height-desktop: @text-2xl * @line-height-normal;

// Categories
@categoriesHeading: 60px;
@categories-item-height: 50px;
@categories-sidebar-width: 315px;

// footer
@footer-height-checkout-mobile: 60px;
@footer-height-mobile: 45px;
@footer-height-mobile-extra: 55px;
@footer-height-desktop: 45px;

// grid padding calculated, based on ant paddings:
@grid-padding-mobile: @padding-md;
@grid-padding-desktop: @padding-lg * 2;

// Filters
@filter-noResults-maxWidth: 500px;
@filter-noResults-height: 57px;
@filter-mobile-option-height: 50px;

// icon navigation
@navigation-item-size: 70px;
@navigation-circle-size: 50px;

// product
@product-image-width-s: 60px;
@product-image-width-sm: 75px;
@product-image-width-md: 90px;
@product-image-width-lg: 160px;
@product-gap-sm: @spacing-s;
@product-gap-md: @spacing-m;

//scrollbar
@default-scrollbar-width: 8px;
@default-scrollbar-border-radius: 8px;

:root {
  --icon-size-default: @icon-size-default;
  --icon-size-extra-small: @icon-size-extra-small;
  --icon-size-small: @icon-size-small;
  --icon-size-medium: @icon-size-medium;
  --icon-size-large: @icon-size-large;
  --button-icon-default-size: @button-icon-default-size;
  --button-icon-small-size: @button-icon-small-size;
  --nav-icon-size: @nav-icon-size;
}
