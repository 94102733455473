.productImageSlider {
  grid-area: img;

  @media (min-width: @screen-md-min) {
    margin-right: @spacing-l;
  }

  // main slider
  .productImageSliderMain {
    @media (max-width: @screen-sm-max) {
      margin-bottom: @spacing-l;
    }

    @media (min-width: @screen-md-min) {
      margin-bottom: @spacing-sm;
    }

    .splide__pagination {
      @media (max-width: @screen-sm-max) {
        bottom: -@spacing-m;
      }

      @media (min-width: @screen-md-min) {
        display: none;
      }
    }
  }

  // thumbnail slider
  .productImageSliderThumbnail {
    .splide__slide {
      cursor: pointer;
    }

    .splide__track > .splide__list > .splide__slide {
      border: none;

      &.is-active {
        border-bottom: 3px solid @primary-color;
      }
    }

    button.splide__arrow {
      background: var(--primary-color);

      svg {
        fill: var(--white);
      }
    }
  }

  // pagination
  .splide__pagination__page {
    width: 15px;
    height: 3px;
    background-color: @disabled-bg;
    border-radius: 0;
    opacity: 1;

    &.is-active {
      background-color: @primary-color;
      transform: scale(1.1);
    }
  }
}
