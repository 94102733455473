[class^="datepickerArrow"] {
  display: flex;
  height: calc(100% - 2 * @spacing-xs);
  align-items: center;
  padding: 0;
  border-top: none;
  border-right: @border-width-thin solid @disabled-bg;
  border-bottom: none;
  border-left: none;
  margin: @spacing-xs 0;
  background-color: transparent;

  @media (max-width: @screen-sm-max) {
    pointer-events: none;
    visibility: hidden;
  }

  &:not([disabled]) {
    cursor: pointer;
  }

  &[disabled] {
    /* stylelint-disable-next-line */
    cursor: not-allowed;
  }

  .icon {
    width: 25px;
  }
}

.datepickerArrowNext {
  transform: rotate(180deg);
  transform-origin: center;
}
