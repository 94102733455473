.portal-dashboard-bioladen-products {
  .content-block__header {
    border-color: var(--impulse-blue);
  }

  .content-block__title {
    color: var(--impulse-blue);
  }

  .content-block__content {
    .iconInfo {
      .cls-1 {
        fill: var(--primary-color);
      }

      &:hover,
      :focus {
        .cls-1 {
          fill: var(--white);
        }
      }
    }
  }
}
