.podcast-player {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--light-grey);
  border-radius: 10px;

  @media only screen and (max-width: @screen-xs-max) {
    flex-direction: column;
  }

  .podcast-player__cover {
    align-self: stretch;

    .ant-image {
      display: block;
    }

    .ant-skeleton-image,
    .ant-image-img {
      border-radius: 10px;
      object-fit: cover;
    }

    .ant-image,
    .ant-image-img,
    .ant-skeleton-image,
    .ant-skeleton-element {
      width: 175px;
      max-width: 175px;
      height: 100%;
    }

    @media only screen and (max-width: @screen-sm-max) {
      display: none;
    }
  }

  .podcast-player__content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    @media only screen and (max-width: @screen-xs-max) {
      width: 100%;
    }

    .podcast-player__header {
      display: flex;
      min-height: 25px;
      justify-content: flex-end;

      .anticon {
        font-size: var(--text-sm);
      }

      .ant-spin {
        margin-right: var(--spacing-s);
      }
    }

    .podcast-player__main {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 var(--spacing-s);

      @media only screen and (max-width: @screen-xs-max) {
        flex-direction: column;
        padding: 0;
      }

      .podcast-player__play-button {
        display: flex;
        flex-direction: row;
        margin-top: var(--spacing-s);
        margin-bottom: var(--spacing-s);

        .anticon {
          color: var(--primary-color);
          font-size: var(--text-3xl);
        }
      }

      .podcast-player__details {
        flex-grow: 1;
        padding-left: var(--spacing-s);

        @media only screen and (max-width: @screen-xs-max) {
          padding: 0 var(--spacing-m);
          margin-top: var(--spacing-m);
          margin-bottom: var(--spacing-m);
        }

        .podcast-player__title {
          display: block;
          color: var(--primary-color);
          font-size: var(--text-m);
          font-weight: var(--font-weight-bold);
        }

        .podcast-player__subtitle {
          display: block;
        }

        .podcast-player__duration {
          color: var(--disabled-text);
          font-size: var(--text-s);
        }

        .podcast-player__actions {
          padding-top: var(--spacing-s);
          font-size: var(--text-m);

          .anticon {
            margin-right: var(--spacing-s);
            color: var(--disabled-text);

            &:hover,
            &:focus {
              color: var(--primary-color);
            }
          }
        }
      }

      .podcast-player__controls {
        display: flex;
        min-width: 100px;
        flex-direction: column;
        align-items: center;
        color: var(--table-borders);
        font-size: var(--text-l);

        .podcast-player__controls-speed {
          border: unset;
          margin-bottom: var(--spacing-s);
          background-color: unset;
          font-size: var(--text-xl);

          &:hover,
          &:focus {
            cursor: pointer;
          }
        }

        .anticon,
        button {
          &:hover,
          &:focus {
            color: var(--primary-color);
          }
        }

        .anticon-forward {
          padding-left: var(--spacing-s);
        }

        .anticon-backward {
          padding-left: var(--spacing-s);
        }
      }
    }

    .podcast-player__footer {
      overflow: hidden;
      padding: 0 var(--spacing-s);

      .ant-slider-rail {
        height: 8px;
        background-color: var(--table-borders);
      }
    }
  }
}

.podcast-player-modal {
  @media only screen and (max-width: @screen-sm-max) {
    padding-bottom: var(--spacing-5xl);
  }

  .ant-tag {
    border: @border-width-base solid var(--primary-color);
    margin-bottom: var(--spacing-s);
    background-color: var(--primary-color);
    color: var(--white);
  }

  .ant-modal-body .podcast-player-modal__podcast {
    display: flex;
    flex-direction: row;
    margin-top: var(--spacing-m);

    @media only screen and (max-width: @screen-sm-max) {
      flex-direction: column;
    }

    .ant-image {
      max-width: 150px;
      max-height: 150px;
      margin-top: var(--spacing-xs);

      @media only screen and (max-width: @screen-sm-max) {
        max-width: 100%;
        max-height: 500px;
        margin-top: unset;
      }
    }

    .podcast-player-modal__podcast-details {
      margin-left: var(--spacing-s);

      @media only screen and (max-width: @screen-sm-max) {
        margin-top: var(--spacing-s);
        margin-left: unset;
      }
    }
  }
}
