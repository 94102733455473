.iconNavigation {
  display: flex;
  flex-wrap: nowrap;
  margin-top: @spacing-m;
  margin-bottom: @spacing-l;
  overflow-x: auto;
}

.iconNavigationList {
  display: flex;
  align-items: flex-start;
  padding: 0;
  margin: 0;
  list-style: none;
}

.iconNavigationButton {
  display: block;
  width: @navigation-item-size;
  flex-wrap: wrap;
  padding: 0;
  border: none;
  background: none;
  cursor: inherit;
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  hyphens: auto;
  overflow-wrap: anywhere;
  white-space: initial;
}

.iconNavigationEntryTitle {
  display: block;
  color: @primary-color;
  font-size: @text-s;
}

.iconNavigationEntryIcon {
  display: inline-flex;
  width: @navigation-circle-size;
  height: @navigation-circle-size;
  align-items: center;
  justify-content: center;
  border-width: @border-width-thin;
  border-style: solid;
  margin: 0 auto @spacing-s;
  border-radius: 50%;

  .transitionWithReducedMotion();

  .icon {
    width: 100%;
    max-width: @nav-icon-size;
    height: 100%;
    max-height: @nav-icon-size;
  }

  &:not(.iconNavigationEntryIconInverted) {
    border-color: @primary-color;
    background: @primary-color;
    color: @white;

    .iconTwoTone {
      --svg-primary-tone: @white;
      --svg-secondary-tone: @primary-color;
    }
  }

  &.iconNavigationEntryIconInverted {
    border-color: @primary-color;
    background: @white;
    color: @primary-color;

    .iconTwoTone {
      --svg-primary-tone: @primary-color;
      --svg-secondary-tone: @white;
    }
  }
}

.iconNavigationEntryIconDashboard {
  margin-bottom: @spacing-s;
  color: @primary-color;
}

.iconNavigationEntry {
  display: inline-flex;
  overflow: hidden;
  flex-direction: column;
  border-bottom: 3px solid transparent;
  cursor: pointer;
  text-align: center;

  .transitionWithReducedMotion();

  &:not(:first-child) {
    margin-left: calc(@spacing-sm / 2);
  }

  &:not(:last-child) {
    margin-right: calc(@spacing-sm / 2);
  }

  &.iconNavigationEntryActive {
    border-color: @primary-color;
  }

  &:focus,
  &:hover {
    text-decoration: underline;
  }
}

.iconNavigationDashboard {
  display: flex;
  flex-wrap: nowrap;
  padding-right: @spacing-sm;
  padding-left: @spacing-sm;
  margin-top: @spacing-m * 2;
  margin-bottom: @spacing-l;
  overflow-x: auto;

  @media screen and (min-width: @screen-md-min) {
    padding-right: @spacing-2xl;
    padding-left: @spacing-2xl;
  }

  @media screen and (min-width: @screen-lg-min) {
    padding-right: @spacing-xl;
    padding-left: @spacing-xl;
  }
}

.iconNavigationEntryDashboard {
  @media only screen and (min-width: @screen-md-min) {
    &:not(:first-child) {
      margin-left: @spacing-l;
    }

    &:not(:last-child) {
      margin-right: @spacing-2xl * 2;
    }
  }
}
