.productInfoPopup {
  display: flex;
  min-width: 130px;
  flex-direction: column;
  font-size: @text-default;

  .productInfoPopup__list {
    margin: 0;

    &:empty {
      display: none;
    }

    &:not([class*="list--"]) {
      padding: var(--spacing-s) var(--spacing-m);
    }

    &.list--unstyled {
      padding: 0;
      list-style: none;
    }
  }

  .productInfoPopupList__list-entry {
    margin-bottom: @spacing-xs;
    line-height: @line-height-normal;

    &.lastInfoItem {
      order: 1;
    }

    .dnr-logo {
      width: var(--icon-size-medium);
      height: var(--icon-size-medium);
      margin-right: var(--spacing-xs);
      vertical-align: text-top;
    }
  }

  .productInfoPopup__info {
    max-height: 250px;
    margin-right: @spacing-sm;
    margin-left: calc(@spacing-sm / 2); // to match spacing of list style item
    overflow-y: auto;

    &:not(:last-child) {
      margin-bottom: @spacing-sm;
    }
  }

  .productInfoPopup__icons {
    display: flex;
    justify-content: flex-end;
    margin: 0 @spacing-sm @spacing-sm;

    &:empty {
      display: none;
    }
  }
}

// active state for productInfoIcons, separate hover/focus from active style
.productInfoIconActiveState() {
  .icon {
    &.iconInfo {
      --svg-icon-color: @white;

      background-color: @primary-color;

      .circle {
        color: @primary-color;
      }
    }

    /*
     * deviate from the actual --svg-XXX variables because the svg has inner styling classes
     * do not take a leaf out of this book!
     */

    &.iconBarCode {
      .barcodeFill {
        fill: @primary-color;
        fill-opacity: inherit;

        --primary-tone: @primary-color;
        --secondary-tone: @primary-color;
      }

      .barcodeInner {
        fill: @white;

        --primary-tone: @white;
      }
    }

    &.iconHeart {
      --primary-tone: @primary-color;
      --secondary-tone: @primary-color;
    }
  }
}

.productInfoIcon {
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  margin: 0;
  background-color: transparent;
  cursor: pointer;

  & + .productInfoIcon {
    margin-left: var(--spacing-sm);
  }

  .icon {
    --svg-icon-color: @primary-color;

    width: var(--nav-icon-size);
    height: var(--nav-icon-size);
    transition: none !important;
    .transitionWithReducedMotion();

    // the icon is not working correctly, therefor so much properties are needed
    &.iconInfo {
      border-radius: 100%;

      circle:first-child {
        .transitionWithReducedMotion();
      }
    }

    &.iconBarCode,
    &.iconHeart {
      * {
        .transitionWithReducedMotion();
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    &:hover,
    &:focus {
      .productInfoIconActiveState();
    }
  }

  &.isActive {
    .productInfoIconActiveState();
  }
}

.productInfoIcons {
  display: flex;
  align-content: center;
  align-items: center;
  grid-area: productInfoIcon;

  &.weekplannerProductInfoIcons {
    justify-content: flex-end;
  }
}
