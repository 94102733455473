.filters-block {
  &:not(.block--checkbox, :last-child) {
    margin-bottom: @spacing-xl;
  }

  &-container {
    .desktopFilterBadge {
      display: flex;
      align-items: center;
      margin-right: @spacing-l;
      opacity: 1;
      user-select: none;

      .transitionWithReducedMotion();
    }

    .desktopFilterPanelHeader {
      // reduce max-width of title for badge
      max-width: calc(100% - @spacing-l - @icon-arrow-width);
      // reserve some space between items
      padding-right: @spacing-xs;
      overflow-wrap: break-word;
    }

    .filterTitle {
      font-size: @text-sm;
      font-weight: @font-weight-bold;
    }

    .buttonAsideClearFilters {
      height: inherit;
      color: @text-color;
      line-height: @text-default-line-height;

      &:hover,
      &:focus {
        color: @text-color;
      }
    }

    @media only screen and (max-width: @screen-sm-max) {
      display: none;
    }

    width: 100%;
    height: auto;
    padding: @spacing-s @spacing-sm 0 @spacing-sm;
    background-color: @light-grey;
    color: @text-color;

    &.filters-block-weekplaner {
      .filters-block__float {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        margin-right: -@spacing-sm;
        margin-left: -@spacing-sm;

        > label,
        > .filters-block:not(.width-full) {
          margin-top: @spacing-ml;
          margin-bottom: 0;
        }

        > .filters-block {
          &:nth-of-type(4n) {
            margin-right: 0;
          }
        }

        > .filters-block.block--checkbox {
          margin-bottom: unset;
        }

        > .filters-block:not(.width-full) {
          width: 25%;
          padding: 0 @spacing-sm;

          &:nth-of-type(-n + 4) {
            margin-top: 0;
          }
        }

        > .filters-block.width-full {
          margin-bottom: @spacing-ml;
        }
      }

      &.clearAllFilters {
        max-width: none;
        flex-basis: auto;
        margin-bottom: 0;
      }

      .filters-block__title {
        margin-top: 0;
      }

      &.weekPlannerEmptyResults {
        padding: @spacing-sm;
      }
    }

    &.clearAllFilters {
      width: 100%;
      max-width: @filter-noResults-maxWidth;
      height: @filter-noResults-height !important;
      flex-basis: @spacing-7xl + @spacing-4xl;

      .filters-block__float {
        width: 100%;
      }
    }
  }

  &Collapse {
    margin-right: -@spacing-sm;
    margin-left: -@spacing-sm;

    .filters-block {
      &:not(.block--checkbox, :last-child) {
        margin-bottom: @spacing-m;
      }

      &__title,
      &--option {
        margin-bottom: @spacing-xs;

        label.ant-checkbox-wrapper {
          max-width: 100%;
          font-size: @text-default;
          font-weight: @font-weight-bold;
          line-height: normal;
        }
      }

      &--option {
        margin-bottom: @spacing-s;

        .ant-checkbox {
          + span {
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .ant-collapse {
      &-item {
        &:not(:first-of-type) {
          border-top: @border-width-thin solid @disabled-bg;
        }

        &-disabled {
          > .ant-collapse-header {
            padding-left: @spacing-sm !important;
            cursor: default;
          }
        }

        &.@{ant-prefix}-collapse-item-active .desktopFilterBadge {
          opacity: 0;
        }
      }

      &-content-box {
        padding-top: @spacing-xs !important;
        padding-bottom: @spacing-s !important;
      }

      &-header {
        // clickable header including icon
        padding-right: @spacing-sm !important;
      }

      &-arrow {
        // clickable header including icon
        pointer-events: none;
      }

      &-header-text {
        display: flex;
        width: 100%;
        justify-content: space-between;
        // compensate line-height for vertical alignment
        padding-top: @spacing-s;
        padding-bottom: @spacing-s;
      }

      &-header > div {
        display: block;
        // compensate line-height for vertical alignment
        padding-top: @spacing-s;
        padding-bottom: @spacing-s;
      }
    }
  }

  &--option {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin: 0 0 @spacing-sm 0;

    & > span {
      padding-top: @spacing-xs;
      margin-left: @spacing-s;
      font-size: @text-default;
      font-weight: @font-weight-regular;
      letter-spacing: 0.32px;
    }

    .buttonFilterProducts {
      width: 100%;
      height: @btn-height-base;
      border: 0;
      font-size: @text-sm;

      &:hover,
      &:focus {
        background-color: @primary-color;
        color: @white;
      }

      &[disabled] {
        border-color: @disabled-bg;
        cursor: auto;
      }
    }
  }

  // DO NOT REMOVE important to be able to use onMouseLeave() instead of onBlur()
  .filterDropDown {
    top: (
      @select-multiple-item-height + @spacing-xs + @spacing-xs +
        @border-width-base + @border-width-base
    ) !important;
    padding-bottom: 0;
  }

  .ant-select {
    width: 100%;
    font-size: @text-default;

    &-selector {
      padding: @spacing-xs;
    }

    .ant-select-selection-item {
      display: flex;
      justify-content: space-between;
      background: @lightest-grey 0 0 no-repeat padding-box;

      &-remove {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .anticon-close {
          margin-top: @spacing-xxs;
          font-size: 7px;
        }
      }

      &-content {
        font-size: @text-default;
        font-weight: @font-weight-regular;
        letter-spacing: 0.32px;
      }
    }
  }

  &__title {
    display: block;
    overflow: hidden;
    margin-bottom: @spacing-s;
    color: @text-color;
    font-size: @text-sm;
    font-weight: @font-weight-regular;
    text-overflow: ellipsis;

    .ant-checkbox-wrapper {
      margin-bottom: 0;
    }
  }

  .filterTitleWrapper {
    width: 100%;

    .buttonClearAllFilters {
      margin-bottom: @spacing-s;
    }
  }
}

.alignScrollNumber {
  vertical-align: middle;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: transparent;
}

.filters-mobile {
  position: fixed;
  z-index: @zIndex-overall;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: flex-end;
  background-color: rgb(@black 0.4);

  .boolean-filter-checkbox {
    margin-top: @spacing-sm;
    margin-left: @spacing-xl;
  }

  .ant-checkbox-wrapper {
    display: flex;
    margin-bottom: @spacing-s;
    font-size: @text-default;
  }

  .ant-badge-count {
    background-color: @secondary-color;
  }

  &__search {
    padding: @spacing-sm @spacing-m;
    background-color: @white;

    .anticon-close-circle svg {
      fill: @black;
    }
  }

  &__content {
    display: flex;
    width: 85%;
    height: 100%;
    box-sizing: border-box;
    flex-direction: column;
    background-color: @light-grey;

    .buttonClearAllFilters {
      color: @primary-color;
      font-size: @text-ml;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: @border-width-thick solid @primary-color;
    background-color: @white;

    &--back {
      padding: 0;
      border: none;
      margin-top: @spacing-xs;
      margin-left: @spacing-m;
      background: transparent;
    }

    &--title {
      overflow: hidden;
      flex-grow: 1;
      margin-right: @spacing-xs;
      color: @primary-color;
      font-size: @text-m;
      letter-spacing: 0.4px;
      line-height: @text-m * @line-height-base;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &--apply {
      display: flex;
      flex-grow: 1;
      justify-content: flex-end;
    }

    &--alle {
      margin-right: @spacing-sm;
      color: @table-borders;
      font-size: @text-default;
      font-weight: @font-weight-regular;
      letter-spacing: 0.32px;
    }

    &--default {
      padding: 0;
      border: none;
      margin-right: @spacing-sm;
      background: transparent;
      color: @text-dark-color;
      letter-spacing: 0.36px;
      text-decoration: underline;
    }

    &--close {
      width: @button-icon-input-size;
      min-width: @button-icon-input-size;
      padding: 0;
      border: none;
      margin-right: @spacing-s - ((@button-icon-input-size - @text-default)/2);
      background: transparent;

      svg {
        vertical-align: middle;
      }
    }
  }

  &__main {
    display: flex;
    overflow: auto;
    width: 100%;
    height: 100%;
    flex-direction: column;
    padding: 0;
    margin: 0;
    background-color: @light-grey;
    overscroll-behavior: contain;

    .ant-spin-spinning {
      margin-top: @spacing-l;
    }
  }

  &__option {
    display: flex;
    height: @filter-mobile-option-height;
    align-items: center;
    border-bottom: @border-width-thin solid @primary-color;
    margin-left: @spacing-m;
    line-height: @filter-mobile-option-height;
    list-style: none;

    &--disabled {
      color: var(--disabled-text);
    }

    &--title {
      overflow: hidden;
      width: 100%;
      margin-right: @spacing-s;
      margin-left: @spacing-sm;
      font-size: @text-sm;
      letter-spacing: 0.36px;
      line-height: @filter-mobile-option-height;
      text-overflow: ellipsis;
      white-space: nowrap;

      .filters-mobile__searchInput-title {
        font-size: smaller;
      }
    }

    &--icon {
      display: flex;
      overflow: hidden;
      width: @button-icon-input-size;
      min-width: @button-icon-input-size;
      align-items: center;
      font-size: @text-m;
    }
  }

  &__filter {
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0;
    border: none;
    background: transparent;
    text-align: left;
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: @spacing-m @spacing-l;
    background-color: @white;

    &--link {
      color: @text-dark-color;
      font-size: @text-default;
      font-weight: @font-weight-regular;
      text-decoration: underline;
    }

    &--saved {
      display: flex;
      margin-top: @spacing-sm;
      color: @primary-color;
      font-size: @text-default;
    }

    &--subtitle {
      padding-bottom: @spacing-xxs;
      margin-left: @spacing-xs;
      font-size: @text-default;
      font-weight: @font-weight-regular;
    }

    &--button {
      width: 100%;

      .button_container {
        margin-bottom: 0;
      }

      button {
        width: 100%;

        span {
          margin: 0 auto;
          color: @primary-color;
          font-size: @text-sm;
          font-weight: @font-weight-regular;
        }
      }
    }

    .ant-spin-spinning {
      display: inline-flex;
    }
  }
}

.filter-product-list-top {
  display: flex;
  justify-content: space-between;

  .ant-col-md-12 {
    width: auto;
    max-width: none;
    flex: 0 1 auto;

    .selected-filters {
      width: auto;
    }
  }

  .button-brand-portrait {
    width: auto;
    background-color: var(--primary-color);
    color: var(--white);
    font-size: var(--text-default);
  }

  .button-brand-portrait:hover,
  .button-brand-portrait:focus {
    background-color: var(--white);
    color: var(--primary-color);
  }
}
