.weekplanner-items-header-default {
  display: flex;
  width: @weekplanner-item-price-width;
  flex: 0 0 1;
  flex-direction: column;

  &__actions {
    width: @weekplanner-item-actions-width;
  }

  &__price {
    flex-shrink: 0;
  }

  &__attribute {
    width: @weekplanner-item-attribute-width;
  }

  &__sku {
    width: @weekplanner-item-sku-width;
    flex-shrink: 0;
  }

  &__volume-price {
    width: @weekplanner-item-volume-price-width;
  }

  &__main {
    min-width: @weekplanner-item-product-simple-width;
    flex: 1 0 0;
  }
}
