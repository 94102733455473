.weekplanner-item-cart {
  display: flex;
  width: @weekplanner-item-cart-width;
  height: 100%;
  flex-direction: column;
  gap: var(--spacing-xs);

  .productCount {
    padding-top: var(--spacing-s);

    .badge-already-ordered {
      margin-left: 1px;
    }
  }

  .productPrices {
    font-size: var(--text-xs);

    .productPrice {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .priceBaseUnit {
      white-space: nowrap;
    }

    .price {
      font-weight: var(--font-weight-bold);
      line-height: calc(var(--text-xs) * 1.1);
      white-space: initial;
    }

    .isStrikeThroughPrice + .hasStrikeThroughPrice {
      line-height: 1;
    }
  }

  .ant-input-number-wrapper,
  .ant-input-number-group-wrapper,
  .productCount
    .productCountInner
    [class*="ant-input-"]:not([class*="-handler"]) {
    height: 30px !important;
  }

  .ant-input-number-input {
    width: 20px;
    padding: 0;
    margin: 0 var(--spacing-xs);
  }

  .ant-input-number-group-addon button {
    width: 10px;
  }

  &--unavailable {
    align-items: stretch;
    justify-content: center;
    gap: var(--spacing-xxs);

    .unavailableBox {
      padding: 0;

      .ant-space {
        gap: var(--spacing-xxs) !important;
      }

      .unavailableHeading {
        padding: 0;
        color: var(--table-grey);
        font-size: var(--text-xxs);
        line-height: var(--text-xxs);

        svg {
          display: none;
        }
      }

      button {
        display: flex;
        height: 20px;
        align-items: center;
        justify-content: center;
        padding: 0;
        border-radius: 0;
        font-size: var(--text-xxs);
      }

      .productAlternativesButton {
        border-color: var(--table-borders);
        background-color: var(--table-borders);

        &:hover,
        &:focus {
          background-color: var(--white);
        }
      }
    }

    .badge-already-ordered {
      // stylelint-disable-next-line
      width: fit-content;
      align-self: center;
      margin-bottom: 0;
    }
  }
}
