.print-view-banner {
  display: flex;
  width: 100vw;
  height: 60px;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--spacing-s);
  background-color: var(--primary-color);
  color: var(--white);

  svg {
    height: 80%;
  }
}
