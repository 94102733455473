.order-overview-modal-wrapper {
  h3 {
    margin-bottom: 0;
  }

  .ant-modal-body {
    margin-bottom: var(--spacing-l);
  }

  .ant-form-item-label label {
    color: var(--text-color);
    font-size: var(--text-default);
    font-weight: var(--font-weight-bold);
  }

  label.ant-form-item-required:before {
    position: absolute;
    right: -15px;
    color: var(--text-default) !important;
  }

  @media only screen and (max-width: @screen-md-min) {
    .ant-form {
      .ant-select,
      .ant-picker-range {
        width: 100%;
      }
    }
  }
}
