.login-request-page {
  .ant-space {
    width: 100%;
  }

  .text-large {
    color: var(--white);
  }

  &__form__footer {
    justify-content: space-between;
  }
}
