.webshop-dashboard-widget-block {
  padding: 0;

  .open-cart-widget-col {
    order: 1;
  }

  .other-open-cart-widget-col {
    order: 2;
  }

  .quick-links-widget-col {
    order: 3;
  }

  .teaser-widget-col {
    order: 4;
  }

  .quick-links-widget,
  .open-cart-widget,
  .other-open-cart-widget,
  .teaser-widget {
    margin-bottom: 0;
  }

  .teaser-widget,
  .other-open-cart-widget {
    margin-top: 0;

    .content-block__content {
      padding-top: 0;
    }

    .divider {
      margin-bottom: var(--spacing-sm);
    }
  }

  .other-open-cart-widget {
    .divider {
      background-color: var(--disabled-bg);
    }
  }

  @media only screen and (min-width: @screen-lg-min) {
    .open-cart-widget-col {
      order: 1;
    }

    .other-open-cart-widget-col {
      order: 3;
    }

    .quick-links-widget-col {
      order: 2;
    }

    .teaser-widget-col {
      order: 4;
    }

    .quick-links-widget,
    .teaser-widget {
      padding-left: var(--spacing-sm);
    }

    .open-cart-widget,
    .other-open-cart-widget {
      padding-right: var(--spacing-sm);
    }

    .other-open-cart-widget.open-cart-widget--full-height {
      .ant-row {
        height: 100%;
        flex-grow: 1;
      }

      .open-cart-widget__footer {
        align-self: flex-end;
        padding-bottom: calc(var(--spacing-sm) + 1px);
      }
    }
  }

  @media only screen and (min-width: @screen-xl-max) {
    .other-open-cart-widget.open-cart-widget--full-height,
    .open-cart-widget__footer,
    .picture-tile {
      min-height: unset;
    }
  }
}
