@spacing-xxs: 2px;
@spacing-xs: 5px;
@spacing-s: 10px;
@spacing-sm: 15px;
@spacing-m: 20px;
@spacing-ml: 25px;
@spacing-l: 30px;
@spacing-xl: 35px;
@spacing-2xl: 50px;
@spacing-3xl: 70px;
@spacing-4xl: 80px;
@spacing-5xl: 200px;
@spacing-6xl: 250px;
@spacing-7xl: 300px;

:root {
  --spacing-xxs: @spacing-xxs;
  --spacing-xs: @spacing-xs;
  --spacing-s: @spacing-s;
  --spacing-sm: @spacing-sm;
  --spacing-m: @spacing-m;
  --spacing-ml: @spacing-ml;
  --spacing-l: @spacing-l;
  --spacing-xl: @spacing-xl;
  --spacing-2xl: @spacing-2xl;
  --spacing-3xl: @spacing-3xl;
  --spacing-4xl: @spacing-4xl;
  --spacing-5xl: @spacing-5xl;
  --spacing-6xl: @spacing-6xl;
  --spacing-7xl: @spacing-7xl;
}
