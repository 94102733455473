.sidebar-switch {
  display: flex;
  overflow: clip;
  flex-direction: column;
  justify-content: center;
  padding: var(--spacing-s);
  background-color: var(--light-grey);
  color: var(--primary-color);
  gap: var(--spacing-s);

  @media screen and (min-width: @screen-lg-min) {
    flex-direction: row;
  }

  &__entry {
    & > span {
      display: flex;
    }

    & > .anticon + span {
      margin-left: 0;
    }

    display: flex;
    max-width: 155px;
    height: 35px;
    flex: 1;
    align-items: center;
    justify-content: center;
    padding: 0 !important;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__title {
      display: flex;
      align-items: center;
      font-weight: bold;
    }

    .ant-badge {
      margin-left: var(--spacing-xs);

      .ant-badge-count {
        background-color: var(--primary-color);
        color: var(--white);
      }
    }

    svg {
      width: 18px;
      height: 18px;
      margin-right: var(--spacing-xs);
      stroke: var(--primary-color);
    }
  }

  &__entry--is-active {
    background-color: var(--primary-color);
    color: var(--white);

    .ant-badge {
      .ant-badge-count {
        background-color: var(--white);
        color: var(--primary-color);
      }
    }

    svg {
      stroke: var(--white);
    }
  }
}
