// legacy
.news {
  //Slider Component
  .ant-divider {
    border-top-width: 2px;
    border-top-color: @primary-color;
    margin: 0;
    margin-top: @spacing-sm;
    margin-bottom: @spacing-sm;
  }

  .news-slider {
    margin: 0 auto;

    .slider-subtitle {
      margin-top: @spacing-m;
      color: @text-color;
      font-size: @text-default;
      letter-spacing: 0.32px;
      text-align: center;
    }

    .video-slide {
      // max-height: 28vw;
      pointer-events: none;
    }

    .is-active {
      opacity: 1;
      pointer-events: unset;
    }

    .splide__arrow--prev {
      left: 0;
    }

    .splide__arrow--next {
      right: 0;
    }

    .splide__arrow {
      z-index: 3;
      width: 50px;
      height: 50px;
      background-color: @primary-color;
      border-radius: 0;
      opacity: 0.8;

      svg {
        width: 32px;
        height: 32px;

        path {
          fill: @white;
        }
      }
    }
  }

  .splide__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.7;
  }

  @media only screen and (max-width: @screen-sm-max) {
    .news-slider {
      margin: 0 auto;

      .video-slide {
        min-height: 160px;
        max-height: 35vw;
      }

      .slider-subtitle {
        width: 100%;
      }

      .splide__arrow {
        width: 35px;
        height: 35px;

        svg {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
