.current-cart-widget-section {
  display: grid;
  height: 100%;
  justify-content: flex-start;
  padding: var(--spacing-sm) var(--spacing-m);
  // stylelint-disable-next-line
  background-color: tint(@secondary-color, 94%);
  //background-color: #f7f7f2;
  border-radius: 10px;
  color: var(--disabled-text);
  grid-gap: 20px;
  // stylelint-disable-next-line
  grid-template-columns: 1fr 1fr;

  @media only screen and (max-width: @screen-xs-max) {
    display: block;
  }

  .ant-statistic {
    height: auto;
  }

  .ant-statistic-title {
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: var(--text-default);
    letter-spacing: 1px;
    line-height: initial;
  }

  .ant-statistic-content-value,
  .ant-statistic-content-suffix {
    color: var(--secondary-color);
    font-size: var(--text-sm);
    font-weight: bold;
  }
}
