.categoriesDropdownContainer {
  position: fixed;
  z-index: 900;

  /* because the dropdown menu should be displayed below the main header and shop category navigation.
  The border width is being substracted because of the box shadow width of the shop category navigation header which results in a gap */
  top: calc(
    @header-height-desktop + @shop-category-nav-height - @border-width-thin
  );
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgb(@text-color 0.7);

  &.categoriesDropdownContainer--isVisible {
    display: flex;

    @media screen and (max-width: @screen-lg-max) {
      display: none;
    }
  }

  @media screen and (prefers-reduced-motion: reduce) {
    .categoriesDropdownInner {
      animation: none;
    }
  }

  .categoriesDropdownInner {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 80vh;
    animation: dropdownFadeInAnimation 0.4s ease-out;
    background-color: @white;

    @keyframes dropdownFadeInAnimation {
      from {
        width: 100%;
        height: 0;
        visibility: hidden;
      }

      to {
        width: 100%;
        height: 80vh;
        transform: translate(0%, 0%);
        visibility: visible;
      }
    }

    /* width of the scrollbar inside the category columns */
    ::-webkit-scrollbar {
      width: @default-scrollbar-width;
    }

    /* roundness and color of the scroll thumb */
    ::-webkit-scrollbar-thumb {
      border-right: @border-width-base solid @white;
      background: @table-borders;
    }

    // hide the scrollbar buttons
    ::-webkit-scrollbar-button {
      display: none;
    }

    @media screen and (prefers-reduced-motion: reduce) {
      .mainCategoriesColumn {
        animation: none;
      }
    }

    .mainCategoriesColumn {
      height: 100%;
      background-color: @light-grey;
      overflow-y: auto;
      scrollbar-width: thin;

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }

        90% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

    .categoriesContent {
      overflow: hidden;
      padding: 0;
      margin: 0;

      .category {
        position: relative;
        display: flex;
        min-height: @categories-item-height;
        align-items: center;
        justify-content: space-between;
        padding: @spacing-s @spacing-xs @spacing-s @spacing-sm;
        cursor: pointer;

        .categoryIconContainer {
          display: flex;
          width: @nav-icon-size;
          align-items: center;
          justify-content: center;

          .categoryIcon {
            display: inline-flex;
            width: var(--icon-size-default);
            height: var(--icon-size-default);
            align-items: center;
            justify-content: center;
            color: @primary-color;
            cursor: pointer;
            pointer-events: none;
          }
        }

        .categoryTitle {
          width: 100%;
          padding-left: @spacing-s;
          margin-right: @spacing-s;
          font-size: @text-sm;
          font-weight: @font-weight-regular;
          letter-spacing: 0.36px;

          &--active {
            color: @primary-color;
            text-decoration: underline;
          }

          &--hasFullHeight {
            display: flex;
            height: 100%;
            align-items: center;
          }

          :hover,
          :focus {
            color: @primary-color;
          }
        }

        .categoriesArrowNextContainer {
          display: flex;
          width: @button-icon-default-size;
          align-items: center;
          justify-content: center;

          .categoriesArrowNext {
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: center;
            padding: 0;
            border: none;
            background: transparent;
            cursor: pointer;

            .arrowNext {
              position: relative;
              color: @primary-color;
              transform: rotate(-90deg);
            }
          }
        }

        &:first-child {
          margin-top: @spacing-sm;
        }

        &:last-child {
          margin-bottom: @spacing-sm;
        }
      }
    }

    .subCategoriesColumn {
      overflow: hidden;
      height: 100%;
      border-left: @border-width-thin solid @disabled-bg;
      overflow-y: auto;
      scrollbar-width: thin;

      .category {
        padding-left: @spacing-m;
      }
    }
  }
}
