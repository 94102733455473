@import "../mixins";

.impulse-teaser {
  a.button {
    padding-top: var(--spacing-xs) !important;
  }

  .button {
    .impulseButton();

    height: 70px;
    justify-content: flex-start;
    font-size: var(--text-sm);
    line-height: var(--text-sm);

    svg,
    span.anticon {
      min-width: var(--button-icon-small-size);
      max-width: var(--button-icon-small-size);
      min-height: var(--button-icon-small-size);
      max-height: var(--button-icon-small-size);
    }
  }

  .ant-card {
    display: flex;
    height: 100%;
    flex-direction: column;
    border-radius: 10px;
    // stylelint-disable-next-line
    background-color: tint(@primary-color, 85%);

    .ant-card-cover img {
      height: 250px;
      border-radius: 10px 10px 0 0;
      object-fit: cover;
      object-position: center;
    }

    .ant-card-body {
      display: flex;
      height: 100%;
      flex-direction: column;
      // stylelint-disable-next-line
      background-color: tint(@primary-color, 85%);
      border-radius: 0 0 10px 10px;

      h2 {
        font-size: var(--text-m);
        letter-spacing: 1px;
        line-height: var(--text-m);
        text-align: center;
      }
    }

    .ant-card-head-title {
      padding-top: 0;
      padding-bottom: var(--spacing-s);
      font-size: var(--text-l);
      letter-spacing: 1px;
      text-align: center;
      text-transform: uppercase;
    }

    .ant-space {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: var(--spacing-s) !important;

      .ant-space-item {
        width: 100%;
      }
    }
  }

  &__description {
    flex-grow: 1;
    color: var(--table-grey);
    font-size: var(--text-m);
    font-weight: normal;
    letter-spacing: 1px;
    line-height: var(--text-ml);
    text-align: center;
  }
}

.impulse-teaser--small {
  .button {
    width: calc(var(--button-icon-small-size) + 2 * var(--spacing-m));
    justify-content: center;

    svg,
    span.anticon {
      margin-right: 0;
    }

    span:last-of-type {
      display: none;
    }
  }

  .ant-card {
    .ant-space {
      flex-direction: row;
      justify-content: flex-start;

      .ant-space-item {
        width: auto;
      }
    }
  }
}
