.inputWithButton {
  .title {
    margin: 0 0 @spacing-xs 0;
    font-size: @text-default;
    text-align: start;
  }

  .inputWithButtonWrapper {
    display: flex;
    flex-direction: row;
  }
}
