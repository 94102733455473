.carts-page,
.carts-page-expired-carts {
  .content-block__content .ant-row {
    flex-grow: 1;
  }

  .open-cart-widget,
  .expired-cart-widget {
    padding-right: 0;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 0;

    .content-block__content {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .picture-tile {
    min-height: unset;
    max-height: 125px;
  }

  .picture-tile img {
    height: 120px;
    object-fit: cover;
    object-position: left top;
  }

  .picture-tile .picture-tile__thumbnail img {
    min-height: unset;
  }
}
