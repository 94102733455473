// right content
.infoCenterContent {
  margin-top: @spacing-2xl;
}

.infoCenterTitle {
  margin-bottom: @spacing-l;
}

// left aside
.infoCenterFolders {
  padding: @spacing-l @spacing-m;
  background-color: @light-grey;

  @media (min-width: @screen-md-min) {
    height: 100%;
    min-height: calc(100vh - @header-height-desktop - @footer-height-desktop);
  }
}

.infoCenterTree {
  color: @primary-color;
  font-size: @text-default;

  @media (min-width: @screen-md-min) {
    font-size: @text-sm;
  }
}

.iconTreeSwitch {
  width: 15px;
  height: 10px;
  transform-origin: center;

  .transitionWithReducedMotion();
}

// node title
.ant-tree-title {
  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.ant-tree-treenode-selected .ant-tree-title {
  text-decoration: underline;
}

.ant-tree-switcher_close .iconTreeSwitch {
  transform: rotate(-90deg);
}

.ant-tree-show-line .ant-tree-switcher {
  background-color: transparent;
}
