.datepickerContainer {
  position: relative;
  display: flex;
  overflow: hidden;
  max-width: 100%;
  height: @btn-height-base;
  justify-content: space-between;
  border: @border-width-base solid @primary-color;
  margin: 0;
  border-radius: 0;
  cursor: pointer;

  &.disabled {
    border: @border-width-base solid @disabled-bg;
  }

  &.dateRangePicker {
    width: 100%;
    justify-content: center;
    border: 0;
    margin: 0;

    .datePickerOrderDetail {
      width: 100%;

      input::placeholder {
        color: @disabled-text;
        font-size: @text-s;
      }
    }
  }

  .datepickerInner {
    display: flex;
    flex-direction: column;
    padding: @spacing-xxs @spacing-xs;

    .datepickerLabel {
      width: 100%;
      font-size: @text-xs;
      line-height: 1;

      &.alignLabel {
        left: 0;
        text-align: center;
      }
    }

    .datepicker {
      width: 100%;
      align-self: flex-end;
      justify-content: center;
      padding: 0;
      border: none;
      box-shadow: none;
      outline: none;
      text-align: center;

      input,
      input:focus,
      input:hover,
      input:visited,
      input:active {
        border: none;
        /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
        appearance: none;
        color: @primary-color;
        outline: none;
        text-align: center;
      }

      .@{ant-prefix}-picker-suffix {
        display: none;
      }

      .@{ant-prefix}-picker-input {
        justify-content: center;
        margin: 0 0 -3px;

        & > input {
          padding: 0;
          margin: 0;
          font-size: @text-default;
          font-weight: 600;
        }
      }

      &.disabled {
        .@{ant-prefix}-picker-input {
          & > input {
            color: @disabled-text;
          }
        }
      }
    }
  }

  @media (min-width: @screen-sm-min) {
    &.dateRangePicker {
      width: auto;
      align-self: flex-start;
      margin: @spacing-m 0;
    }

    @media (min-width: @screen-md-min) {
      max-width: @spacing-6xl;
    }
  }
}
