.webshop-product-information-modal {
  .ant-modal-title {
    display: none;
  }

  .ant-modal-body {
    font-size: var(--text-default);

    .picture > img {
      border-radius: 10px;
    }

    h4 {
      font-size: var(--text-m);
      font-weight: bold;
    }

    .webshop-product-information-modal__body {
      img {
        max-width: 100%;
      }
    }

    .webshop-product-information-modal__attachments {
      a {
        display: inline-flex;
        overflow: hidden;
        max-width: 300px;
        padding: var(--spacing-xs);
        margin-top: var(--spacing-xs);
        margin-right: var(--spacing-s);
        background-color: var(--table-grey);
        border-radius: 5px;
        color: var(--white);
        font-size: var(--text-xs);
        letter-spacing: 1px;
        text-transform: uppercase;

        &:hover,
        &:focus {
          // stylelint-disable-next-line function-no-unknown
          background-color: darken(@table-grey, 10);
          text-decoration: none;
        }

        svg {
          width: var(--icon-size-medium);
          height: var(--icon-size-medium);
          align-self: center;
          margin-right: var(--spacing-xs);
        }

        span {
          overflow: hidden;
          padding-top: var(--spacing-xxs);
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding-top: var(--spacing-xl) !important;
    padding-bottom: var(--spacing-xl) !important;

    button {
      width: 100%;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (min-width: @screen-md-min) {
        width: 50%;
        max-width: 300px;
      }
    }
  }
}
