.company-business-unit-patch-form {
  padding: var(--spacing-m);
  background-color: var(--light-grey);

  .ant-form-item-label label {
    color: var(--text-color);
    font-size: var(--text-default);
    font-weight: var(--font-weight-bold);
  }

  .ant-form-item-label label.ant-form-item-required:before {
    position: absolute;
    right: -15px;
    color: var(--text-default);
  }

  .ant-checkbox-wrapper-in-form-item .anticon {
    color: var(--disabled-text);
    cursor: help;
    font-size: var(--text-s);
  }

  .ant-input-group-compact {
    display: flex;
  }

  &__delivery-dates {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
  }

  &__tags {
    margin-bottom: var(--spacing-m);

    .hint {
      font-size: var(--text-default);
    }

    .ant-tag {
      padding-right: var(--spacing-xs);
      padding-left: var(--spacing-xs);
      margin-bottom: var(--spacing-xs);
      font-size: var(--text-sm);
      line-height: var(--text-l);
    }
  }

  &__submit {
    display: flex;
    justify-content: right;
  }
}
