.bubble {
  display: flex;
  min-width: 25px;
  align-items: center;
  justify-content: center;
  padding: var(--spacing-xxs);
  background-color: var(--secondary-color);
  border-radius: 100px;
  color: var(--white);
}
