.weekplanner-item-sku {
  display: flex;
  width: @weekplanner-item-sku-width;
  flex-direction: column;
  flex-shrink: 0;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: var(--text-s);

  .weekplanner__simple-mode--badges {
    display: flex;
    width: @weekplanner-item-sku-width;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--spacing-xxs);
  }

  .product-advert-badge__weekplanner--default,
  .productNewBadge {
    width: 100%;
    height: var(--text-sm) !important;
    padding: var(--spacing-xxs);
    font-size: var(--text-xxxs);
    letter-spacing: 0;
    line-height: var(--text-s) !important;
    writing-mode: unset;
  }

  .productNewBadge {
    padding: 0;
  }

  .poster-badge {
    display: block;
    line-height: var(--text-s) !important;
  }

  .productNewBadge > p {
    padding: var(--spacing-xxs);
  }
}
