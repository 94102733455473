.filters-desktop {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: var(--spacing-sm);
  margin-bottom: var(--spacing-m);
  background-color: var(--light-grey);
}

.filters-desktop--weekplanner {
  padding: var(--spacing-l) var(--spacing-xl);

  .select-filter__select {
    height: unset;
  }
}

.filters-desktop--weekplanner .filters-desktop__slots {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-right: var(--spacing-l);
  gap: var(--spacing-l);
}

.filters-desktop__close-icon {
  position: absolute;
  top: var(--spacing-m);
  right: var(--spacing-s);
  padding: var(--spacing-xs);
  border: 0;
  background-color: transparent;
  color: var(--primary-color);
  cursor: pointer;

  svg {
    width: 1.25rem;
    height: 1.25rem;
  }
}

.filters-desktop__close.ant-btn {
  display: flex;
  align-self: center;
  margin-top: var(--spacing-sm);

  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: var(--spacing-s);
  }
}
