// #magicNumber #badStyle #feelFreeToOptimize
@button-alternative-width: 163px;

// add spacings between the elements, they are wrapped in a <Spin />
.cartDeletedItemAlternativeWrapper {
  & + & {
    margin-top: @spacing-m;
  }
}

// each "row" of a message
.deletedOrChangedCartItemEntry {
  /* stylelint-disable plugin/no-unsupported-browser-features, declaration-block-no-redundant-longhand-properties */
  display: grid;
  align-items: center;
  column-gap: @spacing-s;
  grid-template-areas:
    "title      dismissDeletedMessage"
    "attributes dismissDeletedMessage"
    "productBasketPreorder productBasketPreorder"
    "alternateProduct alternateProduct";
  grid-template-columns: minmax(220px, 1fr) calc(
      @button-icon-input-size + 2 * @border-width-base
    );

  // only preorder inputs
  &.hasPreorderBasket:not(.hasAlternativesButton) {
    @media (min-width: @screen-md-min) {
      grid-template-areas:
        "title      productBasketPreorder  dismissDeletedMessage"
        "attributes productBasketPreorder  dismissDeletedMessage";
      grid-template-columns: 1fr min-content calc(
          @button-icon-input-size + 2 * @border-width-base
        );
    }

    @media (min-width: @screen-lg-min) {
      grid-template-areas:
        "title      productBasketPreorder dismissDeletedMessage"
        "attributes productBasketPreorder dismissDeletedMessage";
      grid-template-columns: 1fr 2fr calc(
          @button-icon-input-size + 2 * @border-width-base
        );
    }
  }

  // only alternative buttons
  &.hasAlternativesButton:not(.hasPreorderBasket) {
    @media (min-width: @screen-md-min) {
      grid-template-areas:
        "title      alternateProduct dismissDeletedMessage"
        "attributes alternateProduct dismissDeletedMessage";
      grid-template-columns: 1fr @button-alternative-width calc(
          @button-icon-input-size + 2 * @border-width-base
        );
    }

    @media (min-width: @screen-lg-min) {
      grid-template-areas:
        "title      alternateProduct dismissDeletedMessage"
        "attributes alternateProduct dismissDeletedMessage";
      grid-template-columns: minmax(300px, 1fr) @button-alternative-width calc(
          @button-icon-input-size + 2 * @border-width-base
        );
    }
  }

  // alternative button & preorder basket
  &.hasAlternativesButton.hasPreorderBasket {
    @media (min-width: @screen-md-min) {
      grid-template-areas:
        "title                 title                  dismissDeletedMessage"
        "attributes            attributes             dismissDeletedMessage"
        "productBasketPreorder alternateProduct       .";
      grid-template-columns: 1fr @button-alternative-width calc(
          @button-icon-input-size + 2 * @border-width-base
        );
    }

    @media (min-width: @screen-lg-min) {
      grid-template-areas:
        "title      productBasketPreorder alternateProduct dismissDeletedMessage"
        "attributes productBasketPreorder alternateProduct dismissDeletedMessage";
      grid-template-columns: minmax(300px, 1fr) 2fr @button-alternative-width calc(
          @button-icon-input-size + 2 * @border-width-base
        );
    }
  }

  // changedItemsAmount (but not deleted) context.
  &.changedItemsContext {
    grid-template-areas:
      "title      dismissDeletedMessage"
      "changedItemsInfo  .";
    grid-template-columns: minmax(220px, 1fr) 50px;
    grid-template-rows: minmax(20px, 25px);

    .productTitleLink {
      margin-bottom: @spacing-s;
    }

    .productTitleInner {
      color: @primary-color;
      font-size: @text-sm;
    }

    .changedItemsInfo {
      display: flex;
      flex-direction: column;
      grid-area: changedItemsInfo;

      .directToProductLink {
        text-decoration: underline;
      }
    }

    .dismissMessageButton {
      justify-content: flex-end;
    }

    .productTitle {
      margin: 0;
    }

    @media (min-width: @screen-md-min) {
      grid-template-areas: "title  changedItemsInfo  dismissDeletedMessage";
      grid-template-columns: minmax(250px, 1fr) 3fr 50px;

      .directToProductLink,
      .dismissMessageButton {
        margin-bottom: @spacing-xs;
      }

      .changedItemsInfo {
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }
  /* stylelint-enable declaration-block-no-redundant-longhand-properties */

  // message dismiss icon
  .dismissMessageButton {
    grid-area: dismissDeletedMessage;

    .iconClose {
      width: @button-icon-input-size;
      height: @button-icon-input-size;
    }
  }
  /* stylelint-enable plugin/no-unsupported-browser-features */
}
