.ant-popover-placement-bottomRight {
  position: fixed;
  width: 370px;

  &.contactPersonFooter {
    // This value of z-index makes the ContactPerson visible in the mobile view of the cart.
    z-index: @zindex-modal;
    left: 0;
    width: 100%;
    height: 100vh;

    .ant-popover-arrow {
      display: none;
    }

    .ant-popover-content {
      position: fixed;
      top: 0;
      overflow: scroll;
      width: 100vw;
      height: calc(100vh - @footer-height-mobile);
      padding-top: 54px;
      background-color: white;

      .ant-popover-inner-content {
        height: calc(100vh - @footer-height-mobile - 60px);
      }

      .header-user-navigation-mobile {
        position: fixed;
        top: 0;
        width: 100%;
        height: 60px;
        border-bottom: 1px solid var(--primary-color);
        background-color: white;

        .closeIcon {
          width: var(--icon-size-default);
          height: var(--icon-size-default);
          margin-top: -3px;
          margin-right: 2px;
          color: var(--text-dark-color);
          transform-origin: center;

          line {
            stroke-width: @border-width-thick;
          }
        }
      }
    }
  }

  .ant-popover-inner-content {
    padding: 0;

    .header-user-navigation {
      display: flex;
      height: @button-icon-default-size;
      align-items: center;
      justify-content: space-between;
      padding: @spacing-xs @grid-padding-mobile 0 @grid-padding-mobile;

      &.alignRight {
        justify-content: flex-end;
      }
    }

    .button-close-user-navigation {
      position: relative;
      // Left property implemented here beacuse the closing X button was not aligned with the padding of the component.
      left: 3px;
      display: flex;
      width: 30px;
      height: 30px;
      align-items: center;
      justify-content: flex-end;
      padding: 0;
      border: 0;
      box-shadow: none;

      &:hover,
      &:focus {
        background-color: @white;
        color: inherit;
        text-decoration: inherit;
      }

      .closeIcon {
        width: 20px;
        height: 20px;

        .lineClose {
          fill: @text-color !important;
        }
      }
    }

    .divider {
      background-color: @text-default !important;
    }
  }
}
