// brand colors
@primary-color: #51035a;
@primary-color-lighter: #896691;
@primary-color-lightest: #f3f1f5;
@secondary-color: #6c700b;
@secondary-color-lighter: #bec093;
@secondary-color-light: #f4f4ed;
@tertiary-color: #840a5a;
@light-rose: #b78cba;
@search-color: #763f7c;
@highlight-color: #f3f3eb;
@secondary-highlight-color: #ce0087;
@hover-background-color: #ede5ee;
@orange: #d08000;
@light-blue: #1a8ae2;
@light-cyan: #1ab0b7;

//neutral colors
@white: #ffffff;
@lightest-grey: #fafafa;
@light-grey: #f5f3f6;
@disabled-bg: #e6e6e6;
@table-borders: #c1c2c2;
@disabled-text: #848484;
@table-grey: #6c6c6c;
@text-color: #272727;
@text-dark-color: #202020;

// severity
@form-error: #d60132;

@warning-text: #7e6f00;
@warning-bg: #faf2b2;
@warning-border: #fbd81c;

// impulse
@impulse-pink: #d1556d;
@impulse-blue: #233061;
@impulse-purple: #51035a;
@impulse-green: #6c700b;

:root {
  --primary-color: @primary-color;
  --primary-color-lighter: @primary-color-lighter;
  --primary-color-lightest: @primary-color-lightest;
  --secondary-color: @secondary-color;
  --secondary-color-lighter: @secondary-color-lighter;
  --secondary-color-light: @secondary-color-light;
  --tertiary-color: @tertiary-color;
  --light-rose: @light-rose;
  --search-color: @search-color;
  --highlight-color: @highlight-color;
  --secondary-highlight-color: @secondary-highlight-color;
  --hover-background-color: @hover-background-color;
  --white: @white;
  --lightest-grey: @lightest-grey;
  --light-grey: @light-grey;
  --disabled-bg: @disabled-bg;
  --table-borders: @table-borders;
  --disabled-text: @disabled-text;
  --table-grey: @table-grey;
  --text-color: @text-color;
  --text-dark-color: @text-dark-color;
  --orange: @orange;
  --light-blue: @light-blue;
  --light-cyan: @light-cyan;
  --impulse-pink: @impulse-pink;
  --impulse-blue: @impulse-blue;
  --impulse-purple: @impulse-purple;
  --impulse-green: @impulse-green;
}
