.holiday-alert-assets {
  button {
    display: flex;
    align-content: center;
    align-items: center;
    border: 1px solid var(--disabled-text);
    background-color: var(--disabled-text);
    border-radius: 10px;
    color: var(--white);
    font-weight: bold;
    gap: var(--spacing-xs);

    .icon {
      width: var(--icon-size-default);
      height: var(--icon-size-default);
    }

    &:hover,
    &:focus,
    &:active {
      border: 1px solid var(--disabled-text);
      color: var(--disabled-text);
    }
  }
}
