.weekplanner-item-product-simple {
  display: flex;
  min-width: @weekplanner-item-product-simple-width;
  flex: 1 0 0;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--spacing-xs);
  justify-items: center;

  .productTitleLink:hover,
  .productTitleLink:focus {
    text-decoration: none;
  }

  h3.productTitle {
    max-width: 250px;
    align-items: start;
    margin-bottom: 0 !important;
    font-size: var(--text-xs);
    letter-spacing: 0;
    line-height: var(--text-xs);

    .unavailableIcon,
    .preOrderIcon {
      margin-right: var(--spacing-xxs) !important;
      cursor: help;

      svg {
        max-width: var(--icon-size-medium);
        max-height: var(--icon-size-medium);
      }
    }
  }

  .productTitleInner {
    color: var(--text-dark-color);
    font-family: sans-serif;
    font-size: var(--text-xs);
    font-weight: normal;
    line-height: var(--text-xs);
  }

  .productInfoIcon {
    cursor: help;

    svg {
      width: var(--icon-size-medium);
      height: var(--icon-size-medium);
    }
  }
}
