.quick-order-items {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  .quick-order-item:not(:last-of-type) {
    padding-bottom: var(--spacing-sm);
    border-bottom: 1px solid var(--primary-color);
  }
}
