@import "StandingOrdersTable/index";

.standing-orders-page {
  .content-block__content {
    padding-top: 0;
  }

  .ant-alert-warning {
    align-items: flex-start;
    margin-bottom: var(--spacing-m);
    color: @warning-text;

    svg {
      margin-top: calc(2 * var(--spacing-xxs));
    }

    .ant-alert-message {
      color: @warning-text;
    }
  }

  .standing-order-request-modal {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
  }
}
