.quick-link-mixin(@color) {
  border: @border-width-base solid @color;
  background-color: @color;

  &:hover,
  &:focus {
    background-color: var(--white);
    color: @color;

    .quick-link__icon {
      color: @color;
    }
  }
}

.quick-link {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-s);
  border-radius: 10px;
  color: var(--white);

  &:hover,
  &:focus {
    text-decoration: none;
    .transitionWithReducedMotion;

    .quick-link__icon {
      .transitionWithReducedMotion;
    }
  }

  .quick-link__icon {
    margin: 0 var(--spacing-s) 0 0;
    fill: var(--white);

    svg {
      width: 50px;
      height: 50px;
      padding: var(--spacing-xs);
    }
  }

  .quick-link__label {
    overflow: hidden;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
}

.quick-link--primary {
  .quick-link-mixin(var(--primary-color));
}

.quick-link--secondary {
  .quick-link-mixin(var(--secondary-color));
}

.quick-link--tertiary {
  .quick-link-mixin(var(--tertiary-color));
}

.quick-link--default {
  .quick-link-mixin(var(--table-grey));

  #barcode {
    #barcode-outline {
      fill: var(--white);
      stroke: var(--table-grey);
    }

    #barcode-inner {
      fill: var(--table-grey);
    }
  }
}

.quick-link--primary--inverted {
  border: @border-width-base solid var(--primary-color);
  background-color: var(--white);
  color: var(--primary-color);

  &:hover,
  &:focus {
    border: @border-width-base solid var(--white);
    background-color: var(--primary-color);
    color: var(--white);

    .quick-link__icon {
      color: var(--white);
    }
  }
}

.quick-link--primary--outlined {
  .quick-link-mixin(var(--primary-color));

  border: @border-width-base solid var(--white);
}
