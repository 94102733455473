/* stylelint-disable plugin/no-unsupported-browser-features */
// wrapper for productPrice and addToCart components
.productBasket {
  display: grid;
  align-content: center;
  justify-content: flex-end;
  column-gap: @spacing-s;
  grid-area: productBasket;
  grid-template-areas:
    "alreadyOrdered productPrice addToCart addToCart addToCart"
    ".              packageInfo  addToCart addToCart addToCart";
  row-gap: 0;

  .productPriceInfo {
    align-items: baseline;
    justify-content: flex-end;
  }

  .badge-already-ordered {
    align-self: center;
    margin-right: var(--spacing-s);
  }

  @media screen and (min-width: @screen-md-min) {
    column-gap: @spacing-m;

    .badge-already-ordered {
      display: block;
      margin-top: -1px;
    }
  }

  &.hasNextAvailability {
    @media screen and (min-width: @screen-sm-min) {
      padding-top: @spacing-xs;
    }

    justify-content: initial;
    padding-bottom: @spacing-xs;
    margin-top: @spacing-xs;
    margin-bottom: @spacing-xs;
    background-color: @highlight-color;
    grid-area: productBasketPreorder;
    grid-template-areas:
      "nextAvailabilityDate nextAvailabilityDate nextAvailabilityDate"
      "alreadyOrdered       productPrice         addToCart"
      ".                    packageInfo          addToCart";
    grid-template-columns: auto auto fit-content(100%);
    justify-items: stretch;

    @media (min-width: @screen-sm-min) {
      grid-template-areas:
        "nextAvailabilityDate nextAvailabilityDate nextAvailabilityDate nextAvailabilityDate alreadyOrdered productPrice addToCart addToCart addToCart"
        "nextAvailabilityDate nextAvailabilityDate nextAvailabilityDate nextAvailabilityDate .              packageInfo  addToCart addToCart addToCart";
      grid-template-columns: repeat(3, minmax(10px, 1fr)) repeat(2, auto) repeat(
          4,
          max-content
        );
    }

    &.isLoading {
      justify-content: center;
      grid-template-areas: "spinner";
      grid-template-columns: auto;

      .nextAvailabilitySpin {
        display: inline-flex;
        grid-area: spinner;
      }
    }
  }
}

// text block
.nextAvailableDate {
  font-weight: @font-weight-bold;
}

.nextAvailabilityDateInfo {
  align-self: center;
  margin-left: var(--spacing-s);
  color: var(--secondary-color);
  font-size: var(--text-s);
  grid-area: nextAvailabilityDate;
  justify-self: flex-start;

  @media screen and (max-width: @screen-xs-max) {
    width: 100%;
    margin: 0 0 var(--spacing-s) 0;
  }

  .nextAvailabilityExplanation {
    @media screen and (min-width: @screen-sm-min) {
      &:after {
        content: "|";
      }
    }

    .nextAvailabilityExplanationText {
      display: block;
      overflow: hidden;
      max-width: 300px;
      text-overflow: ellipsis;
      vertical-align: bottom;
      white-space: nowrap;

      @media screen and (min-width: @screen-sm-min) {
        display: inline-block;
      }
    }
  }
}

/* stylelint-enable plugin/no-unsupported-browser-features */
.product-basket-preorder-modal {
  #product-basket-preorder-modal-date-picker {
    text-align: center;

    div:first-of-type {
      position: relative !important;
    }

    .ant-picker-dropdown {
      position: unset;
      top: unset;
      left: unset;
    }

    .ant-picker-panel-container {
      box-shadow: none;
    }
  }

  .ant-modal-body {
    padding: 0 var(--spacing-ml) var(--spacing-ml) var(--spacing-ml) !important;
  }

  .ant-picker {
    height: 0;
    border: unset;
  }

  .ant-picker-input {
    display: none;
  }

  .product-basket-preorder-modal__footer {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--spacing-ml);

    a.buttonText {
      text-decoration: none;
    }
  }

  .tooltip-preorder {
    margin-top: var(--spacing-ml);
  }
}
