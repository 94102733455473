.portal-dashboard-stores-classified-ads-item {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-s);
  background-color: var(--light-grey);
  border-radius: 5px;
  gap: var(--spacing-m);

  &__image,
  .ant-image {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 5px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    font-size: var(--text-default);
    gap: var(--spacing-xs);

    > div:first-of-type {
      font-weight: bold;
    }

    > div:nth-child(2) {
      color: var(--table-grey);
      font-size: var(--text-s);
      line-height: var(--text-s);
    }
  }

  .ant-image-mask {
    border-radius: 5px;
  }

  @media screen and (min-width: @screen-sm-max) {
    flex-direction: row;

    &__image,
    .ant-image {
      width: 150px;
      max-width: 150px;
      height: 150px;
    }

    a.button {
      // stylelint-disable-next-line
      width: fit-content;
    }
  }
}
