.filter-slot {
  display: block;
  margin-bottom: var(--spacing-m);
}

.filter-slot--sidebar {
  overflow: clip;
  border-top: 1px solid var(--primary-color);

  &:last-of-type {
    border-bottom: 1px solid var(--primary-color);
  }

  .filter-slot__filters {
    display: grid;
    gap: var(--spacing-s);
  }

  .filter-slot__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--spacing-sm);
    margin-bottom: var(--spacing-sm);
    color: var(--primary-color);
    font-size: var(--text-sm);
  }

  .filter-slot__show-more-button {
    cursor: pointer;
  }

  .filter-slot__filter {
    &:last-of-type {
      margin-bottom: var(--spacing-m);
    }
  }
}

.filter-slot--weekplanner {
  overflow: clip;
  width: 100%;
  border-top: 1px solid var(--primary-color);
  margin-top: var(--spacing-s);

  .filter-slot__filters {
    display: grid;
    gap: var(--spacing-s);
  }

  .filter-slot__title {
    margin-top: var(--spacing-sm);
    color: var(--primary-color);
    font-size: var(--text-sm);
  }
}

.filter-slot__title {
  margin-bottom: var(--spacing-m);
  color: var(--primary-color);
}

.filter-slot__filters {
  display: grid;
  gap: var(--spacing-m) var(--spacing-l);
}

.filter-slot__filters--3-columns {
  grid-template: auto / 1fr 1fr 1fr;
}

.filter-slot__filters--4-columns {
  grid-template: auto / 1fr 1fr 1fr 1fr;
}

.filter-slot__show-more-container {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: var(--spacing-m);

  &:before {
    position: absolute;
    top: 50%;
    right: 0;
    left: 0;
    height: 1.5px;
    background-color: var(--primary-color);
    content: "";
  }
}

.filter-slot__show-more-button {
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-s);
  border: 0;
  background-color: var(--light-grey);
  color: var(--primary-color);

  svg {
    width: 1rem;
    height: 1rem;
    margin-right: 0;
    margin-left: var(--spacing-xs);
  }
}
