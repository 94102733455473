.downloads-page {
  .ant-divider-inner-text {
    color: var(--disabled-text);
  }

  .content-block__content {
    padding-top: 0;
  }

  &__bnn {
    width: 100%;
    padding: var(--spacing-m);
    background-color: var(--light-grey);
    gap: var(--spacing-sm) !important;

    .button,
    .ant-space-item > div {
      width: 100%;
      max-width: 700px;
      margin: 0 auto;
    }

    .ant-space-item > div > div:first-of-type {
      margin-top: 0;
      background-color: var(--primary-color-lighter);
      color: var(--white);
      font-size: var(--text-s);
      text-align: center;
    }
  }
}
