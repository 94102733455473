.productImage {
  overflow: hidden;
  width: 100%;

  @media (max-width: @screen-sm-max) {
    margin-bottom: @spacing-xs;
  }

  &.isUnavailable {
    opacity: 0.3;
  }

  &.productImageList {
    grid-area: img;
  }

  &.weekplannerProductImage {
    display: none;
    visibility: hidden;

    @media screen and (min-width: @screen-xl-min) {
      display: block;
      visibility: visible;
    }
  }

  &.productImageSuggest {
    min-width: @product-image-width-s;
    max-width: @product-image-width-s;
  }
}
