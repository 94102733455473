@import "WeekplannerItemsHeaderCarts/index";
@import "WeekplannerItemsHeaderPagination/index";
@import "WeekplannerItemsHeaderDefault/index";

.weekplanner-items-header {
  display: flex;
  overflow: hidden;
  width: 100%;
  height: @weekplanner-items-header-height;
  flex-direction: row;
  border: 1px solid var(--disabled-bg);

  &-cell {
    display: flex;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xxs);
    border-right: 1px solid var(--disabled-bg);
    color: var(--primary-color);
    font-size: var(--text-s);
    font-weight: var(--font-weight-bold);
    overflow-wrap: anywhere;
    text-align: center;
  }
}
