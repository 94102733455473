.order-table {
  .ant-table-cell h3 {
    align-items: center;
    gap: var(--spacing-xs);
  }

  .ant-table-row-expand-icon-cell,
  .ant-table-cell {
    vertical-align: middle !important;
  }

  .ant-table-row-expand-icon-cell svg {
    padding: var(--spacing-xs);
    background-color: var(--light-grey);
    font-size: var(--text-l);
    vertical-align: middle;
  }

  .ant-table-row-level-0 .ant-table-cell {
    border-color: var(--primary-color);
  }

  .ant-table-expanded-row-level-1
    > .ant-table-cell
    > .ant-table-expanded-row-fixed {
    padding-left: 0 !important;
  }

  .ant-table-expanded-row-level-1 .ant-table-footer {
    padding-bottom: var(--spacing-m);
    background-color: var(--light-grey);
  }

  .ant-table-column-sorter-inner .active svg {
    color: var(--white);
  }

  .ant-table-cell.ant-table-column-has-sorters:hover {
    color: var(--primary-color) !important;
  }

  .ant-table-cell.ant-table-column-has-sorters:focus {
    color: var(--white);
  }

  .download-button.ant-btn {
    padding: 0;
  }

  .download-button {
    svg {
      color: var(--primary-color);
    }
  }

  tr.is-merged,
  tr.is-merged td {
    opacity: 0.7;
  }

  .cart-note-column {
    cursor: pointer;
  }

  &__sub-table {
    button.ant-btn-link {
      height: var(--text-sm);
      padding: 0;
      border: 0;
      margin: 0;
      font-size: var(--text-sm);
      line-height: var(--text-sm);

      & > span {
        height: var(--text-sm);
        font-size: var(--text-sm);
        line-height: var(--text-sm);
      }

      &:hover,
      &:focus {
        & > span {
          text-decoration: underline;
        }
      }
    }

    .ant-table {
      padding-left: 0 !important;
      margin-left: 0 !important;
    }

    .ant-table-thead,
    th.ant-table-cell {
      padding: var(--spacing-xxs) var(--spacing-s) !important;
      // stylelint-disable-next-line
      background-color: tint(@primary-color, 30%) !important;
    }

    .ant-table-tbody,
    td.ant-table-cell {
      background-color: var(--light-grey);
    }
  }

  &__sub-table__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: var(--spacing-s);
    padding-bottom: var(--spacing-s);
    border-bottom: 2px solid var(--primary-color);
    background-color: var(--light-grey);

    h5 {
      font-size: var(--text-l);
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .price {
      font-size: var(--text-m);
      font-weight: bold;
    }
  }
}
