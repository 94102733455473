.ant-scrolling-effect {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    min-height: 100vh;
    padding-bottom: env(safe-area-inset-bottom);
  }
}

.popupMenu {
  z-index: @zIndex-overall;

  .ant-drawer-header {
    display: flex;
    height: @footer-height-checkout-mobile;
    align-items: center;
    padding: @spacing-m @spacing-s @spacing-m @spacing-m;
    border-bottom: 1px solid @primary-color;

    .ant-drawer-extra {
      .drawerExtraWrapper {
        display: flex;
        align-items: center;

        .popupMenuTextButton {
          margin-right: @spacing-m;
          font-size: @text-sm;
          letter-spacing: 0.36px;
          text-decoration: underline;
        }
      }
    }
  }

  .ant-drawer-title {
    font-size: @text-m;
    letter-spacing: 0.4px;
    line-height: @text-m;
  }

  .ant-drawer-footer {
    padding: 0;

    .productAddFooterContainer {
      width: 100%;
      padding: @spacing-sm @spacing-m;

      .productAddSubmitButtons {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    .ant-divider {
      margin: 0;
    }
  }

  .ant-drawer-body {
    padding: 0;
    background-color: @light-grey;
    overflow-y: scroll;
  }
}

.menuItemsList {
  padding: 0;
  margin: 0;
  list-style-type: none;

  .menuItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-right: @spacing-m;
    margin-left: @spacing-m;

    @media screen and (min-width: @screen-md-min) {
      padding-left: @spacing-m;
      margin-left: 0;
    }

    [type="button"] {
      display: flex;
      overflow: hidden;
      width: 100%;
      height: @slider-arrow-size;
      align-items: center;
      justify-content: flex-start;
    }

    .checkIconWrapper {
      display: flex;
      min-width: @button-icon-input-size;
      max-width: @button-icon-input-size;
      align-items: center;
    }

    .menuItemText {
      display: flex;
      overflow: hidden;
      width: 100%;
      margin-right: @spacing-m;
      margin-left: @spacing-s;
      font-size: @text-sm;
      line-height: @text-sm;
      text-align: start;
      text-overflow: ellipsis;
      white-space: nowrap;

      .itemName {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .itemNumber {
        color: var(--primary-color);
        font-size: 15px;
      }
    }

    &:not(:first-of-type) {
      border-top: @border-width-thin solid @primary-color;
    }
  }
}
