.order-list-header {
  h1 {
    text-transform: uppercase;
  }

  .type-selector {
    display: flex;
    width: 100%;
    @media only screen and (min-width: @screen-lg-min) {
      max-width: 570px;
      float: right;
    }

    .single-selector {
      display: flex;
      width: 33.33%;
      align-items: center;
      justify-content: center;
      border: none;
      font-size: var(--text-m);
      font-weight: var(--font-weight-bold);

      @media only screen and (min-width: @screen-lg-min) {
        height: 35px;
        font-size: var(--text-sm);
      }
    }

    .ant-radio-button-wrapper {
      background-color: var(--light-grey);
      color: var(--primary-color);
    }

    .ant-radio-button-wrapper-checked {
      background-color: var(--primary-color);
      color: var(--white);
    }

    .ant-radio-button-wrapper:not(:first-child):before {
      top: 0;
      padding: 0;
    }
  }
}
