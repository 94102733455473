.breakLine {
  border: 1px solid #51035a;
}

.boxSize {
  height: 100%;
}

.ingredients {
  display: flex;
  width: 100%;

  .ingredientsArea {
    display: flex;
    width: 100%;
    flex-direction: column;
    margin-right: 0;

    .ingredientsDirectory {
      margin-bottom: var(--spacing-ml);
    }

    .ingredientsLegend {
      padding: var(--spacing-m);
      background-color: #f8f8f8;

      h4 {
        font-weight: bold;
      }
    }
    @media screen and (min-width: @screen-lg-min) {
      width: 50%;
      margin-right: var(--spacing-m);
    }
  }

  .nutritionalValues {
    width: 100%;
    margin-top: var(--spacing-ml);
    margin-left: 0;

    .ant-table-thead {
      font-size: var(--text-ml);
    }

    @media screen and (min-width: @screen-lg-min) {
      width: 49%;
      margin-top: 0;
      margin-left: var(--spacing-m);
    }
  }

  @media screen and (max-width: @screen-md-max) {
    width: 100%;
    flex-direction: column;
  }
}

.productDetail {
  .productDetailHeader {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    .serviceBtn {
      display: flex;
      align-items: center;
      margin-right: @spacing-xs;

      .icon {
        font-size: @text-ml;
      }
    }
  }

  .productDetailView {
    display: grid;
    overflow: hidden;
    width: 100%;
    margin: 0 0 @spacing-m 0;
    /* stylelint-disable plugin/no-unsupported-browser-features */
    column-gap: 0;
    grid-template-columns: repeat(4, minmax(10px, 1fr));
    grid-template-rows: repeat(6, auto) minmax(70px, max-content) auto;
    row-gap: @spacing-sm;

    &:not(.hasNextAvailability) {
      grid-template-areas:
        "infoBadge         infoBadge         infoBadge         infoBadge"
        "img               img               img               img"
        "insignias         insignias         insignias         insignias"
        "title             title             title             title"
        "attributes        attributes        attributes        attributes"
        "volumePrices      volumePrices      volumePrices      productInfoIcon"
        "productBasket     productBasket     productBasket     productBasket"
        "detailText        detailText        detailText        detailText";
    }

    &.hasNextAvailability {
      grid-template-areas:
        "infoBadge             infoBadge             infoBadge             infoBadge"
        "img                   img                   img                   img"
        "insignias             insignias             insignias             insignias"
        "title                 title                 title                 title"
        "attributes            attributes            attributes            attributes"
        "volumePrices          volumePrices          volumePrices          productInfoIcon"
        "productBasketPreorder productBasketPreorder productBasketPreorder productBasketPreorder"
        "detailText            detailText            detailText            detailText";
    }

    @media screen and (min-width: @screen-md-min) {
      column-gap: 0;
      grid-template-rows:
        auto 25px minmax(32px, max-content) 60px max-content minmax(
          70px,
          max-content
        )
        auto
        auto;
      row-gap: @spacing-m;

      &:not(.hasNextAvailability) {
        grid-template-areas:
          "infoBadge infoBadge  infoBadge         infoBadge         infoBadge         infoBadge"
          "img       .          insignias         insignias         insignias         insignias"
          "img       .          title             title             title             title"
          "img       .          attributes        attributes        attributes        attributes"
          "img       .          volumePrices      volumePrices      volumePrices      productInfoIcon"
          "img       .          productBasket     productBasket     productBasket     productBasket"
          "img       .          detailText        detailText        detailText        detailText"
          "img       .          productBundle     productBundle     productBundle     productBundle";
        grid-template-columns: 250px @spacing-sm repeat(4, minmax(10px, 1fr));
      }

      &.hasNextAvailability {
        grid-template-areas:
          "infoBadge infoBadge                   infoBadge                 infoBadge                 infoBadge"
          "img       insignias                   insignias                 insignias                 insignias"
          "img       title                       title                     title                     title"
          "img       attributes                  attributes                attributes                attributes"
          "img       volumePrices                volumePrices              volumePrices              productInfoIcon"
          "img       productBasketPreorder       productBasketPreorder     productBasketPreorder     productBasketPreorder"
          "img       detailText                  detailText                detailText                detailText"
          "img       productBundle               productBundle             productBundle             productBundle";
        grid-template-columns: 250px @spacing-sm repeat(3, minmax(10px, 1fr));
      }
    }

    @media screen and (min-width: @screen-lg-min) {
      &:not(.hasNextAvailability) {
        grid-template-columns: 470px @spacing-m repeat(4, minmax(10px, 1fr));
      }

      &.hasNextAvailability {
        grid-template-columns: 470px @spacing-m repeat(3, minmax(10px, 1fr));
      }
    }

    @media screen and (min-width: @screen-xxl-min) {
      &:not(.hasNextAvailability) {
        grid-template-columns: 600px @spacing-l repeat(4, minmax(10px, 1fr));
      }

      &.hasNextAvailability {
        grid-template-columns: 600px @spacing-l repeat(3, minmax(10px, 1fr));
      }
    }

    /* stylelint-enable */
    .productImage {
      width: 100%;
      grid-area: img;
    }

    .detailText {
      position: relative;
      top: -@spacing-sm;

      @media screen and (min-width: @screen-md-min) {
        top: -@spacing-m;
      }
    }

    .productAttributes {
      align-self: flex-start;
      grid-area: attributes;
    }

    .productInfoIcons {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      grid-area: productInfoIcon;

      .icon {
        width: auto;
        height: var(--icon-size-default);
      }

      > img {
        margin-right: @spacing-s;
      }
    }

    .productBasket {
      align-items: center;
      padding-right: @spacing-sm;
      padding-left: @spacing-sm;
      background-color: @primary-color;

      &.hasNextAvailability {
        background-color: #f3f3eb;

        .ant-spin-dot-item {
          background-color: @white;
        }

        .nextAvailabilityDateInfo {
          color: var(--secondary-color);

          .nextAvailableDate {
            font-weight: 600;
          }
        }

        .productPriceInfo {
          .productPrice {
            color: var(--text-dark-color);
          }
        }

        .packagingInfo {
          background-color: #f3f3eb;
          color: var(--text-dark-color);

          .packaging-info-base-unit {
            background-color: #f3f3eb;
            color: var(--text-dark-color);
          }

          .packagingInfoInner {
            background-color: #f3f3eb;
            color: var(--text-dark-color);
          }
        }
      }

      .productPrice,
      .isStrikeThroughPrice {
        color: @white;
      }

      .packagingInfo,
      .packagingInfoInner,
      .packaging-info-base-unit {
        background-color: var(--primary-color);
        color: @white;
      }

      &:not(.hasNextAvailability) {
        .remember-cart,
        .productCount .productCountInner {
          border: 2px solid @white;
        }
      }
    }

    .productVolumePrices {
      display: flex !important;
      align-items: center;
      justify-content: flex-start;

      .productVolumePrice,
      .productVolumePrice:last-child {
        margin: @spacing-s @spacing-s 0 0;
      }
    }

    .divider {
      display: none;
    }

    .productInsignias {
      align-items: flex-start;
      justify-content: flex-start;

      .insignie {
        margin-right: @spacing-xs;
        margin-left: 0;
      }
    }
  }

  .productDetailInfo,
  .product-detail-downloads {
    .ant-collapse-content-box,
    .ant-collapse-header {
      padding: 0;
    }

    .ant-collapse-header {
      position: relative;

      .ant-collapse-arrow {
        position: absolute;
        z-index: -1;
        top: @spacing-s;
        right: 0;

        path {
          stroke: @primary-color;
        }
      }
    }
  }

  .productDetailInfo {
    .productInfoList {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      margin: 0;
      list-style: none;

      &.allergyList {
        display: block;
      }

      .productInfoListItem {
        display: flex;
        width: 100%;
        flex-direction: column;
        padding: 0 @spacing-ml @spacing-m 0;

        > span {
          margin-bottom: @spacing-s;
        }

        &.lastInfoItem {
          order: 1;
        }

        &.allergyItem {
          // stylelint-disable-next-line
          width: max-content;
          max-width: 100%;
        }
      }
    }

    .nutritionalValuesList {
      border: var(--primary-color);
      border-width: 2px;
      border-style: solid;

      .ant-table-tbody > tr {
        td:nth-child(even) {
          border-left: 1px solid var(--primary-color);
        }

        td:nth-child(odd) {
          border-right: 1px solid var(--primary-color);
        }
      }

      .ant-table-thead {
        font-size: var(--text-ml);

        tr {
          th:nth-child(even) {
            border-left: 1px solid var(--primary-color);
          }

          th:nth-child(odd) {
            border-right: 1px solid var(--primary-color);
          }
        }
      }

      .ant-table-row:nth-child(even) {
        background-color: #f8f8f8;
      }
    }

    .ingredients-collapse-header {
      display: flex;

      h2 {
        width: 50%;

        &.largeScreen {
          padding-right: var(--spacing-l);
          text-align: right;

          @media screen and (max-width: @screen-md-max) {
            display: none;
          }
        }
      }
    }
  }

  @media screen and (min-width: @screen-sm-min) {
    .productDetailInfo {
      .productInfoList {
        .productInfoListItem {
          width: 50%;
          padding-right: @spacing-m;
        }
      }
    }
  }

  @media screen and (min-width: @screen-md-min) {
    .productDetailInfo {
      .productInfoList {
        .productInfoListItem {
          width: 33.333%;
          padding-right: @spacing-m;
        }
      }
    }
  }

  @media screen and (min-width: @screen-lg-min) {
    .productDetailInfo {
      .productInfoList {
        .productInfoListItem {
          width: 25%;
          padding-right: @spacing-m;
        }
      }
    }

    .productImageSingle {
      padding-right: @spacing-l;
    }

    .detailText {
      position: relative;
      top: 0;
    }
  }

  .product-detail-downloads {
    .button {
      display: flex;
      width: auto;
      max-width: calc(var(--icon-size-large) * 4);
      height: calc(var(--icon-size-large) * 5 + var(--spacing-xl));
      flex-direction: column;
      align-items: center;
      padding: 0;
      place-content: space-between;

      span {
        white-space: pre-wrap;
      }

      svg {
        height: calc(var(--icon-size-large) * 5);
      }

      .ant-btn-loading-icon {
        display: flex;
        justify-content: center;
        place-items: center;
      }

      svg.dnr-icon {
        overflow: visible;
        width: 100%;
        background-color: var(--primary-color);
      }
    }
  }

  .productBundle {
    width: 100%;
    grid-area: productBundle;
  }
}
