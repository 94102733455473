@import "PortalDashboardBioladenNews/index";
@import "PortalDashboardBioladenProducts/index";
@import "PortalDashboardBioladenTeaser/index";

.portal-dashboard-bioladen {
  margin-top: 0;

  > .ant-row > .content-block__content {
    min-height: 250px;
    padding-top: 0;
    // stylelint-disable-next-line
    background-color: tint(@impulse-blue, 94%);
    border-radius: 0 0 5px 5px;
  }

  &__subheader {
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-s);
    background-color: var(--impulse-blue);

    svg {
      width: 120px;
      height: 40px;
      border: 1px solid var(--white);
    }
  }
}
