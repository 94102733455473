/*
 * messages for the scanner responses
 */

.scannerMessage .@{ant-prefix}-message-custom-content {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: flex-start;
}

.scannerMessageContent {
  display: inline-flex;
  align-items: baseline;
  justify-content: flex-start;
}

.scannerMessageContentProduct {
  display: flex;
  flex-direction: column;
  margin-right: @spacing-m;
  text-align: left;
}

.scannerMessage__original-quantity {
  color: red;
}
