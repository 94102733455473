@import "PortalDashboardNewsItem/index";

.portal-dashboard-news {
  margin-bottom: var(--spacing-s);

  .content-block__content {
    padding-bottom: 0;
  }

  &__more {
    margin-top: var(--spacing-s);
    text-align: center;

    .button {
      letter-spacing: 1px;
      text-transform: uppercase;
    }
  }
}
