.support-intro {
  max-width: 870px;
  margin: 0 auto var(--spacing-2xl) auto;

  .faq,
  .contact {
    img {
      max-height: 120px;
      padding-top: var(--spacing-m);
      object-fit: scale-down;
      object-position: center;
    }
  }

  .video-tutorials {
    .quick-link {
      border-color: var(--white);
      background-color: transparent;
      color: var(--white);

      &:hover,
      :focus {
        border-color: var(--white);
        background-color: var(--white);
        color: var(--primary-color);
      }
    }
  }

  .faq {
    background-color: var(--tertiary-color);

    .picture-tile__overlay {
      background-color: transparent;
    }

    .quick-link {
      border-color: var(--white);
      background-color: transparent;
      color: var(--white);

      &:hover,
      :focus {
        border-color: var(--white);
        background-color: var(--white);
        color: var(--tertiary-color);
      }
    }
  }

  .tips {
    background-color: var(--table-grey);

    .picture-tile__thumbnail {
      height: 135px;
      text-align: center;
    }

    svg {
      width: 100%;
      height: 120px;
      max-height: 120px;
      padding-top: var(--spacing-m);
      color: var(--white);
    }

    .picture-tile__overlay {
      background-color: var(--table-grey);
    }

    .quick-link {
      border-color: var(--white);
      background-color: transparent;
      color: var(--white);

      &:hover,
      :focus {
        border-color: var(--white);
        background-color: var(--white);
        color: var(--table-grey);
      }
    }
  }

  .contact {
    background-color: var(--secondary-color);

    .picture-tile__overlay {
      background-color: transparent;
    }

    .quick-link {
      border-color: var(--white);
      background-color: transparent;
      color: var(--white);

      &:hover,
      :focus {
        border-color: var(--white);
        background-color: var(--white);
        color: var(--secondary-color);
      }
    }
  }
}
