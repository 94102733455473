.open-cart-mixin(@color) {
  // stylelint-disable-next-line
  background-color: tint(@color, 94%);

  a:active,
  a:hover,
  a:focus {
    // stylelint-disable-next-line
    background-color: tint(@color, 88%);
  }

  svg {
    color: @color;
  }

  .open-cart-line__value {
    color: @color;
  }
}

.open-cart-line {
  overflow: hidden;
  background-color: var(--light-grey);
  border-radius: 10px;
  color: var(--disabled-text);

  a {
    display: block;
    padding: var(--spacing-sm);
    color: var(--disabled-text);

    &:active,
    &:hover,
    &:visited,
    &:focus {
      text-decoration: none;
    }

    &:active,
    &:hover,
    &:focus {
      // stylelint-disable-next-line
      background-color: darken(@light-grey, 4%);
      .transitionWithReducedMotion;
    }
  }

  svg {
    width: calc(@icon-size-default * 2);
    height: calc(@icon-size-default * 2);
    align-self: center;
    color: var(--table-grey);
    grid-area: cart;

    @media only screen and (max-width: @screen-xs-max) {
      width: @icon-size-large;
      height: @icon-size-large;
    }
  }

  .open-cart-line__content {
    display: grid;
    grid-gap: 10px;
    grid-template-areas: "cart deliveryDate amount";
    // stylelint-disable-next-line
    grid-template-columns: 60px minmax(auto, 180px) auto;
    row-gap: 20px;
    text-align: left;

    @media only screen and (max-width: @screen-xs-max) {
      // stylelint-disable-next-line
      grid-template-columns: 30px auto auto;
      row-gap: 10px;
    }

    .ant-skeleton-active {
      grid-column: 1/-1;
    }

    &.has-header {
      grid-template-areas: "deadline deadline deficit" "cart deliveryDate amount";

      @media only screen and (max-width: @screen-xs-max) {
        grid-template-areas: "deadline deadline deadline" "deficit deficit deficit" "cart deliveryDate amount";
      }
    }
  }

  .open-cart-line__delivery-date {
    grid-area: deliveryDate;

    @media only screen and (max-width: @screen-xs-max) {
      margin-bottom: var(--spacing-s);
    }
  }

  .open-cart-line__amount {
    grid-area: amount;

    @media only screen and (max-width: @screen-xs-max) {
      margin-bottom: var(--spacing-s);
    }
  }

  .open-cart-line__label {
    display: block;
    margin-bottom: var(--spacing-xs);
    letter-spacing: 1px;
  }

  .open-cart-line__value {
    display: block;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .ant-skeleton {
    .ant-skeleton-content {
      ul {
        display: none !important;
      }

      .ant-skeleton-title {
        width: 100% !important;
        margin-bottom: 0 !important;
      }
    }
  }

  @media only screen and (max-width: @screen-xs-max) {
    grid-gap: 5px;
    // stylelint-disable-next-line
    grid-template-columns: 35px auto auto;
  }
}

.open-cart-line--danger {
  .open-cart-mixin(@tertiary-color);
}

.open-cart-line--warning {
  .open-cart-mixin(@orange);
}
