.quick-order-product-prices {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;

  & > div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: var(--spacing-s);
  }

  .productPrices {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);
  }

  .productPrice {
    font-size: var(--text-m);
    font-weight: bold;
    text-align: right;
  }

  @media screen and (min-width: @screen-xl) {
    align-items: flex-end;
  }
}
