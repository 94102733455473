.nav-node {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &--is-new > div {
    display: flex;
    align-items: center;

    .ant-tag {
      border: 0;
      background-color: var(--secondary-color);
      color: var(--white);
      text-transform: uppercase;
    }
  }
}
