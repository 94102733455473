.breadcrumbs {
  display: flex;
  align-items: center;
}

.breadcrumbsList {
  display: none;
  align-items: center;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;

  @media (min-width: @screen-sm-max) {
    display: flex;
  }
}

.breadcrumbsItem {
  margin: 0 @spacing-xs 0 0;
  color: @primary-color;
  font-size: @text-s;
  font-style: italic;

  .breadcrumbsAnchor {
    color: @primary-color;
  }

  .breadcrumbsLast {
    color: @text-color;
  }

  .icon {
    width: @spacing-sm;
  }

  .breadcrumbsArrow {
    width: @spacing-s;
    height: @spacing-s;
    margin-right: @spacing-xs;
    transform: rotate(-90deg);
  }
}
