.container-layout--footer {
  background-color: @primary-color;
}

.footer-nav {
  display: flex;
  height: @footer-height-desktop;
  align-items: center;

  @media screen and (max-width: @screen-xs-max) {
    height: unset;
    justify-content: space-evenly;
    padding: var(--spacing-sm) 0;
  }

  @media screen and (min-width: @screen-sm-min) {
    justify-content: flex-end;
  }
}

.footer-menu {
  padding: 0;
  margin: 0;
  color: @white;
  font-size: @text-s;
  list-style-type: none;

  @media screen and (min-width: @screen-sm-min) {
    margin-right: -@spacing-m;
  }
  @media screen and (max-width: @screen-xs-max) {
    li:first-child button.show-cookie-trigger {
      height: unset;
    }
  }

  li {
    display: inline;
    padding-right: @spacing-m;
    padding-left: @spacing-m;

    @media screen and (max-width: @screen-xs-max) {
      display: block;
      padding: var(--spacing-xxs) 0;
      text-align: center;
    }
  }

  a {
    color: @white;
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: underline;
    }
  }
}

// ios position fix
.ant-popover-placement-bottomRight.contactPersonFooter {
  @supports (-webkit-touch-callout: none) {
    /* CSS specific to iOS devices */
    left: 0 !important;
  }
}

//MOBILE
.tooltipMask {
  position: fixed;
  z-index: 987;
  top: 0;
  right: 0;
  bottom: @footer-height-mobile-extra;
  left: 0;
  background-color: @text-color;
  opacity: 0.8;
}

.mobileFooterWrapper {
  @media screen and (max-width: @screen-xs-max) {
    /*
   * add padding to "preserve" the space
   * overlapped by the absolute positioned footer
   */
    padding-top: @footer-height-mobile-extra;

    &.mobileFooterWrapperHasCheckout {
      padding-top: @footer-height-mobile-extra + @footer-height-checkout-mobile;
    }
  }

  // hide on larger screens to remove min height of ant-row
  @media screen and (min-width: @screen-sm-min) {
    display: none;
  }
}

.mobileFooter {
  position: fixed;
  z-index: @zindex-popover;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  height: calc(@footer-height-mobile-extra + @spacing-ml);
  align-content: flex-end;
  justify-content: space-evenly;
  padding: @spacing-xs 0 @spacing-ml;
  background-color: var(--primary-color);
  box-shadow: 0 2px 10px rgb(@black 0.2);

  p {
    margin-top: @spacing-xs;
    font-size: @text-s;
    line-height: @text-s;
  }

  .footerIconDiv {
    display: flex;
    flex-direction: column;
    color: var(--white);

    .iconContainer {
      position: relative;
      display: flex;
      width: 28px;
      height: 28px;
      align-items: center;
      justify-content: center;
    }

    .iconFavoritelist {
      width: 28px;
      height: 28px;

      &.has-favorite-list {
        color: var(--white);
        fill: var(--white);
      }

      &.has-no-favorite-list {
        color: var(--white);
        fill: var(--primary-color);
      }
    }

    .iconUser,
    .iconSortiment {
      path {
        fill: var(--white);
        fill-opacity: 100%;
      }

      rect {
        fill: var(--white);
      }

      width: auto;
      height: 23px;
    }

    .iconUser {
      width: 28px;
      height: 26px;

      path {
        fill: var(--white);
        stroke: var(--white);
      }
    }

    .contactPersonTeaserWrapper {
      .contactPersonImageTeaser {
        width: 28px;
        height: 28px;
        border-radius: 50%;
      }
    }
  }

  @media screen and (min-width: @screen-sm-min) {
    display: none;
  }

  &.mobileFooterCategoriesVisible {
    z-index: @zIndex-overall;
  }
}

.mobileFooterCol {
  position: relative;
  display: flex;
  width: 25%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  color: var(--white);

  .backgroundItem {
    opacity: 0;
  }

  &.activeNavItem1,
  &.activeNavItem2,
  &.activeNavItem3,
  &.activeNavItem4 {
    color: var(--white);

    svg.icon {
      padding: @spacing-xs;
      transform: scale(1.125);
    }

    img {
      width: auto;
      height: 28px;
      transform: scale(1.25) translateY(-11px);
    }

    .iconContainer {
      background-color: var(--white);
      border-radius: 50%;
      transform: scale(1.25) translateY(-11px);

      .iconCustomerDots {
        fill: var(--white);
      }
    }

    .iconSortiment {
      width: 24px;
      height: 24px;

      path {
        fill: var(--primary-color);
      }

      rect {
        fill: var(--primary-color);
      }
    }

    .iconUser {
      path {
        fill: var(--primary-color);
        stroke: var(--primary-color);
      }
    }

    .backgroundItem {
      position: absolute;
      bottom: calc(100% + 5px);
      display: block;
      align-self: center;
      opacity: 1;

      path {
        fill: var(--primary-color);
      }
    }
  }
}
