.open-cart-widget {
  height: 100%;

  .open-cart-widget__empty {
    display: flex;
    height: 100%;

    .open-cart-widget__empty__content {
      position: relative;
      z-index: 1;
      display: flex;
      overflow: hidden;
      width: 100%;
      height: 100%;
      min-height: 250px;
      max-height: 400px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: var(--light-grey);
      border-radius: 10px;
      color: var(--disabled-text);
      font-size: var(--text-m);
      letter-spacing: 1px;
      text-align: center;

      svg {
        position: absolute;
        z-index: -1;
        top: calc(50% - calc(@icon-size-large * 3));
        left: calc(50% - calc(@icon-size-large * 3));
        width: calc(@icon-size-large * 6);
        height: calc(@icon-size-large * 6);
        color: var(--disabled-bg);
        pointer-events: none;
      }
    }
  }
}

.orderConfirmation {
  .container-layout--inner {
    padding-right: initial;
    padding-left: initial;
  }
}
