.favouriteListsSelector {
  display: flex;
  flex-direction: column;
  padding: @spacing-sm;
  margin: @spacing-xl 0 @spacing-m 0;
  background-color: @light-grey;

  .title {
    margin: 0;
    line-height: @text-ml;
  }

  .itemNumber {
    color: var(--primary-color);
    font-size: 15px;
  }

  .favouriteList {
    .captionWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: @spacing-xs;

      .caption {
        display: flex;

        &.caption--active {
          color: @primary-color;
          text-decoration: underline;
        }
      }

      .name {
        display: block;
        overflow: hidden;

        .favoriteListName {
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      .itemNumber {
        margin-right: 5px;
      }

      .trash {
        margin-left: auto;
      }
    }

    &:not(:first-child) {
      margin-top: @spacing-s;
    }
  }
}

.deleteFavouriteListConfirmModal {
  .ant-modal-title {
    font-weight: @font-weight-bold;
  }

  .ant-modal-body {
    font-size: @text-default;
  }

  .ant-modal-footer {
    .ant-btn {
      font-size: @text-default;
    }
  }
}

.favouriteListsSelector-SortButton {
  .ant-space-item {
    display: flex;
  }

  .ant-space-item:first-child {
    margin-top: var(--spacing-xxs);
  }

  .weiling-arrow {
    width: var(--icon-size-small);
    height: var(--icon-size-small);
  }
}

.favouriteListsSelector-SortDropdown {
  background-color: var(--white);

  .ant-space {
    gap: var(--spacing-xxs) !important;
  }

  .ant-space-item {
    padding: var(--spacing-xs) var(--spacing-s);
  }

  .ant-space-item:hover,
  .ant-space-item:focus {
    background-color: var(--hover-background-color);
  }
}
