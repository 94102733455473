.maintenance-page {
  &__wrapper {
    position: relative;
    min-height: calc(
      100vh - @header-height-mobile - @footer-height-mobile - @spacing-xl
    );

    @media (min-width: @screen-md-min) {
      min-height: calc(
        100vh - @header-height-desktop - @footer-height-desktop - @spacing-xl
      );
    }

    &__background {
      position: fixed;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      aspect-ratio: 16 / 9;
      object-fit: cover;
    }

    &__content {
      max-width: 350px;
      margin-top: var(--spacing-4xl);
      margin-right: auto;
      margin-left: auto;

      @media (min-width: @screen-md-min) {
        margin-top: var(--spacing-5xl);
      }

      h1,
      h2,
      p {
        text-align: center;

        &:not(:first-child) {
          margin-top: var(--spacing-l);
        }
      }

      h1 {
        padding-top: var(--spacing-m);
        padding-bottom: var(--spacing-m);
        border-top: @border-width-thick solid var(--primary-color);
        border-bottom: @border-width-thick solid var(--primary-color);

        & > span:first-child {
          display: block;

          @media (max-width: @screen-sm-max) {
            font-size: calc(var(--text-3xl) * 0.75);
          }
        }
      }
    }
  }
}
