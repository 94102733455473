.article {
  &-container {
    margin-top: @spacing-l;
    margin-bottom: @spacing-l;

    @media only screen and (min-width: @screen-md-min) {
      &--left {
        .article-image {
          order: 1;

          &:hover,
          &:focus {
            cursor: pointer;
          }
        }

        .article-content-container {
          order: 2;
        }
      }

      &--right {
        .article-image {
          justify-content: flex-end;
          order: 2;
          padding-right: 0;

          &:hover,
          &:focus {
            cursor: pointer;
          }
        }

        .article-content-container {
          order: 1;
          padding-left: 0;
        }
      }
    }
  }

  &-image {
    display: flex;
    overflow: hidden;
    align-items: flex-start;

    @media screen and (min-width: @screen-md-min) {
      justify-content: flex-start;
    }

    img {
      width: auto;
      max-height: 200px;

      @media screen and (min-width: @screen-md-min) {
        width: 100%;
        height: auto;
        max-height: 100%;
      }
    }
  }

  &-content {
    margin-top: 25px;

    @media only screen and (min-width: @screen-md-min) {
      margin-top: 0;
    }

    &--grey {
      padding: @spacing-s;
      background-color: @lightest-grey;

      @media only screen and (min-width: @screen-md-min) {
        padding: @spacing-l @spacing-ml @spacing-ml;
      }
    }

    &__title {
      font-size: @text-ml;
      font-weight: @font-weight-bold;
      letter-spacing: 1.92px;
      line-height: 38px;
      text-transform: uppercase;
    }

    &__text {
      font-size: @text-sm;
      font-weight: @font-weight-regular;
      letter-spacing: 0.36px;
      line-height: 24px;

      img {
        max-width: 100%;
      }
    }
  }

  &__productListHeader {
    align-items: center;
    margin-bottom: @spacing-ml;

    h2 {
      margin-bottom: 0;
    }

    @media screen and (min-width: @screen-md-min) {
      margin-bottom: @spacing-xl;

      .ant-col:last-child {
        text-align: right;
      }
    }

    a {
      color: @text-color;
      text-decoration: underline;

      &:hover,
      &:focus {
        color: @primary-color;
      }
    }
  }
}

// linkabale image
.article-content__image {
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.productListArticle {
  margin-bottom: @spacing-2xl;
}
