@portal-dashboard-department-news-item-height: 80px;

.portal-dashboard-department-news-item-mixin(@color) {
  // stylelint-disable-next-line
  background-color: tint(@color, 85%);

  > .ant-tag {
    background-color: @color;
  }

  > svg {
    color: @color;
  }

  &:hover,
  &:focus {
    // stylelint-disable-next-line
    background-color: tint(@color, 80%);
  }
}

.portal-dashboard-department-news-item {
  display: flex;
  overflow: hidden;
  height: @portal-dashboard-department-news-item-height;
  flex-direction: row;
  justify-content: space-between;
  padding: var(--spacing-s);
  border: 0;
  background-color: var(--disabled-bg);
  border-radius: 5px;
  gap: var(--spacing-s);
  text-align: unset;
  text-wrap: unset;

  > div:first-of-type {
    display: flex;
    overflow: hidden;
    flex-direction: column;
    justify-content: space-evenly;
    color: var(--table-grey);
    font-size: var(--text-default);

    > div {
      overflow: hidden;
      text-overflow: ellipsis;
      text-wrap: nowrap;
      white-space: nowrap;
    }

    > div:first-of-type {
      color: var(--text-color);
      font-weight: bold;
    }

    > div:nth-child(2) {
      font-size: var(--text-xs);
      // stylelint-disable-next-line
      color: tint(@table-grey, 30%);
      line-height: var(--text-xxs);
    }
  }

  > svg {
    min-width: 20px;
    height: 8px;
    color: var(--table-grey);
    place-self: center;
  }

  &:hover,
  &:focus {
    > svg.weiling-arrow {
      transform: rotate(-90deg) scale(1.5) !important;
      .transitionWithReducedMotion;
    }
  }
}

.portal-dashboard-department-news-item--default {
  .portal-dashboard-department-news-item-mixin(@table-grey);
}
