.webshop-dashboard {
  .shopCategoryNavigation {
    margin-bottom: var(--spacing-xl) !important;
  }

  .webshop-top-teaser-widget {
    margin-top: 0 !important;

    .content-block__content {
      padding-top: 0 !important;
    }
  }

  .webshop-dashboard-widget-block .ant-col section {
    margin-top: 0;
  }
}
