@import "PortalDashboardStoresClassifiedAds/index";

.portal-dashboard-stores {
  .content-block__content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: var(--spacing-ml);
    padding-bottom: var(--spacing-ml);
    border-top: 2px solid var(--primary-color);
    border-bottom: 2px solid var(--primary-color);

    button {
      display: block;
      padding: 0;
      border: 0;
      background-color: unset;
      cursor: pointer;
    }

    .ant-space {
      justify-content: center;
      gap: var(--spacing-ml) !important;
    }

    .picture-tile {
      min-height: unset !important;
      max-height: unset !important;
      aspect-ratio: 4/3;

      &__quick-link {
        height: 33% !important;
        min-height: unset !important;
      }

      &__overlay__title {
        > div {
          letter-spacing: 1px;
        }

        > div:first-of-type {
          font-size: var(--text-sm);
          text-transform: uppercase;
        }

        > div:last-of-type {
          font-size: var(--text-default);
          font-weight: normal;
        }
      }
    }

    a:hover,
    a:focus,
    button:hover,
    button:focus {
      .picture-tile img {
        transform: scale(1.1);
        .transitionWithReducedMotion;
      }
    }
  }

  @media screen and (min-width: @screen-sm-max) {
    .content-block__content .picture-tile {
      width: 300px;
    }
  }
}
