.seperator {
  margin-right: var(--spacing-xs);
  margin-left: var(--spacing-xs);
}

.productAttributes {
  align-self: center;
  grid-area: attributes;

  @media (min-width: @screen-lg) {
    align-self: stretch;
  }

  .productAttributeDetailIcon {
    svg {
      display: inline;
      width: auto;
      max-width: @federation-icon-width;
      height: 100%;
      max-height: @federation-icon-height;
      /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
      cursor: none;
      transform-origin: center;
      vertical-align: text-top;

      .transitionWithReducedMotion();

      &:hover,
      &:focus {
        transform: scale(2);
      }
    }

    svg.transport-type-icon {
      max-height: @federation-icon-height + 2px;
    }

    svg > rect,
    svg > polygon {
      fill: @text-color;
    }

    svg#frozen {
      max-height: @icon-size-medium;
      padding: var(--spacing-xxs);
      background-color: var(--light-cyan);
      border-radius: 50%;

      & > polygon {
        fill: var(--white);
      }
    }

    svg#temperature-controlled {
      min-width: calc(var(--icon-size-medium) + 1px);
      min-height: calc(var(--icon-size-medium) + 1px);
      padding: var(--spacing-xxs);
      background-color: var(--light-blue);
      border-radius: 50%;

      .cls-1 {
        fill: var(--white);
      }
    }

    svg#bioladen-basic,
    svg#vegan {
      width: var(--icon-size-medium);
      height: var(--icon-size-medium);
      max-height: var(--icon-size-medium);
    }

    svg#vegan {
      fill: #217e0a;
    }
  }

  &.weekplannerProductAttributes {
    font-size: @text-s;

    .product-attribute-grid {
      display: grid;
      grid-template-areas: "sku brandname origin caliber brixdaily";
      // stylelint-disable-next-line
      grid-template-columns: min-content minmax(auto, min-content) repeat(
          10,
          min-content
        );

      .sku {
        grid-area: sku;
      }

      .brandname {
        display: block;
        overflow: hidden;
        grid-area: brandname;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  .productAttributeComposition {
    margin-top: @spacing-xs;

    .innerProductAttributeComposition {
      display: inline-block;
      overflow: hidden;
      width: auto;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
