.filter-bar {
  display: grid;
  align-items: center;
  margin-bottom: var(--spacing-s);
  gap: var(--spacing-s);
  grid-auto-rows: 1fr;
  grid-template: auto / 145px auto;
  grid-template-areas: "sort quickFilters";

  .dynamic-filter {
    height: 29px;

    .bubble {
      background-color: var(--primary-color);
    }
  }

  .sort-dropdown {
    display: flex;
  }
}

.filter-bar--mobile {
  padding: var(--spacing-s);
  background-color: var(--light-grey);
  gap: var(--spacing-xs);
}

.filter-bar.filter-bar--with-search {
  grid-template: auto / 145px auto;
  grid-template-areas:
    "sort     sort"
    "search   search";

  @media screen and (min-width: @screen-sm-max) {
    grid-template: auto / 145px auto auto;
    grid-template-areas:
      "sort         search        search"
      "quickFilters quickFilters  quickFilters";
  }

  @media screen and (min-width: @screen-xl-min) {
    grid-template: auto / 145px minmax(250px, auto) auto;
    grid-template-areas: "sort search quickFilters";
  }
}

.filter-bar.filter-bar--with-toggle {
  grid-template: auto / auto auto;
  grid-template-areas: "sort filterToggle";

  @media screen and (min-width: @screen-sm-max) {
    grid-template: auto / 145px auto;
    grid-template-areas:
      "sort         filterToggle"
      "quickFilters quickFilters";
  }

  @media screen and (min-width: @screen-xl-min) {
    grid-template: auto / auto 145px auto;
    grid-template-areas: "filterToggle sort quickFilters";
  }
}

.filter-bar.filter-bar--with-search.filter-bar--with-toggle {
  grid-template: auto / auto auto;
  grid-template-areas:
    "sort     filterToggle"
    "search   search";

  @media screen and (min-width: @screen-sm-max) {
    grid-template: auto / min-content 145px auto;
    grid-template-areas:
      "filterToggle sort          search"
      "quickFilters quickFilters  quickFilters";
  }

  @media screen and (min-width: @screen-xl-min) {
    grid-template: auto / min-content 145px minmax(250px, auto) auto;
    grid-template-areas: "filterToggle sort search quickFilters";
  }
}

.filter-bar__select-sort {
  grid-area: sort;
}

.filter-bar__quick-filters {
  grid-area: quickFilters;
}

.filter-bar__quick-filters__inner {
  display: flex;
  overflow: clip;
  max-height: 30px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-m);
}

.filter-bar__filters-toggle {
  grid-area: filterToggle;
}

.filter-bar__show-filters__label {
  padding-left: var(--spacing-s);
}

.filter-bar__dropdown {
  .select-filter__dropdown {
    min-width: 300px !important;
  }
}

.filter-bar__search {
  margin-right: 0;
  grid-area: search;
}

.filter-bar__col {
  display: flex;
  height: 100%;
  min-height: 55px;
  align-items: center;
  justify-content: center;
  padding: 0 var(--spacing-s);
  background-color: var(--light-grey);
}

.filter-bar__col--mobile {
  padding: 0;
  background-color: unset;

  .ant-btn {
    display: flex;
    width: 100%;
    height: 35px;
    align-items: center;
    justify-content: center;
    font-weight: bold;

    svg {
      margin-right: var(--spacing-s);
    }
  }
}

.filter-bar__affix .ant-affix {
  z-index: 11;

  & > div {
    padding-top: var(--spacing-s);
    background-color: var(--white);
  }
}
