.productInsignias {
  display: flex;
  flex-flow: row nowrap;
  align-content: flex-start;
  align-items: flex-start;
  justify-content: flex-end;
  grid-area: insignias;

  &Text {
    font-size: @text-s;
    text-align: right;
    white-space: nowrap;
  }

  .insignie {
    width: auto;
    max-width: @federation-icon-width-insignias-default;
    height: @federation-icon-height-insignias;

    & + .insignie {
      margin-left: @spacing-xs;
    }
  }

  .bioladenInsignie {
    max-width: @federation-icon-width-insignias-large;
  }

  .bioladenFairInsignie {
    max-width: calc(
      @federation-icon-width-insignias-large +
        @federation-icon-width-insignias-default
    );
  }

  .steinofenbaecker {
    max-width: calc(
      @federation-icon-width-insignias-large +
        @federation-icon-width-insignias-default
    );
  }

  &.leftAligned {
    justify-content: flex-start;

    .insignie {
      margin-right: @spacing-xs;
      margin-left: 0;
    }
  }

  @media screen and (min-width: @screen-md-min) {
    .insignie:not(:first-child) {
      margin-left: @spacing-xs;
    }
  }
}
