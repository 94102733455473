.portal-tip-modal {
  .ant-modal-title {
    display: none;
  }

  .ant-modal-body {
    padding-top: var(--spacing-l) !important;
    font-size: var(--text-default);

    .picture > img {
      border-radius: 10px;
    }

    h4 {
      font-size: var(--text-m);
      font-weight: bold;

      > span:first-of-type {
        text-transform: uppercase;
      }
    }

    .portal-tip-modal__body img {
      max-width: 100%;
    }

    .ant-checkbox-wrapper {
      color: var(--disabled-text);
      font-size: var(--text-s);
    }

    .divider {
      background-color: var(--disabled-bg);
    }
  }

  .ant-modal-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: var(--spacing-xl) !important;
    padding-bottom: var(--spacing-l) !important;
    gap: var(--spacing-sm);

    button {
      width: 100%;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (min-width: @screen-md-min) {
        width: 50%;
        max-width: 300px;
      }
    }

    > div:first-of-type {
      width: 100%;
      color: var(--disabled-text);
      text-align: left;
    }
  }
}
