.filter-chip {
  display: flex;
  align-items: center;
  padding: var(--spacing-xs) var(--spacing-s);
  border: 1px solid var(--light-grey);
  margin: var(--spacing-xs) var(--spacing-xs) var(--spacing-xs) 0;
  background-color: var(--light-grey);
  border-radius: 50px;
  font-size: var(--text-default);
  gap: var(--spacing-xs);
}

.filter-chip__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: var(--spacing-xs);
}

.filter-chip__label {
  display: block;
  overflow: hidden;
  max-width: 120px;
  font-size: 0.7em;
  text-overflow: ellipsis;

  &:after {
    content: ":";
  }
}

.filter-chip__text {
  display: block;
  overflow: hidden;
  max-width: 200px;
  font-size: 0.9em;
  text-overflow: ellipsis;
}

.filter-chip span.anticon {
  padding: var(--spacing-xs);
  color: inherit;

  &:hover,
  &:focus {
    background-color: var(--hover-background-color);
  }
}

.filter-chip--reset {
  background-color: var(--primary-color-lighter);
  color: var(--white);
  cursor: pointer;
}

.filter-chip--secondary {
  border-color: var(--secondary-color);
}
