.info-button {
  width: var(--icon-size-small);
  height: var(--icon-size-small);
  cursor: help;

  .anticon,
  svg {
    width: var(--icon-size-small);
    height: var(--icon-size-small);
    color: var(--disabled-text);
  }
}
