.totalSum {
  height: 100%;
  padding: @spacing-m @spacing-sm @spacing-ml @spacing-sm;
  background-color: @light-grey;

  .cart-total-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: @spacing-sm;

    .shipping-disclaimer {
      color: @primary-color;
      font-size: @text-s;
    }

    a {
      color: @text-color;
      text-decoration: underline;
    }

    .agb-not-checked {
      a {
        color: @form-error;
      }

      .ant-checkbox-inner {
        border: 2px solid @form-error;
      }
    }

    .agb {
      margin-left: @spacing-s;
    }
  }

  .checkbox-div {
    align-items: flex-start;
    justify-content: flex-start;
  }

  p {
    margin: 0;
  }
}

.cart-checkout-popconfirm {
  position: relative !important;
  max-width: 300px;

  .ant-popover-inner-content {
    padding: var(--spacing-s) !important;
  }

  .ant-popover-arrow {
    top: unset !important;
  }
}
