.dropdown-button {
  .ant-dropdown-button button:first-of-type {
    overflow: hidden;
    border-right: 1px solid var(--primary-color);
    color: var(--primary-color);

    span {
      overflow: hidden;
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ant-dropdown-button button:last-of-type {
    color: var(--primary-color);

    svg {
      width: @icon-size-medium;
      height: @icon-size-medium;
    }
  }

  &--full-width {
    .ant-space,
    .ant-space-item {
      width: 100%;
    }

    .ant-dropdown-button {
      display: flex;

      button:first-of-type {
        flex-grow: 1;
      }
    }
  }
}
