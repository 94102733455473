.ant {
  &-card {
    font-weight: @font-weight-bold;

    &-head {
      &-title {
        color: @primary-color;
        font-size: @text-m;
        font-weight: @font-weight-bold;
        letter-spacing: 0.4px;

        div {
          display: flex;
          justify-content: space-between;

          p {
            display: inline-flex;
            width: auto;
            margin: 0;
          }

          .publish-date {
            align-self: flex-end;
            color: @text-color;
            font-size: @text-sm;
          }
        }

        @media screen and (min-width: @screen-md-min) and (max-width: @screen-md-max) {
          div {
            display: block;
            justify-content: space-between;

            p {
              display: block;
              width: 100%;
              margin-bottom: 0;
            }

            .publish-date {
              display: block;
              align-self: flex-start;
            }
          }
        }
      }
    }

    &-body {
      background-color: @light-grey;
    }
  }
}
