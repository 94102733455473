.open-cart-details-mixin(@color) {
  .open-cart-line-details__deadline .ant-statistic-content {
    color: @color;
  }

  .open-cart-line-details__deficit
    .ant-progress
    .ant-progress-inner
    .ant-progress-bg {
    background-color: @color;
  }
}

@media only screen and (max-width: @screen-xs-max) {
  display: block;
}

.open-cart-line-details__deadline {
  grid-area: deadline;

  .ant-statistic-content {
    display: inline-block;
    color: var(--disabled-text);
    font-size: var(--text-s);
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1.5rem;
  }
}

.open-cart-line-details__deficit {
  grid-area: deficit;

  .ant-progress {
    height: 20px;

    .ant-progress-outer {
      width: calc(100% - 60px);
      height: 100%;
    }

    .ant-progress-inner {
      padding: var(--spacing-xxs);
      border: 1px solid var(--disabled-text);
      border-right: 0;
      background-color: var(--white);
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;

      .ant-progress-bg {
        height: 14px !important;
        background-color: var(--table-grey);
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }

    .ant-progress-text {
      width: 96px;
      padding: var(--spacing-xxs);
      padding-right: var(--spacing-xs);
      border: 1px solid var(--disabled-text);
      margin-left: 0;
      background-color: var(--white);
      color: var(--table-grey);
      font-weight: bold;
      letter-spacing: 1.5px;
      text-align: right;
    }
  }
}

.open-cart-line--danger {
  .open-cart-details-mixin(@tertiary-color);

  .open-cart-line-details__deadline .ant-statistic-content {
    padding: 0 var(--spacing-xs);
    background-color: @tertiary-color;
    color: var(--white);
  }
}

.open-cart-line--warning {
  .open-cart-details-mixin(@orange);
}
