.search-filter {
  .ant-input-affix-wrapper {
    line-height: unset;
  }

  .ant-input {
    line-height: unset;
  }

  .ant-input::placeholder {
    color: var(--table-grey);
  }
}
