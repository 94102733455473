.quickOrder {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-left: @spacing-s;

  @media screen and (max-width: @screen-sm-max) {
    margin: @spacing-s 0 @spacing-l 0;
  }

  @media (min-width: @screen-md-min) {
    max-width: min(300px, 100%);
  }

  .ant-select {
    width: 100%;
  }

  // loading indicator
  .quickOrderLoading {
    position: absolute;
    z-index: @zindex-affix;
    // default ant padding is used currently for the <AutoComplete/> Component. Change values here to position <Spin/>, if Input gets altered
    top: @input-padding-horizontal-lg;
    right: calc(@btn-height-base + @input-padding-horizontal-lg);
  }
}
