.select-filter {
  position: relative;
  display: block;
  width: 100%;

  .ant-select {
    width: 100%;
  }
}

.select-filter--inline {
  .select-filter__dropdown {
    z-index: 9;
    top: 50px !important;
    padding-bottom: 0;
  }
}

.select-filter__dropdown {
  overscroll-behavior: contain;
}

.select-filter--filter-bar {
  .ant-select,
  .ant-select * {
    position: absolute !important;
    height: 0 !important;
    padding: 0 !important;
    border: 0 !important;
    margin: 0 !important;
  }

  .select-filter__dropdown {
    z-index: 100;
    top: 0 !important;
    padding-bottom: 0;
  }
}

.select-filter__dropdown-search-container {
  padding: var(--spacing-s);
}

.select-filter__dropdown-search {
  width: 100%;
  border: 1px solid var(--light-grey);
}

.select-filter__dropdown-select-all {
  width: 100%;
  padding: 5px 12px;
  border: 0;
  font-size: 16px;
  text-align: left;
}

.select-filter__container {
  position: relative;
}

.select-filter__select {
  width: 100%;
  height: 55px;

  .ant-select-selector {
    height: 50px;
  }
}

.select-filter__accept-changes-button {
  width: 100%;
  border: 0;
  background-color: var(--primary-color);
  color: var(--white);
  font-size: var(--text-sm);

  &[disabled] {
    border-color: var(--disabled-bg);
    cursor: auto;
  }
}

.select-filter__option {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.select-filter__option-check {
  margin-right: var(--spacing-s);
}

.select-filter__option-label {
  font-weight: normal;
}

.select-filter__option--selected {
  .select-filter__option-label {
    font-weight: bold;
  }
}

.select-filter__filter-bar-item {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  gap: var(--spacing-xs);
}

.select-filter__filter-bar-label {
  overflow: hidden;
  line-height: 25px;
  text-align: left;
  white-space: nowrap;
}

.select-filter__filter-bar-arrow {
  width: 1em;
  height: 1em;
}

.select-filter__filter-bar-arrow--up {
  transform: rotate(180deg);
}

.select-filter__checkbox-label {
  font-weight: bold;
}

.select-filter--sidebar {
  .ant-select-selection-placeholder {
    color: var(--table-grey);
  }

  .select-filter__select {
    height: 45px;

    .ant-select-selector {
      height: 42px;
    }

    .ant-select-selection-item {
      height: 25px;
      line-height: 25px;
    }
  }
}
