.selected-filters-button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: var(--text-default);
  font-weight: bold;

  svg {
    margin-right: var(--spacing-s);
  }

  .ant-badge {
    margin-left: var(--spacing-s);
  }
}

.selected-filters-button.ant-btn-default .ant-badge-count {
  background-color: var(--primary-color);
  color: var(--white);
}

.selected-filters-button.ant-btn-primary .ant-badge-count {
  background-color: var(--white);
  color: var(--primary-color);
}
