.productOrderNotes {
  width: auto;
  color: var(--primary-color);
  font-style: italic;
  grid-area: orderNote;

  @media only screen and (max-width: @screen-md-max) {
    grid-area: orderNote;
  }
}
