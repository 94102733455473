.orderLimitWarning {
  align-items: flex-start;
  margin-bottom: @spacing-l;
  color: @warning-text;
}

.orderLimitWarningMessage {
  display: flex;
  flex-wrap: wrap;
  color: @warning-text;
}
