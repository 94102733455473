.selected-filters {
  display: flex;
  overflow: hidden;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: var(--spacing-m);
}

.selected-filters > span:first-of-type {
  margin-right: var(--spacing-xs);
  font-weight: var(--font-weight-bold);
}
