@text-xxxs: 10px;
@text-xxs: 11px;
@text-xs: 12px;
@text-s: 14px;
@text-default: 16px; // used in body
@text-sm: 18px;
@text-m: 20px;
@text-ml: 24px;
@text-l: 30px;
@text-xl: 36px;
@text-2xl: 40px;
@text-3xl: 78px;
@text-mega: 120px;

// old line-height: 2.4
@text-default-line-height: @line-height-custom;
@default-input-line-height: @line-height-custom * 2;

@font-weight-regular: 400;
@font-weight-bold: 600;

:root {
  --text-xxxs: @text-xxxs;
  --text-xxs: @text-xxs;
  --text-xs: @text-xs;
  --text-s: @text-s;
  --text-default: @text-default;
  --text-sm: @text-sm;
  --text-m: @text-m;
  --text-ml: @text-ml;
  --text-l: @text-l;
  --text-xl: @text-xl;
  --text-2xl: @text-2xl;
  --text-3xl: @text-3xl;
  --text-mega: @text-mega;
  --font-weight-regular: @font-weight-regular;
  --font-weight-bold: @font-weight-bold;
}
