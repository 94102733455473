.quick-order-upload {
  .ant-form-item-label > label {
    color: var(--text-color) !important;
    font-weight: bold;
  }

  .ant-space-item a {
    color: var(--disabled-text);
  }

  &__info-button-bnn {
    a {
      color: var(--primary-color);
    }
  }

  &__info-button-pdf {
    button {
      height: unset;
      padding: 0;
    }
  }
}

.quick-order-upload-alternative-delivery-date-modal {
  .ant-modal-body {
    padding: 0 var(--spacing-ml) 0 var(--spacing-ml) !important;
  }

  .ant-modal-footer {
    padding: 0 var(--spacing-ml) var(--spacing-ml) var(--spacing-ml) !important;
  }

  .ant-picker {
    height: 0;
    border: unset;
  }

  .ant-picker-input {
    display: none;
  }

  &__date-picker {
    text-align: center;

    .ant-picker-panel-container {
      box-shadow: unset;
    }

    div:first-of-type,
    .ant-picker-dropdown {
      position: relative !important;
    }
  }
}
