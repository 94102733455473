a {
  color: @text-dark-color;

  &:hover,
  &:focus {
    color: @primary-color;
    text-decoration: underline;
  }
}

.button_container {
  margin-bottom: @spacing-m;
}

.button {
  &-link {
    padding: 0;
    border: 0;
    box-shadow: none;
    color: @primary-color;
    font-size: @text-default;
    font-weight: @font-weight-bold;
    letter-spacing: 0;

    @media screen and (min-width: @screen-xs-min) {
      font-size: @text-s;
    }

    &:after {
      content: none;
    }
  }

  &-primary {
    height: 45px;
    border: @border-width-base solid @primary-color;
    font-size: @text-default;

    &__svg-right {
      img {
        margin-left: @spacing-s;
      }
    }

    &:hover,
    &:focus {
      background-color: @white;
      color: @primary-color;

      svg path,
      svg circle {
        stroke: @primary-color;
      }

      .cart-svg {
        fill: @primary-color !important;
        stroke: @primary-color !important;
      }
    }

    &.ant-btn-default {
      color: @primary-color;
    }
  }
}

.icon-link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
