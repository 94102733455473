@import "cart/cartItemsList";
@import "cart/cartItem";
@import "cart/totalSum";
@import "cart/minimumOrderLimits";
@import "cart/deletedOrChangedCartItemEntry";
@import "cart/cartDeleteModal";

.orderDetail {
  .backLink {
    display: flex;
    align-items: center;
    margin-bottom: @spacing-ml;
  }

  @media screen and (max-width: @screen-sm-min) {
    top: @shop-category-nav-height-mobile;
  }
}

.cart,
.orderDetail {
  .productList {
    padding-top: @spacing-m;
  }

  .productListHeader {
    display: none;
    width: 100%;
    height: 35px;
    flex-direction: row;
    align-items: center;
    background-color: @primary-color;
    color: @white;
    text-align: right;

    @media screen and (min-width: @screen-md-min) {
      display: grid;
      row-gap: 0;

      .productListHeaderImg {
        grid-area: img;
      }

      .productListHeaderArticle {
        grid-area: title;
      }

      .productListHeaderStatus {
        grid-area: status;
      }

      .productListHeaderCount {
        grid-area: productCount;
      }

      .productListHeaderVpe {
        grid-area: packageInfo;
      }

      .productListHeaderSinglePrice {
        grid-area: singlePrice;
      }

      .productListHeaderTotal {
        padding-right: @spacing-xs;
        grid-area: sumPrice;
      }

      .productListHeaderButton {
        grid-area: menuBtn;
      }
    }
  }

  .cartTitle {
    display: inline-block;
    margin-right: @spacing-sm;
  }

  .customer-data {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: @spacing-sm;

    .date {
      font-weight: @font-weight-bold;
    }

    .customer-data-key {
      width: 40%;
      min-width: 140px;
      margin-right: @spacing-sm;
      font-weight: @font-weight-bold;
    }

    .customer-data-value {
      .eyeOutlined {
        width: @text-m;
        height: @text-m;
        margin-top: @spacing-xs / 3;
        color: @primary-color;
        font-weight: @font-weight-bold;
      }

      .iconText {
        display: none;
        margin-left: 5%;
        color: @primary-color;
      }

      .buttonInvoice,
      .buttonInvoiceName {
        display: none;
        width: 100%;
        flex-direction: inherit;
        padding: 0;
        border: none;
        background: none;
        color: inherit;
        cursor: pointer;
        font: inherit;
        outline: inherit;
      }

      &.invoiceName {
        width: 200px;
        flex-grow: 0;
        color: @text-color;
        text-decoration: underline;
      }

      .buttonInvoiceName {
        display: flex;
        width: 100%;
        justify-content: flex-start;
        text-align: inherit;
        word-break: break-all;
      }

      .textInvoiceName {
        display: none;
      }

      .iconInvoice {
        justify-content: center;
        margin-left: @spacing-xs;
      }

      @media screen and (min-width: @screen-sm) {
        .buttonInvoiceName {
          display: none;
          justify-content: flex-end;
        }

        .textInvoiceName {
          display: block;
        }

        &.invoiceData {
          justify-content: space-between;
        }

        .buttonInvoice {
          display: flex;
          margin-left: @spacing-xl;
        }

        .iconText {
          display: block;
        }

        &.invoiceName {
          width: @spacing-5xl;
          color: @text-color;
          text-decoration: none;
        }
      }
    }
  }

  .agb-not-checked {
    border-color: @form-error;
    color: @form-error;
  }

  .cart-footer-row {
    margin-bottom: var(--spacing-m);

    .ant-col > .ant-col {
      max-width: unset;
      padding-right: 0 !important;
      padding-left: 0 !important;
    }
  }

  @media screen and (min-width: @screen-md-min) {
    &.headerScrollPaddingAdditionMedium {
      padding-top: @header-height-mobile;
    }
  }
}

.cart {
  &__actions {
    &__items {
      // stylelint-disable-next-line
      width: min-content;

      .button_cart-button {
        display: inline-flex;
        color: var(--primary-color);
        font-size: var(--text-sm);
      }

      .hide-cart-button {
        display: none;
      }

      .button__print-cart {
        display: inline-flex;
        color: var(--primary-color);
        font-size: var(--text-sm);
      }
    }

    &__trigger {
      margin-bottom: var(--spacing-s);
      background-color: var(--light-grey);
      color: var(--primary-color);

      &:hover,
      &:focus {
        background-color: var(--disabled-bg);
      }
    }
  }
}

@media screen and (max-width: @screen-md) {
  .cart .divider--no-padding {
    width: calc(100% + 2 * @grid-padding-mobile) !important;
    margin-left: -@grid-padding-mobile;
  }
}
