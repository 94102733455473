.product-detail-modal {
  .ant-modal-title {
    display: none;
  }

  .ant-modal-content {
    max-width: 1500px;
    margin: 0 auto;

    @media screen and (max-width: @screen-sm-max) {
      .ant-modal-close {
        top: unset;
        padding-right: unset;
      }
    }
  }

  .ant-modal-body {
    padding-top: var(--spacing-3xl) !important;
    font-size: var(--text-default);

    @media screen and (max-width: @screen-sm-max) {
      padding-right: 0 !important;
      padding-left: 0 !important;
    }

    .ant-spin {
      display: flex;
      min-height: 250px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--spacing-s);
    }
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding-top: var(--spacing-xl) !important;
    padding-bottom: var(--spacing-xl) !important;

    button {
      width: 100%;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (min-width: @screen-md-min) {
        width: 50%;
        max-width: 300px;
      }
    }
  }

  .loader-component {
    background-color: unset;
  }

  .ant-collapse-expand-icon {
    z-index: 1;
  }
}
