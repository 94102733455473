@import "../mixins";

.impulse-main-teaser {
  a.button {
    padding-top: var(--spacing-xs) !important;
  }

  .button {
    .impulseButton();

    height: 70px;
    justify-content: flex-start;
    font-size: var(--text-sm);
    line-height: var(--text-sm);

    svg,
    span.anticon {
      min-width: var(--button-icon-small-size);
      max-width: var(--button-icon-small-size);
      min-height: var(--button-icon-small-size);
      max-height: var(--button-icon-small-size);
    }
  }

  .ant-card {
    display: flex;
    height: 100%;
    flex-direction: column;
    padding: var(--spacing-m) var(--spacing-l);
    border-radius: 10px;
    // stylelint-disable-next-line
    background-color: tint(@primary-color, 85%);

    .ant-card-cover img {
      height: 350px;
      object-fit: cover;
      object-position: center;
    }

    .ant-card-head {
      padding-right: 0;
      padding-left: 0;
      border-bottom: 3px solid var(--primary-color);
      margin-bottom: var(--spacing-m);
    }

    .ant-card-body {
      display: flex;
      height: 100%;
      flex-direction: column;
      padding-right: 0;
      padding-left: 0;
      // stylelint-disable-next-line
      background-color: tint(@primary-color, 85%);
    }

    .ant-card-head-title {
      padding-top: 0;
      padding-bottom: var(--spacing-s);
      font-size: var(--text-l);
      letter-spacing: 1px;
      line-height: var(--text-xl);
      text-align: center;
      text-transform: uppercase;
    }

    .ant-space {
      flex-direction: column;
      justify-content: center;
      gap: var(--spacing-s) !important;

      @media screen and (min-width: @screen-md-max) {
        flex-direction: row;
      }

      .ant-space-item {
        width: 100%;

        @media screen and (min-width: @screen-md-max) {
          max-width: 275px;
        }
      }
    }
  }

  &__description {
    flex-grow: 1;
    color: var(--table-grey);
    font-size: var(--text-m);
    font-weight: normal;
    letter-spacing: 1px;
    line-height: var(--text-ml);
    text-align: center;
  }
}

.impulse-main-teaser--small {
  .button {
    width: calc(var(--button-icon-small-size) + 2 * var(--spacing-m));
    justify-content: center !important;

    svg,
    span.anticon {
      margin-right: 0 !important;
    }

    span:last-of-type {
      display: none;
    }
  }

  .ant-card {
    .ant-card-head-title {
      font-size: var(--text-ml);
      line-height: var(--text-l);
    }

    .ant-divider {
      border: 2px solid var(--primary-color);
      margin-top: 0;
    }

    .ant-space {
      flex-direction: row;
      justify-content: flex-start;

      .ant-space-item {
        width: auto;
      }
    }
  }
}
