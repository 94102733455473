/* stylelint-disable plugin/no-unsupported-browser-features */
:root {
  --screen-xs: @screen-xs;
  --screen-xs-min: @screen-xs-min;
  --screen-xs-max: @screen-xs-max;
  --screen-sm: @screen-sm;
  --screen-sm-min: @screen-sm-min;
  --screen-sm-max: @screen-sm-max;
  --screen-md: @screen-md;
  --screen-md-min: @screen-md-min;
  --screen-md-max: @screen-md-max;
  --screen-lg: @screen-lg;
  --screen-lg-min: @screen-lg-min;
  --screen-lg-max: @screen-lg-max;
  --screen-xl: @screen-xl;
  --screen-xl-min: @screen-xl-min;
  --screen-xl-max: @screen-xl-max;
  --screen-xxl: @screen-xxl;
  --z-index-modal: @zindex-modal;
  --z-index-modal-mask: @zindex-modal-mask;
  --z-index-message: @zindex-message;
  --z-index-notification: @zindex-notification;
  --z-index-popover: @zindex-popover;
  --z-index-tooltip: @zindex-tooltip;
  --z-index-overall: @zIndex-overall;
}
/* stylelint-enable plugin/no-unsupported-browser-features */
