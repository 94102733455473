.brandPortraitModal {
  color: var(--primary-color);

  .brand-name {
    display: flex;
    padding-top: var(--spacing-m);
    margin-bottom: var(--spacing-s);
    font-size: larger;
  }

  .brand-text {
    display: flex;
    width: 100%;
    align-items: center;

    &.has-valid-picture {
      p {
        width: 70%;
      }

      img {
        width: 30%;
        height: 30%;
        padding-left: var(--spacing-s);
      }
    }

    @media only screen and (max-width: @screen-sm-max) {
      display: block;
      text-align: center;

      span {
        display: block;
        width: 100%;
        text-align: left;
      }

      img {
        width: 40%;
        height: 40%;
        padding-top: var(--spacing-s);
      }
    }
  }
}
