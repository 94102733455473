.stocktaking-page {
  .content-block__content {
    padding-top: 0;
  }

  .ant-tag {
    margin-right: 0;
  }

  .ant-tag-success {
    border-color: var(--secondary-color);
    // stylelint-disable-next-line function-no-unknown
    background-color: lighten(@secondary-color-lighter, 15);
    color: var(--secondary-color);
  }

  textarea {
    min-height: 100px;
  }

  .date-picker-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: var(--spacing-xs) var(--spacing-s);
    border: 2px solid var(--disabled-bg);
    background-color: var(--white);
  }

  .quick-order-wrapper {
    min-width: min(300px, 75%);

    .quickOrder,
    input {
      width: 100% !important;
      max-width: unset;
    }
  }

  .ant-popover-disabled-compatible-wrapper {
    padding: 0;
    border: 0;
    margin: 0;

    button {
      width: 100%;
    }
  }
}

.stocktaking-popconfirm {
  position: unset;
  top: unset;
  max-width: 250px;

  .ant-popover-inner-content {
    padding: var(--spacing-s) !important;
  }

  .ant-popover-arrow {
    top: -1px !important;
  }
}

.stocktaking-item-post-modal {
  label.ant-form-item-required:before {
    display: none !important;
  }

  .productImage {
    text-align: center;
  }

  .stocktaking-item-quantity-wrapper {
    display: flex;
    width: 100%;

    .ant-form-item {
      width: 100%;

      .quantity-input {
        width: 90px;

        @media only screen and (max-width: @screen-xs-max) {
          width: 100%;
        }
      }

      @media only screen and (max-width: @screen-xs-max) {
        width: 180px !important;
      }
    }

    button {
      display: none;

      @media only screen and (max-width: @screen-xs-max) {
        display: block;
        flex: auto;
        margin-top: 48px;
      }
    }
  }
}
