.quickSearch {
  position: relative;
  z-index: @zIndex-fixed;
  display: flex;
  overflow: hidden;
  justify-content: center;

  .autoCompleteWrapper {
    @media (min-width: @screen-md-max) {
      width: 250px;
    }
    @media (max-width: @screen-md-max) {
      width: 100%;
    }

    @media screen and (max-width: @screen-xs) {
      order: 2;
    }
  }

  &:focus-within {
    // search icon
    .iconSearch {
      opacity: 1;
    }
  }

  &.autocompleteSearch {
    width: 100%;
    height: @button-icon-default-size;
    border-radius: 5px;

    @media screen and (max-width: @screen-xs) {
      display: flex;
    }

    .autocompleteInput,
    .ant-select-selector {
      width: 100%;
    }

    .autocompleteInput {
      align-items: center;

      .searchResult {
        display: flex;
      }

      .searchSuggest {
        width: 100%;
      }
    }
  }

  .ant-input-affix-wrapper {
    border-color: @search-color;
    background-color: @search-color;

    &:hover,
    &:focus {
      border-color: @search-color;
    }
  }

  .ant-input {
    background-color: @search-color;
    color: @white;
  }

  .ant-input-clear-icon {
    color: @white;

    svg {
      @media (min-width: @screen-md-min) {
        width: @button-icon-input-size;
        height: @button-icon-input-size;
      }
    }

    &:hover,
    &:focus {
      color: unset;
    }
  }

  .iconSearch {
    stroke-width: 8;
  }

  .iconButtonInput {
    color: @white;
    opacity: 0.46;
    .transitionWithReducedMotion();

    @media (min-width: @screen-md-min) {
      width: @button-icon-input-size;
      height: @button-icon-input-size;
    }

    &:hover,
    &:focus {
      opacity: 1;
    }

    svg {
      width: inherit;
      height: inherit;
    }
  }

  .withoutOpacity {
    opacity: 1;
  }

  .buttonInput {
    width: @button-icon-default-size;
    background-color: @search-color;

    @media screen and (max-width: @screen-xs) {
      order: 1;
    }

    &:focus,
    &:hover {
      background-color: @search-color;
      opacity: 0.7;

      .iconButtonInput {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  }

  .scanButton {
    @media screen and (max-width: @screen-xs) {
      order: 3;
    }
  }
}
