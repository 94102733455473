.portal-dashboard-teaser {
  .webshop-teasers-widget {
    padding: 0;
    margin-top: 0 !important;

    .content-block__content {
      padding: 0;
    }
  }

  @media screen and (min-width: @screen-md-max) {
    .webshop-teasers-widget {
      .picture-tile {
        height: 215px !important;
        min-height: unset !important;

        .picture > img {
          min-height: 215px !important;
        }

        &:hover,
        :focus {
          img {
            transform: scale(1.1);
            .transitionWithReducedMotion;
          }
        }
      }
    }
  }
}
