@import "StandingOrderRequestItems/index";
@import "StandingOrderRequestModalForm/index";

.standing-order-request-modal-wrapper {
  .ant-modal {
    max-width: 1600px;
  }

  .standing-order-request-items {
    margin-top: var(--spacing-m);
  }

  .ant-modal-footer {
    margin-top: var(--spacing-m);
  }
}
