.prices-switch {
  .ant-switch {
    height: 26px;
    border: 3px solid var(--white);
    line-height: 26px;

    svg {
      display: flex;
      width: var(--icon-size-medium);
    }

    .cls-1 {
      fill: none;
    }

    .cls-2 {
      fill: var(--white);
    }

    .ant-switch-handle {
      height: 17px;
    }
  }

  .ant-switch.ant-switch-checked {
    background-color: var(--secondary-highlight-color);

    .cls-2 {
      fill: var(--white);
    }
  }
}
