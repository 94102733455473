.editableCaption {
  display: block;
  width: 90%;
  min-height: @caption-height-mobile;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: @spacing-sm;

  @media only screen and (min-width: @screen-md-min) {
    width: 100%;
  }

  .captionWrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    color: inherit;

    .caption {
      overflow: hidden;
      margin: 0;
      text-align: left;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .icon {
      width: var(--icon-size-default);
      height: var(--icon-size-default);
    }

    .editButton {
      display: flex;
      min-height: @caption-height-mobile;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 0;
      border: none;
      margin-left: @spacing-xs;
      background: none;
      color: @primary-color;

      .editIcon {
        width: var(--icon-size-medium);

        @media only screen and (min-width: @screen-md-min) {
          width: @button-icon-default-size;
        }
      }

      @media only screen and (min-width: @screen-md-min) {
        min-height: @caption-height-tablet;
      }

      @media only screen and (min-width: @screen-lg-min) {
        min-height: @caption-height-desktop;
      }
    }
  }

  .captionInput {
    display: flex;
    flex-direction: row;
  }

  @media only screen and (min-width: @screen-md-min) {
    min-height: @caption-height-tablet;
    margin-bottom: 0;
  }

  @media only screen and (min-width: @screen-lg-min) {
    min-height: @caption-height-desktop;
    margin-bottom: 0;
  }
}
