.top-navigation {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-m);

  .button-brand-portrait {
    width: auto;
    border: none;
    color: var(--primary-color);
    font-size: var(--text-default);
  }

  .button-brand-portrait:hover,
  .button-brand-portrait:focus {
    span {
      text-decoration: underline !important;
    }
  }

  .button-brand-portrait:after {
    animation: none !important;
    box-shadow: none !important;
  }
}
