.portal-dashboard-links {
  display: flex;
  flex-direction: row;
  margin-top: var(--spacing-m);

  .ant-space {
    justify-content: space-between;
  }

  .ant-space-item:has(.cart-teaser) {
    width: 100%;

    .cart-teaser {
      width: 100%;
    }
  }

  @media screen and (min-width: @screen-sm-max) {
    justify-content: center;

    .ant-space {
      justify-content: center;
    }

    .ant-space-item:has(.cart-teaser) {
      width: unset;

      .cart-teaser {
        width: unset;
      }
    }
  }
}
