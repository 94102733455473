.productListSpin {
  margin-top: 100px;
}

.productList {
  width: 100%;

  p {
    margin: 0;
  }

  .ant-result-info {
    margin-top: @spacing-4xl;

    // Styles for the Button in the Result component (antdesign)
    .buttonClearAllFilters {
      color: @primary-color;
      font-size: @text-ml;
    }
  }

  .ant-result-extra {
    margin: 0;
  }
}

.productHeadingTitle {
  display: none;
  height: 100%;
  align-items: flex-end;

  h1 {
    overflow: hidden;
    width: 100%;
    margin: 0;
    font-size: @text-l;
    letter-spacing: 2.4px;
    text-overflow: ellipsis;

    small {
      color: @text-color;
      font-size: @text-default;
      font-style: italic;
      font-weight: @font-weight-regular;
      letter-spacing: normal;
      text-transform: none;
    }
  }

  @media only screen and (min-width: @screen-md-min) {
    display: flex;
  }
}

// todo remove with button refactoring!
.buttonNavigationText {
  display: inline-block;
  margin-left: @spacing-s;
}

// Mobile Styling for Header Buttons
.weekplannerProductListWrapper {
  order: 3;
  padding-left: @spacing-xs;

  & > div {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-s);
  }

  .productHeadingButtons {
    width: 100%;
    order: 4;

    // todo refactor!
    .buttonNavigation {
      width: 100%;
      height: @button-icon-default-size;
      padding: 0;
      border-color: @primary-color;
      color: @primary-color;

      .iconWrapper {
        display: none;
      }

      .buttonIcon,
      .calendarIcon {
        width: var(--icon-size-medium);
        height: var(--icon-size-medium);
      }

      .calendarIcon {
        margin-right: 0;
        fill: @primary-color;
      }

      .buttonText {
        margin-left: @spacing-s;
        color: @primary-color;
      }

      .fillPrimaryColor {
        margin: 0;
        fill: @primary-color;
      }

      &:hover,
      &:focus {
        border: 0;
        background-color: @primary-color;

        .buttonText {
          color: @white;
          text-decoration: none;
        }

        .iconWrapper {
          border: 0;
        }
      }
    }
  }

  .buttonAddProductsToNewCart {
    width: 100%;
  }
}

/*  Tablet & Desktop Markup */
.desktopTabletDateSelectWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  order: 2;
  gap: var(--spacing-sm);

  .productHeadingButtons {
    display: flex;
    justify-content: flex-end;

    .buttonNavigation {
      width: auto;
      height: @height-base;
      padding: 0;
      border: none;
      box-shadow: 0 0 0 1.5px @primary-color;
      color: @primary-color;

      .iconTextButtonContent {
        padding: 0 @spacing-s;
      }

      .calendarTextWrapper {
        width: auto;
      }

      .productListIcon {
        margin: 0;
        fill: @primary-color;
      }

      .calendarIcon {
        margin-right: 0;
        fill: @primary-color;
      }

      .productListIcon,
      .calendarIcon {
        @media (min-width: @screen-md-min) {
          width: @icon-productlist-weekplanner-button;
          height: @icon-productlist-weekplanner-button;
        }
      }

      .buttonText {
        display: none;
        margin-top: @spacing-xxs;
        margin-left: @spacing-s;
        color: @primary-color;
      }

      .iconWrapper {
        width: 95px;
        height: 100%;
        background-color: @primary-color;
        color: @white;

        .calendarIcon {
          fill: @white;

          &.listIconProductListTablet {
            margin: 0;
            fill: @white;
          }

          &.fillPrimaryColor {
            fill: @primary-color;
          }
        }

        &.calendar {
          width: 100px;
        }

        .productListIcon {
          &.listIconProductListTablet {
            margin: 0;
            fill: @white;
          }
        }
      }

      &:hover,
      &:focus {
        border-color: @primary-color;
        background-color: @primary-color;

        .buttonText {
          color: @white;
          text-decoration: none;
        }

        .iconWrapper {
          border: 0;
        }
      }
    }
  }

  @media only screen and (min-width: @screen-lg-min) {
    .productHeadingButtons {
      .buttonNavigation {
        width: 100%;

        .buttonText {
          display: block;
        }

        .iconWrapper {
          &.iconWrapperProductList {
            width: 52px;
          }

          &.calendar {
            width: 65px;

            .calendarIcon {
              width: var(--icon-size-medium);
              height: var(--icon-size-medium);
            }
          }
        }

        .productListIcon,
        .calendarIcon {
          width: var(--icon-size-medium);
          height: var(--icon-size-medium);
        }
      }
    }
  }
}

// "Col" containing page title styling.
.webShopTitle {
  align-self: flex-end;
  order: 1;

  h1 {
    display: flex;
    line-height: var(--text-l);
  }
}

/* Styling including Media Queries for "filterPannelToggler" */
.filterPanelToggler {
  order: 4;

  .buttonContainer {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-top: @spacing-xs;
    margin-bottom: @spacing-xs;

    .sortIcon {
      width: 14px;
    }

    .filterButton {
      width: 100%;
      padding: @spacing-xs + @spacing-xxs 0 @spacing-xs;

      &[disabled] {
        .icon {
          margin-right: @spacing-s;

          .iconStroke {
            stroke: @disabled-text;
          }
        }
      }
    }
  }
}

@media (min-width: @screen-md-min) {
  .filterPanelToggler {
    .buttonContainer {
      .button {
        width: 100%;
      }
    }
  }

  @media (min-width: @screen-lg-min) {
    .filterPanelToggler {
      padding-left: @spacing-sm;
    }
  }
}

.ant-result-extra {
  margin: 0;
}

.clearAllFiltersWeekPlanner {
  color: @primary-color;
  font-size: @text-ml;

  .buttonText {
    color: @primary-color;
  }

  &:hover,
  &:focus {
    text-decoration: none;
  }
}
