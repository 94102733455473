@picture-tile-min-height: 250px;
@picture-tile-max-height: 350px;

.picture-tile {
  position: relative;
  overflow: hidden;
  min-height: @picture-tile-min-height;
  max-height: @picture-tile-max-height;
  border-radius: 10px;

  .picture {
    width: 100%;
  }

  .picture-tile__icon {
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    padding: var(--spacing-xs);
    background-color: var(--primary-color);
    border-radius: 50%;
    justify-items: center;

    svg {
      width: @icon-size-large;
      height: @icon-size-large;
      color: var(--white);
    }
  }

  .picture-tile__thumbnail img {
    width: 100%;
    min-height: calc(@picture-tile-min-height / 2 + @spacing-s);
    border-radius: 10px;
    object-fit: fill;
  }

  .picture-tile__overlay {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    min-height: calc(@picture-tile-min-height / 2);
    max-height: 50%;
    background-color: var(--primary-color);
    border-radius: 0 0 10px 10px;

    .picture-tile__overlay__content {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding: var(--spacing-m);

      .picture-tile__overlay__title {
        position: relative;
        color: var(--white);
        font-size: var(--text-m);
        font-weight: bold;
        text-align: center;

        @media screen and (max-width: @screen-sm-max) {
          font-size: var(--text-sm);
        }

        &::selection {
          background-color: var(--white);
          color: var(--primary-color);
        }
      }

      .picture-tile__overlay__link a {
        padding: var(--spacing-s) var(--spacing-m);

        @media screen and (max-width: @screen-sm-max) {
          padding: var(--spacing-xs) var(--spacing-sm);
        }
      }
    }
  }
}

.picture-tile--overlay-right {
  min-height: unset;
  border-radius: 10px;

  .picture-tile__icon {
    right: unset;
    left: 0;
  }

  .picture-tile__thumbnail img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: fill;
  }

  .picture-tile__overlay {
    right: 0;
    left: unset;
    width: 50%;
    height: 100%;
    min-height: 100%;
    border-radius: 0 10px 10px 0;

    .picture-tile__overlay__content {
      justify-content: center;
      gap: var(--spacing-m);
      word-wrap: anywhere;

      .picture-tile__overlay__link {
        // stylelint-disable-next-line
        height: min-content !important;
      }
    }
  }
}

.picture-tile--overlay-hidden {
  .picture-tile__overlay {
    display: none;
  }
}

.picture-tile__quick-link {
  .picture-tile__overlay__content {
    justify-content: space-between !important;

    .picture-tile__overlay__link {
      display: flex;
      max-width: 90%;
      height: 100%;
      align-items: flex-end;
      margin-bottom: 10px;
    }

    .quick-link__label {
      overflow: visible;
      // stylelint-disable-next-line
      hyphens: auto;
    }
  }
}
