.tutorial-video {
  .ant-card {
    border-bottom: 2px solid var(--table-borders);
    margin-bottom: var(--spacing-m);

    .ant-card-cover {
      overflow: hidden;
      border: 2px solid var(--table-borders);
      border-radius: 10px;

      video {
        height: 150px;
      }
    }

    .ant-card-body {
      padding: var(--spacing-m);
      background-color: var(--white);

      .ant-card-meta-title {
        font-weight: bold;
      }
    }
  }
}
