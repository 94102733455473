.deliveryDateContainer {
  position: relative;
  display: flex;
  width: 138px;
  height: 40px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: @spacing-xs @spacing-s;
  border: @white 2px solid;
  margin: 0;
  margin-left: @spacing-sm;
  background-color: @primary-color;
  border-radius: 5px;
  cursor: pointer;

  @media screen and (max-width: @screen-xs-max) {
    width: 94px;
    justify-content: center;
    padding: @spacing-xs;
    margin-left: @spacing-xs;
  }

  .deliveryDateContainer:active,
  :focus {
    @media screen and (max-width: @screen-xs-max) {
      background-color: @white;
    }
  }

  .datepicker {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding: @spacing-xxs @spacing-s;
    border: none;
    margin: 0;
    background-color: transparent;
    box-shadow: none;
    cursor: pointer;
    outline: none;
    text-align: center;

    @media screen and (max-width: @screen-xs-max) {
      padding: @spacing-xxs @spacing-xs;
    }

    .ant-picker-suffix {
      display: none;
    }

    .ant-picker-input {
      margin: 0;
      color: transparent;

      @media screen and (max-width: @screen-xs-max) {
        display: none;
      }

      & > input {
        padding: 0;
        margin: 0;
        color: @white;
        cursor: pointer;
        font-size: @text-default;
        font-weight: 600;
        letter-spacing: 0.32px;
        line-height: @text-default;
      }
    }

    &.disabled {
      .ant-picker-input {
        & > input {
          color: @disabled-text;
        }
      }
    }
  }

  .dateWrapper {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;

    @media screen and (max-width: @screen-xs-max) {
      display: none;
    }

    .deliveryDateParagraph {
      margin: 0;
      color: @white;
      font-size: @text-xs;
      letter-spacing: 0.24px;
      line-height: @text-xs;
    }
  }

  .iconDeliveryDate {
    width: var(--icon-size-default);
    height: var(--icon-size-default);
    color: @white;
    .transitionWithReducedMotion();

    @media screen and (min-width: @screen-sm-min) {
      display: flex;
      width: var(--icon-size-medium);
      height: var(--icon-size-medium);
    }

    @media screen and (min-width: @screen-md-min) {
      display: flex;
      width: @icon-productlist-weekplanner-button;
      height: auto;
    }

    svg {
      width: inherit;
      height: inherit;
    }
  }
}

.deliveryDateContainer-open {
  @media screen and (max-width: @screen-xs) {
    background-color: white;

    .iconDeliveryDate {
      color: @primary-color;
    }
  }
}

.ant-picker-cell {
  .ant-picker-cell-inner {
    &.hasCart {
      background-color: rgb(@primary-color 10%) !important;
      color: @disabled-color !important;
    }

    &.picker-cell--cart-only {
      background-color: rgb(@primary-color 10%) !important;
      color: var(--primary-color) !important;
    }

    &.picker-cell--order-only {
      background-color: rgb(@secondary-color-lighter 60%) !important;
      color: var(--primary-color) !important;
    }

    &.picker-cell--cart-and-order {
      // stylelint-disable-next-line
      background-image: linear-gradient(
        135deg,
        rgb(@secondary-color-lighter 60%) 50%,
        rgb(@primary-color 10%) 50%
      ) !important;
      color: var(--primary-color) !important;
    }
  }

  &.ant-picker-cell-in-view:not(.ant-picker-cell-selected) {
    .ant-picker-cell-inner.hasCart {
      color: @primary-color !important;
    }
  }
}

// Styles to make ant-design calendar to show only one month in mobile view.
@media only screen and (max-width: @screen-sm-min) {
  .ant-picker-panels > *:first-child button.ant-picker-header-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:first-child button.ant-picker-header-super-next-btn {
    visibility: visible !important;
  }

  .ant-picker-panels > *:last-child {
    display: none;
  }

  .ant-picker-panel-container,
  .ant-picker-footer {
    width: 280px !important;
  }

  .ant-picker-footer-extra > div {
    flex-wrap: wrap !important;
  }
}

.datePickerCalendar {
  .ant-picker-footer-extra {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin: 0;
  }

  .ant-picker-today-btn {
    display: none;
  }

  @media only screen and (max-width: @screen-md-min) {
    width: 100%;

    .ant-picker-panel-container {
      margin: auto;
      text-align: center;
    }
  }

  .ant-picker-week-panel {
    th:first-of-type {
      padding-right: var(--spacing-xs);
      border-right: 2px solid var(--disabled-bg);

      &:before {
        color: var(--disabled-text);
        content: "KW";
      }
    }

    .ant-picker-body {
      padding-top: 0;
      padding-bottom: 0;
    }

    .ant-picker-week-panel-row-selected td,
    .ant-picker-week-panel-row td {
      background-color: unset;
      color: unset;

      .ant-picker-cell-inner {
        color: unset;
      }

      &:first-of-type {
        padding-right: var(--spacing-xs);
        border-right: 2px solid var(--disabled-bg);
        color: var(--disabled-text);
        cursor: unset;
      }

      &:hover,
      &:focus {
        .ant-picker-cell-inner {
          background-color: rgb(@primary-color 10%) !important;
        }
      }

      &.ant-picker-cell-selected {
        .ant-picker-cell-inner {
          background-color: var(--primary-color) !important;
          color: var(--white) !important;

          &.picker-cell--cart-only,
          &.picker-cell--order-only {
            background-color: var(--primary-color) !important;
            background-image: none !important;
            color: var(--white) !important;
          }

          &.picker-cell--cart-and-order {
            // stylelint-disable-next-line
            background-image: linear-gradient(
              135deg,
              var(--secondary-color) 50%,
              var(--primary-color) 10% 50%
            ) !important;
            color: var(--white) !important;
          }
        }
      }

      &.ant-picker-cell-disabled {
        color: var(--disabled-text);
      }

      &.ant-picker-cell-today > .ant-picker-cell-inner:before {
        border: 2px solid var(--table-borders);
      }

      &:not(.ant-picker-cell-in-view) {
        color: var(--disabled-text);
      }
    }
  }
}
