.sub-header {
  z-index: 900;
  top: 119px;
  overflow: hidden;
  padding-top: var(--spacing-s);
  margin-top: 0 !important;
  background-color: var(--primary-color);
  color: var(--white);

  @media only screen and (min-width: @screen-lg-min) {
    top: 70px;
  }

  .sub-header__content {
    padding: var(--spacing-m) 0;
    border-top: 2px solid var(--white);
    overflow-x: auto;
  }
}
