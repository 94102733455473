.productSliderWrapper {
  margin-top: @spacing-2xl;
  margin-bottom: @spacing-l;

  // hide arrows due to missing setting for breakpoints in slider
  .splide__arrows {
    @media (max-width: @screen-sm-max) {
      display: none;
    }
  }

  .splide__arrow {
    width: @slider-arrow-size;
    height: @slider-arrow-size;
    background-color: @primary-color;
    border-radius: 0;

    .transitionWithReducedMotion();

    &:focus,
    &:hover {
      opacity: 1;
    }

    &[disabled] {
      display: none;
    }

    svg {
      width: @slider-arrow-size / 2;
      height: @slider-arrow-size / 2;
      fill: @white;
      user-select: none;
    }
  }
}

.productSliderItem {
  display: grid;
  overflow: hidden;
  max-width: 175px;
  height: 100%;
  grid-template-areas:
    "img          img          img          img"
    "title        title        title        title"
    "productInfo  productInfo  productInfo  productInfo"
    "productPrice productPrice productPrice productPrice";
  /* stylelint-disable plugin/no-unsupported-browser-features */
  grid-template-rows: repeat(3, minmax(10px, max-content)) minmax(10px, auto);
  row-gap: @spacing-s;

  .productTitle {
    margin-bottom: 0;
    overflow-wrap: anywhere;
    text-align: center;
  }

  // wrapper for product info
  .productInfo {
    display: flex;
    flex-direction: column;
    column-gap: 10px;
    grid-area: productInfo;
    text-align: center;
    /* stylelint-enable plugin/no-unsupported-browser-features */
    .packagingInfo {
      align-self: center;
      margin-top: var(--spacing-xs);
    }
  }

  .productPriceInfo {
    align-self: flex-end;
    margin-top: var(--spacing-xs);
    justify-self: center;
  }
}

.productSliderHeading {
  display: flex;
  overflow: hidden;
  align-items: baseline;
  justify-content: space-between;
  text-overflow: ellipsis;
}

.productSliderTitle {
  margin-right: @spacing-sm;
}

.productSliderQuantity {
  white-space: nowrap;
}

.productSliderImage.productImage {
  width: 100%;
  grid-area: img;
}
