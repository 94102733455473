// Dieser Less File wird nicht nur vom Login Component sondern auch vom Register Component verwendet
// bei Änderungen beide Components checken.
.login,
.register,
.reset-password-component {
  display: flex;
  min-width: 100vw;
  min-height: 100vh;
  flex-flow: column wrap;
  padding: @spacing-m;
  background-color: @primary-color;
  background-image: url("../../static/images/Login-background-cut.png");
  background-position: right bottom;
  background-position-x: 160%;
  background-repeat: no-repeat;
  background-size: 310px;

  h1,
  h2 {
    color: @white;
  }

  h2 {
    font-weight: @font-weight-regular;
  }

  .login-welcome {
    margin-bottom: @spacing-m;
  }

  //FORM
  form {
    input {
      color: @text-dark-color;
    }

    .ant-form-item {
      margin-bottom: @spacing-s;
      font-size: @text-sm;
      font-weight: @font-weight-regular;

      //Form Error Color
      .ant-form-item-explain {
        color: @form-error;
      }

      ::placeholder {
        /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: @text-dark-color;
        opacity: 1; /* Firefox */
      }
    }

    .formLinks {
      display: flex;
      height: calc(100% - @spacing-s);
      align-items: center;
      justify-content: flex-end;

      a {
        color: @white;
        font-size: @text-default;
        text-decoration: underline;
      }
    }

    margin-bottom: @spacing-2xl;
  }

  .login-footer-menu {
    display: flex;
    flex-grow: 1;
    align-items: flex-end;
    align-self: flex-start;
    justify-content: flex-end;

    ul {
      display: flex;
      flex-direction: column;
      margin-bottom: 0;
      list-style: none;
      padding-inline-start: 0;

      li {
        margin-bottom: @spacing-s;

        button {
          height: auto;
        }

        a,
        button {
          color: @light-rose;
        }
      }
    }
  }

  //HEADER
  .login-header {
    .header-desktop-divider {
      display: none;
    }

    .logo-row {
      align-items: center;
      justify-content: space-between;

      img {
        margin-bottom: @spacing-s;
      }
    }

    h3 {
      color: @white;
      font-size: @text-default;
      letter-spacing: 0.8px;
    }
  }

  .ant-alert-login {
    position: absolute;
    top: 5vh;
    right: 0;
    left: 0;
    width: 50vw;
    margin: 0 auto;
  }
}

//MEDIA QUERIES
@media (min-width: @screen-sm-min) and (max-width: @screen-sm-max) {
  .login,
  .register,
  .reset-password-component {
    display: flex;
    flex-flow: column wrap;
    background-position-x: 110%;
    background-size: 310px;

    .login-header {
      display: none;
    }
  }
}

@media (min-width: @screen-md-min) {
  .login,
  .register,
  .reset-password-component {
    padding: @spacing-2xl @spacing-2xl @spacing-2xl 18vw;
    background-position-x: 155%;
    background-size: 600px;

    .login-footer-menu {
      ul {
        flex-direction: row;
        margin-bottom: 0;
        padding-inline-start: unset;

        li {
          button {
            display: flex;
            height: auto;
          }

          a,
          button {
            margin-right: @spacing-m;
            color: @white;
          }
        }
      }
    }

    form {
      button {
        padding: 0 @spacing-l;
      }
    }

    .login-header-row {
      justify-content: flex-end;
    }

    .login-header {
      position: absolute;

      .logo-row {
        flex-direction: column-reverse;
        text-align: center;
      }

      img {
        margin: 0;
      }

      h3 {
        margin: 0;
        font-size: @text-sm;
      }

      .header-desktop-divider,
      .login-header-divider {
        display: block;
        margin: 0;
      }
    }

    h1 {
      margin-top: 130px;
    }

    .login-welcome-break {
      display: none;
    }

    .submit-button-div {
      display: flex;
      align-content: flex-end;

      .ant-form-item-control {
        display: flex;
        justify-content: flex-end;
        margin-bottom: -@spacing-xs;
      }
    }
  }
}

@media (min-width: @screen-md-min) and (max-width: @screen-md-max) {
  .login,
  .register,
  .reset-password-component {
    padding: @spacing-2xl;
    background-position-x: 155%;
    background-size: 600px;

    h1,
    h2 {
      font-size: @text-xl;
    }
  }
}

@media (min-width: @screen-lg-min) and (max-width: @screen-lg-max) {
  .login,
  .register,
  .reset-password-component {
    background-position-x: 115%;

    .login-header-breaks {
      display: block;
    }
  }
}

@media (min-width: @screen-xl-min) {
  .login,
  .register,
  .reset-password-component {
    padding: @spacing-2xl @spacing-2xl @spacing-2xl 22vw;
    background-position: bottom right;
    background-size: 700px;

    .login-header-breaks {
      display: block;
    }
  }
}
