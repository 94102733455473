.portal-dashboard-bioladen-teaser {
  margin-top: 0;

  .content-block__header {
    border-color: var(--impulse-blue);
    border-top: 0;
  }

  .content-block__title {
    color: var(--impulse-blue);
  }

  .content-block__content {
    padding-bottom: 0 !important;

    .picture-tile {
      min-height: unset !important;
      max-height: unset !important;
      aspect-ratio: 4/3;

      &__overlay {
        background-color: var(--impulse-blue);

        &__content {
          justify-content: center !important;
        }

        &__link {
          display: none;
        }

        &__title {
          font-size: var(--text-sm);
          letter-spacing: 1px;
          text-transform: uppercase;
        }
      }

      &__quick-link {
        height: 33% !important;
        min-height: unset !important;
      }
    }

    a:hover,
    a:focus {
      .picture-tile img {
        transform: scale(1.1);
        .transitionWithReducedMotion;
      }
    }
  }

  .ant-carousel {
    margin-bottom: var(--spacing-xl);

    ul.slick-dots {
      margin-bottom: -@spacing-xl;

      li,
      li.slick-active {
        width: 12px;
        height: 12px;
        background-color: var(--primary-color);
        border-radius: 50%;

        button {
          width: 12px;
          height: 12px;
          border-radius: 50%;
        }
      }

      li.slick-active button {
        background-color: var(--primary-color) !important;
      }
    }
  }
}
