.order-confirmation-page {
  display: flex;
  width: 100%;
  min-height: calc(100vh - @header-height-mobile - @footer-height-mobile);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  text-align: center;

  h1 {
    font-size: 20px;
    font-weight: bold;
    letter-spacing: initial;
    text-transform: none;
  }

  .buttons {
    width: 200px;
  }

  &.is-employee.is-foreign {
    justify-content: flex-start;
  }

  @media (min-width: @screen-md-min) {
    min-height: calc(
      100vh - @header-height-desktop - @footer-height-desktop -
        @shop-category-nav-height
    );
  }
}
