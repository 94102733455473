.tdProductGroup() {
  &[data-row-key^="productgroup"] {
    td.ant-table-cell {
      &,
      &.ant-table-selection-column {
        padding-top: @spacing-xs;
        padding-bottom: @spacing-xs;
        border-bottom: none;
        background-color: @light-grey;
      }
    }

    td.ant-table-selection-column {
      padding-top: @spacing-xs;
      padding-bottom: @spacing-xs;

      label {
        margin-bottom: 0;
      }
    }
  }
}
