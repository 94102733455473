.print-view-cart-note {
  display: flex;
  width: 100vw;
  padding: var(--spacing-s) var(--spacing-l);
  margin: var(--spacing-m) 0;
  background: #f5f3f6;
  color: var(--primary-color);
  font-size: var(--text-m);

  &__personal-note {
    padding-bottom: var(--spacing-4xl);
  }

  &__edit-icon {
    width: var(--icon-size-medium);
    min-width: var(--icon-size-medium);
    // stylelint-disable-next-line
    height: min-content;
    margin-right: var(--spacing-xs);

    .cls-1 {
      fill: var(--primary-color);
    }
  }

  span {
    display: inline-block;
  }
}
