.deleteFavouriteListButtonWrapper {
  display: flex;
  width: @button-icon-input-size;
  height: @button-icon-input-size;
  align-items: center;
  justify-content: center;

  .deleteFavouriteListButton {
    min-width: @button-icon-input-size;
    max-width: @button-icon-input-size;
    color: @primary-color;
  }
}
