/**
 * the actual scanner / the scandit component wrapper
 */

.scannerView {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;

  // the scandit component renders a div without any classname ...
  & > div {
    width: 100%;
    max-height: calc(100vh - @header-height-desktop - 124px);

    @media (max-width: @screen-sm-min) {
      min-height: calc(100vh - @header-height-mobile);

      // the scandit component renders a div without any classname ...
      & > div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
      }
    }
  }

  /*
   * the scanner component has an area object
   * but is percent based, which is not what we want mobile
   * but therefore there is a little mismatch on the left and right sides
   * of the scanner area which will be a bit larger than the border box
   */

  .scannerButtonScan,
  .scandit .scandit-viewfinder {
    @media (max-width: @screen-sm-max) {
      left: @grid-padding-mobile !important;
      width: calc(100% - 2 * @grid-padding-mobile) !important;
    }
  }

  .scannerButtonScan {
    position: absolute;
    bottom: calc(30% - @btn-height-base);
  }

  .scandit .scandit-viewfinder {
    border-width: @border-width-thick;
    border-color: @primary-color;
    border-radius: @border-radius-base;

    .transitionWithReducedMotion();

    &:not(.paused) {
      filter: drop-shadow(0 0 0.5rem @primary-color-lighter)
        drop-shadow(0 0 1rem @primary-color-lighter)
        drop-shadow(0 0 2.5rem @primary-color-lighter);
    }

    &.paused {
      opacity: 0.7;
    }
  }
}
