.productVolumePrices {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  grid-area: volumePrices;

  @media (max-width: @screen-sm-max) {
    display: none !important;
  }

  .productVolumePrice {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px @spacing-xs;
    border: 1px solid @primary-color;
    margin-bottom: @spacing-s;
    background: transparent;
    color: @text-color;
    font-size: @text-s;
    font-weight: @font-weight-bold;
    line-height: 22px;
    white-space: nowrap;

    &.volumePriceActive {
      border: 1px solid @primary-color;
      background-color: @primary-color;
      color: @white;
    }

    &:not(:first-child) {
      margin-left: @spacing-s;
    }
  }

  @media screen and (min-width: @screen-md-min) {
    justify-content: flex-end;
  }
}
@media only screen and (max-width: @screen-md-max) {
  .productList .productVolumePrices {
    display: none !important;
  }
}

// weekplanner view
.weekplannerVolumePrices {
  display: flex;
  flex-flow: row wrap;

  .productVolumePrice {
    color: @text-color;
    font-size: @text-xxs;
    white-space: nowrap;

    &:not(:last-child) {
      padding-right: @spacing-xs;
    }
  }
}
