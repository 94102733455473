.linked-thumbnail {
  display: flex;
  width: 200px;
  flex-direction: column;
  align-items: center;
  margin: var(--spacing-s);

  .linked-thumbnail__thumbnail {
    display: block;
    width: 100%;
    text-align: center;

    img {
      border: 1px solid var(--light-grey);
    }
  }

  .linked-thumbnail__button {
    width: 100%;
    margin-top: var(--spacing-s);
  }
}
