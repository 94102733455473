@webshopCategoryVideoHeight: 40px;
@webshopCategoryVideoMaxWidth: 200px;
@webshopCategoryVideoImageWidth: calc(
  @webshopCategoryVideoImageHeight / 9 * 16
);
@webshopCategoryVideoImageHeight: @webshopCategoryVideoHeight;

.webshop-category-video {
  display: flex;
  overflow: hidden;
  height: @webshopCategoryVideoHeight;
  background-color: var(--light-grey);
  box-shadow: 0 0 0 1.5px var(--disabled-bg);
  color: var(--primary-color);
  cursor: pointer;

  & > .ant-space-item:first-of-type {
    min-width: @webshopCategoryVideoImageWidth;
  }

  & > .ant-space-item {
    display: flex;
    overflow: hidden;
    height: 100%;
    align-items: center;
  }

  &__thumbnail {
    position: relative;
    width: @webshopCategoryVideoImageWidth;
    min-width: @webshopCategoryVideoImageWidth;
    height: @webshopCategoryVideoImageHeight;
    cursor: pointer;
    pointer-events: all;

    img {
      width: @webshopCategoryVideoImageWidth;
      min-width: @webshopCategoryVideoImageWidth;
      height: @webshopCategoryVideoImageHeight;
      background-color: var(--white);
    }

    span.anticon {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      color: var(--white);

      svg {
        font-size: var(--text-xl);
      }
    }

    span.anticon,
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    & > div:after {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: var(--primary-color);
      content: "";
      opacity: 0.5;
    }
  }

  &__details {
    overflow: hidden;
    padding-top: var(--spacing-xs);
    padding-right: var(--spacing-xs);
    padding-bottom: var(--spacing-xs);

    h5 {
      overflow: hidden;
      width: 100%;
      margin-bottom: 0;
      color: var(--primary-color);
      font-size: var(--text-default);
      font-weight: bold;
      line-height: var(--text-default);
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    div {
      overflow: hidden;
      width: 100%;
      color: var(--text-color);
      font-size: var(--text-xs);
      line-height: var(--text-xs);
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    @media screen and (min-width: @screen-md-min) {
      max-width: @webshopCategoryVideoMaxWidth;
    }
  }

  &:hover,
  &:focus {
    background-color: var(--primary-color);
    box-shadow: 0 0 0 1.5px var(--primary-color);
    .transitionWithReducedMotion;

    h5,
    div {
      color: var(--white);
      .transitionWithReducedMotion;
    }
  }
}
