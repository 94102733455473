.favouritelist__top-bar {
  @media only screen and (max-width: @screen-sm-max) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .print-button {
    margin-left: var(--spacing-xs);

    @media only screen and (min-width: @screen-md-min) {
      display: none;
    }
  }
}
