.packagingInfo {
  display: flex;
  align-content: flex-start;
  justify-content: flex-end;
  font-size: clamp(@text-xxs, 2vw, @text-s);
  grid-area: packageInfo;

  .pipe {
    width: 1px;
    margin: 0 3px;
    background-color: @text-color;
  }

  .packagingInfoInner {
    background-color: @lightest-grey;
    color: @text-dark-color;
    white-space: nowrap;

    .packagingInfo-amount {
      font-weight: @font-weight-bold;
    }
  }

  &.weekplannerPackagingInfo {
    display: inline-flex;
    align-content: unset;
    justify-content: unset;
    margin-left: @spacing-xs;
    grid-area: title;
  }
}

.cart-package-info {
  display: initial;

  .packaging-info-base-unit {
    font-size: @text-xxs;
  }
}
