.quick-order-actions {
  .ant-space {
    padding: var(--spacing-m);
    background-color: var(--light-grey);
  }

  .ant-space,
  .button,
  .space-item {
    width: 100%;
  }

  &__info {
    text-align: center;
  }
}
