.holiday-alert-deadline-modal {
  .ant-modal-body {
    margin-top: var(--spacing-sm);
    font-size: var(--text-default);
    text-align: center;
  }

  .ant-modal-footer {
    display: flex;
    justify-content: center;
    padding-top: var(--spacing-xl) !important;
    padding-bottom: var(--spacing-xl) !important;

    button {
      width: 100%;
      letter-spacing: 1px;
      text-transform: uppercase;

      @media screen and (min-width: @screen-md-min) {
        width: 50%;
        max-width: 500px;
      }
    }
  }
}
