@colorsList: primary @primary-color, primary-lighter @primary-color-lighter,
  secondary @secondary-color, tertiary @tertiary-color, text @text-color,
  light-rose @light-rose, light-grey @light-grey, lightest-grey @lightest-grey,
  table-grey @table-grey, table-borders @table-borders,
  search-color @search-color, form-error @form-error, white @white;

.makeColorClasses(@colorsList);
.makeColorClasses(@list) {
  .iter(length(@list));
  .iter(@i) when (@i > 0) {
    .iter(@i - 1);
    @pair: extract(@list, @i);
    @key: extract(@pair, 1);
    @value: extract(@pair, 2);
    .color-@{key} {
      color: @value;
    }
    .bgColor-@{key} {
      background-color: @value;
    }
    .borderColor-@{key} {
      border-color: @value;
    }
  }
}

.bg-light-grey {
  background-color: var(--light-grey);
}
