.business-unit-list {
  .business-unit-grid {
    display: grid;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    border: initial;
    background-color: @light-grey;
    border-radius: 10px;
    cursor: pointer;
    grid-template-areas: "radio customerId address";
    // stylelint-disable-next-line
    grid-template-columns: 50px 100px auto;
    text-align: left;
  }

  .business-unit-tooltip-key-key,
  .business-unit-tooltip-address-key {
    color: @disabled-text;
    letter-spacing: 1px;
  }

  .business-unit-tooltip-key-value,
  .business-unit-tooltip-address-address,
  .business-unit-tooltip-address-city {
    color: @disabled-text;
    font-size: @text-s;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .business-unit-grid-selected {
    background-color: #e6e7d6;
  }

  .ant-radio-input {
    opacity: inherit;
  }

  input[type="radio"] {
    accent-color: @secondary-color;
    transform: scale(1.5);
  }

  .ant-radio-group {
    font-size: inherit;
  }

  .ant-space {
    display: flex;
    gap: @spacing-s !important;
  }

  .ant-radio-wrapper {
    height: 100%;
    align-items: center;
    justify-content: center;
    margin-right: 0;
    grid-area: radio;
  }

  .business-unit-tooltip-key {
    grid-area: customerId;
  }

  .business-unit-tooltip-address {
    grid-area: address;
  }
}
