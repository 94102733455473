.quick-order-product-alternatives {
  &__buttons {
    width: 100%;
    flex-direction: column;
    gap: var(--spacing-sm) !important;

    .ant-space-item,
    button {
      width: 100%;
    }

    button.productAlternativesButton {
      border-color: var(--table-grey);
      background: var(--table-grey);
      color: white;

      &:hover,
      &:focus {
        color: var(--table-grey);
      }
    }

    button:not(.productAlternativesButton) {
      border-color: var(--secondary-color);
      background: var(--secondary-color);
      color: white;

      &:hover,
      &:focus {
        color: var(--secondary-color);
      }
    }
  }

  @media screen and (min-width: @screen-xl) {
    &__buttons {
      width: unset;
      flex-direction: row;

      .ant-space-item,
      button {
        width: unset;
      }
    }
  }
}
