.weekplanner-items-header-cart {
  display: flex;
  overflow: hidden;
  width: @weekplanner-item-cart-width;
  height: 100%;
  flex-direction: column;
  gap: var(--spacing-xs);

  .buttonActionForInput {
    background-color: var(--white);

    &:hover,
    &:focus {
      background-color: var(--white);
      box-shadow: unset;
    }
  }

  .iconCart {
    width: var(--icon-size-large);
    color: var(--primary-color);

    &--expired {
      color: var(--table-borders);
    }
  }
}
