@import "ImpulseDownload/index";
@import "ImpulseIntro/index";
@import "ImpulseMainTeaser/index";
@import "ImpulseTeaser/index";
@import "ImpulseTopMenu/index";

.impulse-page {
  margin-top: var(--spacing-xs) !important;

  .ant-divider {
    border-top-width: 3px !important;
  }
}

.ant-image-preview-mask {
  z-index: 10000;
  background-color: rgb(0 0 0 / 80%);
}

.ant-image-preview-wrap {
  z-index: 10001;
}
