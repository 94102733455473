.notification-modal {
  h2 {
    padding: var(--spacing-m) 0;
    margin-bottom: unset;
    text-align: center;
  }

  p {
    color: var(--primary-color);
    font-size: var(--text-m);
  }

  .ant-modal-content .ant-modal-body {
    padding: var(--spacing-xl) var(--spacing-xl) 0 var(--spacing-xl);
  }

  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
}
