.product-note-button {
  .icon {
    color: var(--table-borders);

    &:hover,
    &:active,
    &:focus {
      color: var(--primary-color);
    }
  }

  &--has-note {
    .icon {
      color: var(--primary-color);
    }
  }
}
