.weekplanner-item-actions {
  display: flex;
  width: @weekplanner-item-actions-width;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  .ant-space {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs) !important;

    .productInfoIcon {
      width: var(--icon-size-default);
      height: var(--icon-size-default);
    }
  }
}
