.cart-teaser {
  display: flex;
  overflow: hidden;
  width: 220px;
  min-width: 180px;
  flex-flow: column wrap;
  border-radius: 5px;

  & > a:first-of-type {
    display: block;
    border-radius: 5px;

    &:active,
    &:hover,
    &:visited,
    &:focus {
      text-decoration: none;
    }
  }

  &__header {
    display: flex;
    width: 100%;
    height: 22px;
    align-items: center;
    justify-content: center;
    font-size: var(--text-s);
  }

  &__content {
    display: flex;
    width: 100%;
    height: 48px;
    flex-direction: row;
    align-items: center;
    padding: var(--spacing-s);
    gap: var(--spacing-m);

    .icon {
      width: var(--icon-size-large);
      height: var(--icon-size-large);
    }

    .ant-progress {
      width: 100%;
      height: 20px;

      .ant-progress-outer {
        width: 100%;
        height: 100%;
      }

      .ant-progress-inner {
        padding: var(--spacing-xxs);
        background-color: var(--white);

        .ant-progress-bg {
          height: 12px !important;
          background-color: var(--table-grey);
        }
      }
    }

    > div:last-of-type {
      display: flex;
      flex-direction: column;
      flex-grow: 1;
    }
  }

  &--primary {
    a {
      // stylelint-disable-next-line
      background-color: tint(@tertiary-color, 94%);
    }

    a:active,
    a:hover,
    a:focus {
      // stylelint-disable-next-line
      background-color: tint(@tertiary-color, 88%);
      .transitionWithReducedMotion;
    }

    .cart-teaser__header {
      background-color: var(--tertiary-color);
      color: var(--white);
    }

    .cart-teaser__content {
      // stylelint-disable-next-line
      //background-color: tint(@tertiary-color, 94%);
      color: var(--tertiary-color);

      .icon {
        color: var(--tertiary-color);
      }

      .ant-progress-bg {
        background-color: var(--tertiary-color) !important;
      }
    }
  }

  &--secondary {
    a {
      // stylelint-disable-next-line
      background-color: tint(@orange, 94%);
    }

    a:active,
    a:hover,
    a:focus {
      // stylelint-disable-next-line
      background-color: tint(@orange, 88%);
      .transitionWithReducedMotion;
    }

    .cart-teaser__header {
      background-color: var(--orange);
      color: var(--white);
    }

    .cart-teaser__content {
      // stylelint-disable-next-line
      //background-color: tint(@tertiary-color, 94%);
      color: var(--orange);

      .icon {
        color: var(--orange);
      }

      .ant-progress-bg {
        background-color: var(--orange) !important;
      }
    }
  }
}
