.category-tree-block {
  // stylelint-disable-next-line selector-not-notation
  &:not(.block--checkbox):not(:last-child) {
    margin-bottom: var(--spacing-xl);
  }

  &-container {
    .category-tree-panel-header {
      // reduce max-width of title for badge
      max-width: calc(100% - var(--spacing-l) - var(--icon-arrow-width));
      // reserve some space between items
      padding-right: var(--spacing-xs);
      margin-bottom: 5%;
      overflow-wrap: break-word;
    }

    a:active {
      font-weight: bold;
    }

    .active {
      font-weight: bold;
      text-decoration: underline;
    }

    .ant-tree-node-content-wrapper-normal,
    .ant-tree-node-content-wrapper-open {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      /* If Browser supports this function,
      it will show the text in 2 lines and
      if it's too long it will be dotted (...) */
      @supports (display: -webkit-box) and (-webkit-box-orient: vertical) and
        (-webkit-line-clamp: 2) {
        /* autoprefixer: off */
        display: -webkit-box; /* stylelint-disable-line */
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        white-space: unset;
      }
    }

    .ant-tree-switcher-icon {
      color: var(--table-borders);
    }

    @media only screen and (max-width: var(--screen-sm-max)) {
      display: none;
    }

    width: 100%;
    height: auto;
    padding: var(--spacing-s) var(--spacing-sm) 0 var(--spacing-sm);
    margin-bottom: 5%;
    background-color: var(--light-grey);
    color: var(--text-color);
  }
}

.folder-tree {
  color: var(--primary-color);
  font-size: var(--text-default);

  @media (min-width: 768px) {
    font-size: var(--text-sm);
  }
}
