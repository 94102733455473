.show-cookie-trigger {
  position: relative;
  font-size: inherit;

  &:not([disabled]):hover,
  &:not([disabled]):focus {
    text-decoration: underline;
  }
}

.show-cookie-trigger--underline {
  text-decoration: underline;
}
