@portal-dashboard-news-item-height: 130px;
@portal-dashboard-news-item-padding: @spacing-s;
@portal-dashboard-news-item-preview-width: calc(
  @portal-dashboard-news-item-height - (2 * @portal-dashboard-news-item-padding)
);

.portal-dashboard-news-item-mixin(@color) {
  // stylelint-disable-next-line
  background-color: tint(@color, 85%);

  .portal-dashboard-news-item__content {
    > div:first-of-type .ant-tag {
      background-color: @color;
    }

    > div:last-of-type svg {
      color: @color;
    }
  }

  &:hover,
  &:focus {
    // stylelint-disable-next-line
    background-color: tint(@color, 80%);
  }
}

.portal-dashboard-news-item {
  display: flex;
  overflow: hidden;
  height: @portal-dashboard-news-item-height;
  flex-direction: row;
  padding: @portal-dashboard-news-item-padding;
  border: 0;
  background-color: var(--disabled-bg);
  border-radius: 5px;
  gap: var(--spacing-s);
  text-align: unset;
  text-wrap: unset;

  &__preview {
    overflow: hidden;
    width: @portal-dashboard-news-item-preview-width;
    min-width: @portal-dashboard-news-item-preview-width;
    max-width: @portal-dashboard-news-item-preview-width;
    height: 100%;
    border-radius: 5px;

    img {
      width: @portal-dashboard-news-item-preview-width;
      min-width: @portal-dashboard-news-item-preview-width;
      max-width: @portal-dashboard-news-item-preview-width;
    }
  }

  &__content {
    display: flex;
    overflow: hidden;
    width: 100%;
    flex-direction: column;
    gap: var(--spacing-xxs);
    white-space: nowrap;

    > div:first-of-type {
      svg {
        width: unset;
        height: 18px;
      }

      .ant-tag {
        border: 0;
        background-color: var(--table-grey);
        border-radius: 5px;
        color: var(--white);
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }

    > div:nth-child(2) {
      overflow: hidden;
      flex-shrink: 0;
      color: var(--text-color);
      font-size: var(--text-default);
      font-weight: bold;
      text-overflow: ellipsis;
    }

    > div:nth-child(3) {
      font-size: var(--text-xs);
      // stylelint-disable-next-line
      color: tint(@table-grey, 30%);
      line-height: var(--text-xxs);
    }

    > div:last-of-type {
      display: flex;
      overflow: hidden;
      max-height: calc(2 * var(--text-default) + var(--spacing-sm));
      flex-wrap: nowrap;
      justify-content: space-between;
      color: var(--table-grey);
      font-size: var(--text-default);
      gap: var(--spacing-xs);
      white-space: wrap;

      svg {
        min-width: 20px;
        height: 8px;
        place-self: center;
      }
    }
  }

  &:hover,
  &:focus {
    // stylelint-disable-next-line
    background-color: tint(@disabled-bg, 88%);

    img {
      transform: scale(1.1);
      .transitionWithReducedMotion;
    }

    svg.weiling-arrow {
      transform: rotate(-90deg) scale(1.5) !important;
      .transitionWithReducedMotion;
    }
  }
}

.portal-dashboard-news-item--default {
  .portal-dashboard-news-item-mixin(@table-grey);
}

.portal-dashboard-news-item--bioladen {
  .portal-dashboard-news-item-mixin(@impulse-blue);
}

.portal-dashboard-news-item--bioladen-special {
  .portal-dashboard-news-item-mixin(@impulse-blue);
}

.portal-dashboard-news-item--update {
  .portal-dashboard-news-item-mixin(@primary-color);
}

.portal-dashboard-news-item--recall {
  .portal-dashboard-news-item-mixin(@tertiary-color);
}

.portal-dashboard-news-item--new {
  .portal-dashboard-news-item-mixin(@secondary-color);
}

.portal-dashboard-news-item--info {
  .portal-dashboard-news-item-mixin(@orange);
}

.portal-dashboard-news-item--important {
  .portal-dashboard-news-item-mixin(@form-error);
}
