.standing-order-details {
  max-width: 550px;
  padding: var(--spacing-xs) var(--spacing-s);
  background-color: var(--light-grey);

  .ant-descriptions-header {
    margin-bottom: var(--spacing-xs);
  }

  .ant-descriptions-item {
    padding-bottom: var(--spacing-xs);
  }
}
