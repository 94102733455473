.podcast-list {
  margin-bottom: @spacing-m;

  .podcast-list__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .ant-divider {
    border-top-width: 2px;
    border-top-color: @primary-color;
    margin: 0;
    margin-top: @spacing-s;
    margin-bottom: @spacing-m;
  }

  .podcast-type-selector {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-top: var(--spacing-s);
    margin-bottom: var(--spacing-m);
    @media only screen and (min-width: @screen-lg-min) {
      width: 500px;
    }

    .podcast-single-selector {
      display: flex;
      width: 50%;
      align-items: center;
      justify-content: center;
      border: none;
      font-size: @text-s;
      font-weight: @font-weight-bold;
      letter-spacing: 0.36px;
    }

    .ant-radio-button-wrapper {
      background-color: @light-grey;
      color: @primary-color;
    }

    .ant-radio-button-wrapper-checked {
      background-color: @primary-color;
      color: @white;
    }
  }

  @media (min-width: @screen-xs-min) {
    .podcast-type-selector {
      .podcast-single-selector {
        font-size: @text-default;
      }
    }
  }

  @media (min-width: @screen-md-min) {
    .podcast-type-selector {
      .podcast-single-selector {
        font-size: @text-m;
      }
    }
  }
}

.ccm--consent-switch {
  display: flex;
  align-items: center;
  padding: var(--spacing-m) var(--spacing-m);
  background-color: var(--primary-color);
  color: var(--white);

  input {
    margin-right: var(--spacing-m);
  }

  a {
    color: inherit;
  }
}
