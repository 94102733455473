.quick-link-button {
  display: flex;
  overflow: hidden;
  width: 80px;
  min-width: 70px;
  flex-direction: column;
  align-items: center;
  gap: var(--spacing-xs);

  button.ant-btn {
    display: flex;
    width: 70px;
    height: 70px;
    align-items: center;
    justify-content: center;
    border-radius: 5px;

    svg {
      width: var(--button-icon-default-size);
      height: var(--button-icon-default-size);
    }

    .cls-1 {
      fill: var(--white);
    }
  }

  &__label {
    overflow: hidden;
    width: 100%;
    color: var(--disabled-text);
    font-size: var(--text-s);
    text-align: center;
    text-overflow: ellipsis;
    text-wrap: nowrap;
  }

  &--primary {
    button.ant-btn {
      border-color: var(--primary-color);
      background-color: var(--primary-color);
      color: var(--white);

      &:hover,
      &:focus {
        background-color: var(--white);
        color: var(--primary-color);
        .transitionWithReducedMotion;

        .cls-1 {
          fill: var(--primary-color);
        }
      }
    }
  }

  &--default {
    button.ant-btn {
      border-color: var(--table-grey);
      background-color: var(--table-grey);
      color: var(--white);
      fill: var(--white);

      &:hover,
      &:focus {
        background-color: var(--white);
        color: var(--table-grey);
        fill: var(--table-grey);
        .transitionWithReducedMotion;

        .cls-1 {
          fill: var(--table-grey);
        }
      }

      & svg#barcode {
        fill: var(--table-grey);
      }
    }
  }
}
