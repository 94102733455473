.icon {
  color: var(--svg-icon-color, inherit);

  --primary-tone: var(--svg-primary-tone, --svg-icon-color);

  &.iconTwoTone {
    --primary-tone: var(--svg-primary-tone, @primary-color);
    --secondary-tone: var(--svg-secondary-tone, @white);

    &.iconTwoTone--inverted {
      --primary-tone: var(--svg-secondary-tone, @white);
      --secondary-tone: var(--svg-primary-tone, @primary-color);
    }
  }

  // important styles to make sure they will be applied
  &.iconIsDisabled {
    color: @disabled-text !important;

    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    cursor: not-allowed !important;
    pointer-events: none !important;
  }
}

.iconCart,
.userIcon,
.iconScan,
.iconSearch,
.contactPersonIcon {
  color: var(--white);
}
