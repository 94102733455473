.quick-order-product-tile {
  display: flex;

  .productImage {
    max-width: 80px;
    max-height: 80px;
    margin-right: var(--spacing-s);
  }

  & > div:first-of-type {
    flex-grow: 1;
  }
}
