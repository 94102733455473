.price-tag-list-header {
  background-color: var(--white);

  header {
    display: flex;
    justify-content: space-between;

    @media (max-width: @screen-xs-max) {
      flex-direction: column;
    }

    h1 {
      display: inline-block;
      margin-right: var(--spacing-sm);
    }

    .button__clear-price-tag-list {
      display: inline-flex;
      margin-right: var(--spacing-sm);
      margin-bottom: var(--spacing-s);
    }
  }
}

.priceTagListItem {
  td:last-child {
    padding-right: 0 !important;
  }

  @media (max-width: @screen-xs-max) {
    .productImage {
      max-width: @product-image-width-sm;
    }
  }

  .productPrice {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @media only screen and (max-width: @screen-md-max) {
      white-space: nowrap;
    }

    .priceBaseUnit {
      font-size: @text-s;
      font-weight: @font-weight-regular;
    }

    > div {
      // stylelint-disable-next-line
      width: max-content;
    }
  }

  .itemInfo--small {
    .single-price,
    .vpe {
      display: flex;
      max-width: 125px;
      flex-direction: row;
      align-items: flex-end;
      align-self: flex-start;
      justify-content: space-between;

      @media screen and (max-width: @screen-xs-max) {
        font-size: var(--text-s) !important;
      }
    }

    .single-price {
      font-weight: bold;
    }

    .vpe {
      color: var(--disabled-text);
      font-size: var(--text-default);
    }

    .quantity {
      min-width: 75px;
      max-width: 120px;
      margin-left: var(--spacing-xs);
      justify-self: flex-end;

      .ant-input-number,
      .ant-input-number-focused {
        box-shadow: none;
      }

      .quantity__title {
        width: 100%;
        padding: 0 var(--spacing-xs);
        background-color: var(--primary-color);
        color: var(--white);
        font-size: var(--text-s);
        font-weight: bold;
        text-align: center;
      }

      .quantity__content {
        display: flex;

        .productCountInner {
          border-right: 0;
        }

        .quantity__content__append {
          display: flex;
          padding-right: var(--spacing-xs);
          border-top: 2px solid var(--primary-color);
          border-right: 2px solid var(--primary-color);
          border-bottom: 2px solid var(--primary-color);

          svg {
            width: @icon-size-medium;
            height: @icon-size-medium;
            align-self: center;
            color: var(--primary-color);

            @media screen and (max-width: @screen-xs-max) {
              width: @icon-size-small;
              height: @icon-size-small;
            }
          }
        }
      }
    }
  }
}

.productCount.priceTagListPriceInput {
  width: 100%;
}

.priceTagListTagContent {
  @media (max-width: @screen-xs-max) {
    overflow: auto;
    max-height: 33vh;
  }
}

.price-tag-modal,
.blank-price-tag-modal {
  .ant-modal-title {
    text-transform: uppercase;
  }

  .linked-thumbnails {
    display: flex;
    flex-wrap: wrap;
    margin: var(--spacing-m) auto;

    .linked-thumbnail {
      flex-grow: 1;
      margin-bottom: var(--spacing-m);
    }

    .linked-thumbnail__thumbnail {
      flex-grow: 1;
    }
  }
}
