.selectSortContainer {
  display: flex;
  height: inherit;
  padding: 0;
  margin: 0;

  @media only screen and (max-width: @screen-sm-max) {
    width: inherit;
    margin-left: @spacing-xs;
  }

  .sortByContainer {
    display: flex;
    align-items: center;
  }
}

.sortMenu {
  /* 170px because the dropdown menu width would decrease once a item has been selected.
  ** This behavior results from the width of the <Dropdown /> children, which decreases once a sort item has been selected.
  ** To ensure that the width of the dropdown menu does not decrease, the value is set preemptively
  */
  min-width: 170px;
  padding: 0;
}

.selectedSortMenuItem {
  background-color: @hover-background-color;
}

.arrowDownWrapper {
  display: flex;
  align-items: center;
  padding: 0;
  color: @text-color;
  text-decoration: none;

  :hover,
  :focus {
    color: @primary-color;
    text-decoration: underline;
  }
}

.arrowDown {
  width: @icon-arrow-down-width;
  padding: 0;
  margin: 0;
  margin-right: -3px;
  margin-left: calc(@spacing-s - 1px);
}

.sortByDropdown {
  height: 100%;
  margin: 0;
}

.selectedSortWrapper {
  display: flex;
  height: 100%;
  align-items: center;
}

.selectSortCloseButton {
  display: flex;
  height: inherit;
  align-items: center;
  padding: 0;
  border: none;
  margin: 0;
  background-color: @white;
  cursor: pointer;
  text-decoration: none;
}

.closeIcon {
  width: var(--icon-size-extra-small);
  padding: 0;
  margin: 0;
  margin-bottom: var(--spacing-xxs);
  margin-left: var(--spacing-xs);

  // 6px because otherwise the icon stroke width is too less
  line {
    stroke-width: 6px;
  }

  &--large {
    width: @button-icon-input-size;
    margin-bottom: 0;
    margin-left: 0;

    // 3px because otherwise the icon stroke width is too less
    line {
      stroke-width: 3px;
    }
  }
}
