@import "StandingOrderRequestItem/index";

.standing-order-request-items {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-sm);

  .standing-order-request-item:not(:last-of-type) {
    padding-bottom: var(--spacing-sm);
    border-bottom: 1px solid var(--light-grey);
  }
}
