.stocktaking-product-search {
  // TODO
}

.stocktaking-product-search__search {
  .productImageSuggest {
    min-width: 50px !important;
    max-width: 50px !important;
    margin-right: var(--spacing-xs);
  }

  .suggestItemProductInfo {
    line-height: var(--text-m);
  }
}
