.support-tutorials {
  .container-layout {
    padding-right: 0;
    padding-left: 0;
  }

  .ant-collapse-item {
    border-bottom: 2px solid var(--primary-color);
  }

  .ant-collapse-header {
    display: flex;
    flex-flow: row-reverse wrap !important;
    padding-top: var(--spacing-sm) !important;
    padding-right: var(--spacing-s) !important;
    padding-bottom: var(--spacing-sm) !important;

    .ant-collapse-header-text {
      order: 3;
      font-size: var(--text-m);
      font-weight: bold;
    }

    .ant-collapse-extra {
      flex-basis: 100%;
      order: 1;
      color: var(--table-grey);
      font-size: var(--text-default);
    }

    .ant-collapse-expand-icon {
      display: flex;
      flex-direction: column;
      align-self: center;
      order: 2;

      .ant-collapse-arrow {
        position: unset;
        color: var(--table-grey) !important;
      }
    }
  }

  .ant-collapse-content-box {
    font-size: var(--text-default);
  }
}
