.ad-product-listing-top {
  ul.slick-dots {
    margin-bottom: 7px;

    li,
    li.slick-active {
      width: 10px;
      height: 10px;
      background-color: var(--primary-color);
      border-radius: 50%;

      button {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }

    li.slick-active button {
      background-color: var(--primary-color) !important;
    }
  }

  &__ad {
    padding: var(--spacing-s);
    margin-bottom: var(--spacing-sm);
    background-color: var(--light-grey);

    .picture-tile {
      max-height: unset;
      border-radius: 0;
    }

    .picture-tile__thumbnail,
    .picture-tile__thumbnail picture,
    .picture-tile__thumbnail img {
      height: 100%;
      max-height: 400px;
      border-radius: 0;
    }

    .picture-tile__thumbnail img {
      object-fit: contain;
      object-position: center;
    }

    .picture-tile__overlay {
      height: 100%;
      max-height: 100%;
      background-color: unset;

      &__link .quick-link--primary--outlined:hover,
      &__link .quick-link--primary--outlined:focus {
        border-color: var(--primary-color);
      }
    }
  }
}
