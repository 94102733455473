.support-client {
  .container-layout {
    padding-right: 0;
    padding-left: 0;
  }

  .content-block__icon {
    display: flex;
    justify-content: center;
    padding-bottom: var(--spacing-m);

    img {
      max-height: calc(3 * var(--icon-size-large));
    }
  }

  .content-block__header {
    border-color: var(--table-grey);
  }

  .content-block__title {
    color: var(--table-grey);
  }

  .content-block__content {
    p:last-of-type {
      display: flex;
      max-width: 400px;
      flex-direction: column;
      margin: 0 auto;
      gap: var(--spacing-s);
    }

    p:last-of-type .quick-link {
      display: flex;
      justify-content: center;
      padding: var(--spacing-s) var(--spacing-m);
      border-color: var(--white);
      background-color: var(--table-grey);
      color: var(--white);
      text-decoration: none;

      &:hover,
      :focus {
        border-color: var(--table-grey);
        background-color: transparent;
        color: var(--table-grey);
      }
    }
  }
}
