.content-block {
  .content-block__header {
    position: relative;
    padding: var(--spacing-sm) 0;
    border-top: 2px solid var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
    text-align: center;

    @media screen and (max-width: @screen-md) {
      display: flex;
      align-items: flex-end;
      text-align: unset;
    }

    .content-block__title {
      margin-bottom: 0;
      font-weight: bold;
      text-transform: uppercase;

      @media screen and (max-width: @screen-md) {
        flex-grow: 1;
      }
    }

    .content-block__title-noLink {
      text-align: center;
    }

    .content-block__title-link {
      position: absolute;
      right: 0;
      bottom: var(--spacing-sm);
      padding-bottom: var(--spacing-xxs);
      color: var(--disabled-text);
      font-weight: bold;

      @media screen and (max-width: @screen-md) {
        position: unset;
      }

      a {
        display: flex;
        align-items: center;
        color: var(--disabled-text);
        letter-spacing: 1px;

        .anticon {
          margin-left: var(--spacing-xs);
        }
      }

      &:hover,
      &:focus {
        a {
          color: var(--primary-color);
        }
      }
    }
  }

  .content-block__content {
    padding: var(--spacing-sm) 0;
  }
}
