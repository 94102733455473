.newsArticle {
  display: flex;
  overflow: hidden;
  height: 100%;
  flex-direction: column;
  padding-bottom: @spacing-xs;
  border-bottom: 1px solid @primary-color;
  margin-bottom: @spacing-sm;

  .newsArticleImage {
    width: 100%;
    border-bottom: 1px solid @primary-color;
    margin-bottom: @spacing-sm;

    @media (max-width: @screen-sm-max) {
      display: none;
    }
  }

  .newsArticleMeta {
    display: flex;
    justify-content: flex-end;

    .newsArticleShareButton {
      display: flex;
      text-decoration: none;

      .shareText {
        margin-left: @spacing-xs;
        color: @primary-color;
      }

      .icon {
        width: var(--icon-size-medium);
        height: var(--icon-size-medium);
        color: var(--primary-color);
      }
    }
  }

  .newsArticleDate {
    display: flex;
    align-self: center;
    margin-bottom: @spacing-m;
    margin-left: auto;
    text-align: right;
  }

  p {
    margin: 0;
  }
}
