.orderNoteWrapper {
  margin-bottom: @spacing-l;
}

.orderNotes {
  border: none;
  background-color: transparent;

  .orderNotesHeading {
    display: flex;
    align-items: baseline;
  }

  .orderNotesHeadline {
    margin: 0;
  }

  .orderNotesCount {
    margin-left: @spacing-sm;
    color: @text-color;
    font-size: @text-default;
    font-style: italic;
  }

  .@{ant-prefix}-collapse-item,
  .@{ant-prefix}-collapse-content {
    border: none;
  }

  .@{ant-prefix}-collapse-header {
    background-color: transparent;
  }

  .@{ant-prefix}-collapse-header,
  .@{ant-prefix}-collapse-content-box {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  &.@{ant-prefix}-collapse-icon-position-right
    > .@{ant-prefix}-collapse-item
    > .@{ant-prefix}-collapse-header
    .@{ant-prefix}-collapse-arrow {
    right: 0;
    color: @primary-color;
  }
}

.orderNote {
  & + & {
    margin-top: @spacing-m;

    /*
     * keep padding to prevent "jumping content",
     * but add negative margin to keep spacings even when expanded
     */

    &:last-child {
      margin-bottom: -@collapse-content-padding;
    }
  }

  .orderNoteTitle {
    margin: 0;
    color: @text-color;
    font-size: @text-sm;
    font-weight: @font-weight-bold;
  }

  .orderNoteContent {
    font-size: @text-default;
  }
}
