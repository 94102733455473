.user-navigation {
  min-width: 370px;
  background-color: var(--white);

  .user-navigation-widescreen {
    background-color: var(--primary-color-lightest);
    font-size: var(--text-m);

    * {
      transition: none !important;
    }

    li {
      margin-top: 0;
      margin-bottom: 0;
      font-size: var(--text-m);

      div {
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    .ant-menu-item:not(:last-child) {
      margin-top: 0;
      margin-bottom: 0;
    }

    ul {
      background-color: var(--white);
    }

    .ant-menu-item {
      padding: 30px 0;
      border-bottom: 1px solid var(--primary-color);
    }

    .ant-menu-submenu {
      div {
        padding: 30px 0;
        border-bottom: 1px solid var(--primary-color);
      }

      li {
        border-bottom: 1px solid var(--primary-color);
      }
    }

    a,
    span {
      color: var(--primary-color);
      text-decoration: none;
    }

    svg {
      color: var(--primary-color);
      fill: var(--primary-color);
    }

    .link-icon {
      display: inline-block;
      width: @icon-size-large;
      height: @icon-size-large;
      margin-right: var(--spacing-ml);

      &__contact-icon {
        .phoneOutline {
          fill: var(--primary-color);
        }

        .iconCustomerBubble {
          fill: var(--primary-color);
        }

        .iconCustomerDots {
          color: var(--white);
          fill: var(--white);
        }
      }
    }

    // contactButton &  logoutButton
    .ant-menu-item-only-child {
      padding-right: 0;
      padding-left: 0 !important;
      margin-right: 0;
      margin-left: 0;

      &:last-child {
        border: none;
      }
    }

    .logoutButton {
      display: flex;
      width: 100%;
      align-items: center;
      padding-right: 0;
      padding-left: 0;
      border: none;
      background-color: var(--primary-color-lightest);
      font-size: var(--text-m);
      text-decoration: none;

      .button-icon {
        margin-right: calc(var(--spacing-ml) + var(--spacing-xxs));
        margin-left: 24px;
      }
    }

    //SubMenu-Hover
    .ant-menu-submenu-active {
      background-color: var(--primary-color);

      i:before,
      i:after {
        background-color: var(--white);
      }

      svg {
        color: var(--white);
        fill: var(--primary-color);
      }

      a,
      span,
      div {
        background-color: var(--primary-color);
        color: var(--white);
      }

      //non-Selected-SubMenu-Items
      li {
        background-color: var(--white);

        a,
        span {
          background-color: var(--white);
          color: var(--primary-color);
        }

        svg {
          color: var(--primary-color);
          fill: var(--primary-color);
        }
      }
    }

    //Item hover
    .ant-menu-item-active {
      background-color: var(--primary-color);

      .link-icon {
        &__contact-icon {
          .phoneOutline {
            fill: white;
          }

          .phoneBubbleOutline {
            fill: var(--primary-color);
          }

          .iconCustomerBubble {
            fill: var(--white);
          }

          .iconCustomerDots {
            color: var(--primary-color);
            fill: var(--primary-color);
          }
        }
      }

      svg {
        color: var(--white);
        fill: var(--primary-color);
      }

      a,
      span,
      button {
        background-color: var(--primary-color);
        color: var(--white);
      }
    }
    //Item selected
    .ant-menu-item-selected {
      background-color: var(--primary-color) !important;

      .link-icon {
        &__contact-icon {
          .phoneOutline {
            fill: white;
          }

          .phoneBubbleOutline {
            fill: var(--primary-color);
          }

          .iconCustomerBubble {
            fill: var(--white);
          }

          .iconCustomerDots {
            color: var(--primary-color);
            fill: var(--primary-color);
          }
        }
      }

      svg {
        color: var(--white) !important;
        fill: var(--primary-color) !important;
      }

      a,
      span,
      button {
        background-color: var(--primary-color) !important;
        color: var(--white) !important;
      }
    }

    .user-navigation__list {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 0;
      margin: 0;
      list-style: none;

      .user-navigation__list__element {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: flex-start;
        padding: var(--spacing-sm) var(--spacing-s);
        font-size: var(--text-m);

        .user-navigation__list__element__button {
          display: flex;
          align-items: flex-end;
          justify-content: flex-end;
          padding: 0;
          border: none !important;
          background: none !important;
          font-size: var(--text-m);

          .icon {
            margin-right: calc(var(--spacing-ml) + var(--spacing-xxs));
            margin-left: var(--spacing-xxs);
            color: var(--primary-color);
          }

          .bookmarkFill {
            fill: var(--primary-color);
          }
        }

        .user-navigation__list__element__link {
          width: 100%;
          color: var(--primary-color);

          .icon {
            display: inline-block;
            width: @icon-size-large;
            height: @icon-size-large;
            margin-right: var(--spacing-ml);

            --svg-icon-color: var(--primary-color);
          }
        }

        .user-navigation__list__element__link,
        .user-navigation__list__element__button {
          display: flex;
          align-items: center;
          margin-left: var(--spacing-ml);
          color: var(--primary-color);
          cursor: pointer;
        }

        &:not(:last-child) {
          border-bottom: 1px solid var(--primary-color);
        }

        &:hover,
        &:focus,
        &--active {
          background-color: var(--primary-color);
          color: var(--white);
          //.transitionWithReducedMotion;
          .user-navigation__list__element__link {
            text-decoration: none;
          }

          .user-navigation__list__element__link,
          .user-navigation__list__element__button,
          svg {
            color: var(--white);
            fill: var(--white);

            .bookmarkFill {
              fill: white;
            }
          }

          button svg {
            color: var(--white) !important;
            fill: var(--white) !important;
          }
        }
      }
    }

    &:before {
      position: absolute;
      top: -@spacing-xs;
      right: var(--spacing-xs);
      width: 0;
      height: 0;
      border-width: 0 10px 5px;
      border-style: solid;
      border-color: transparent transparent @white transparent;
      content: "";
      transform: rotate(360deg);
    }
  }
}

.user-navigation-mobile {
  width: 100%;
  min-width: 100vw;
  padding: 0 0 var(--spacing-m) 0;
  background-color: var(--white);

  .user-navigation__list__element {
    padding: 8px 0 8px 38px;
  }

  &:before {
    display: none;
  }
}

.user-navigation-popover {
  padding-top: var(--spacing-s);

  .ant-popover-inner-content {
    padding: 0;
  }

  .ant-popover-arrow {
    display: none;
  }
}
