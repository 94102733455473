@largeTeaserHeight: 480px;
@numSmallTeaser: 2;
@smallTeaserSpacing: @spacing-m;
@smallTeaserHeight: calc(
  (@largeTeaserHeight - @smallTeaserSpacing) / @numSmallTeaser
);

.webshop-top-teaser-widget {
  margin-bottom: var(--spacing-xs) !important;

  ul.slick-dots {
    margin-bottom: -@spacing-xl;

    li,
    li.slick-active {
      width: 12px;
      height: 12px;
      background-color: var(--primary-color);
      border-radius: 50%;

      button {
        width: 12px;
        height: 12px;
        border-radius: 50%;
      }
    }

    li.slick-active button {
      background-color: var(--primary-color) !important;
    }
  }

  .picture-tile__thumbnail,
  .picture-tile__thumbnail picture,
  .picture-tile__thumbnail img {
    height: 100%;
  }

  .picture-tile__thumbnail img {
    object-fit: cover;
    object-position: center;
  }

  .picture-tile__overlay {
    height: 100%;
    max-height: 100%;
    background-color: unset !important;

    &__link .quick-link--primary--outlined:hover,
    &__link .quick-link--primary--outlined:focus {
      border-color: var(--primary-color);
    }
  }

  @media screen and (min-width: @screen-lg-min) {
    .webshop-top-teaser-widget__picture-tile--small {
      height: @smallTeaserHeight!important;
      min-height: @smallTeaserHeight!important;
      max-height: @smallTeaserHeight!important;

      &:first-of-type {
        margin-bottom: @smallTeaserSpacing;
      }
    }

    .webshop-top-teaser-widget__picture-tile--large {
      height: @largeTeaserHeight !important;
      min-height: @largeTeaserHeight !important;
      max-height: @largeTeaserHeight !important;
    }
  }
}
