.@{ant-prefix}-collapse-header {
  .iconCollapse {
    transform-origin: center;
    .transitionWithReducedMotion();
  }

  // respect translateY by ant.design
  &[aria-expanded="false"] .iconCollapse {
    transform: translateY(-50%) rotate(-180deg) !important;
  }
}
