.unavailableBox {
  display: flex;
  flex-direction: column;
  padding: 0 var(--spacing-xs) var(--spacing-xs) var(--spacing-xs);
  font-size: var(--text-xs);

  .iconUnavailable {
    width: var(--icon-size-small);
    height: var(--icon-size-small);
    margin-right: @spacing-xs;
  }

  .unavailableHeading {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0 @spacing-s;
    color: var(--primary-color);
  }

  button {
    width: 100%;
    height: var(--icon-size-large);
    padding: var(--spacing-xs);
    border-radius: 5px;
    font-size: calc(var(--text-xs) + 1px);
    line-height: var(--text-xs);
  }

  button:not(.productAlternativesButton) {
    border-color: var(--secondary-color);
    background-color: var(--secondary-color);
  }

  button:not(.productAlternativesButton):hover,
  button:not(.productAlternativesButton):focus {
    color: var(--secondary-color);
  }

  button.productAlternativesButton {
    border-color: var(--table-grey);
    background-color: var(--table-grey);
    color: white;
  }

  button.productAlternativesButton:hover,
  button.productAlternativesButton:focus {
    color: var(--table-grey);
  }

  .ant-space {
    width: 100%;
    gap: var(--spacing-xs);
  }
}
