.quick-links-widget {
  .picture-tile__overlay {
    max-height: 125px;
  }

  .quick-link--large-screen {
    width: 100%;
    height: 70px;
    align-items: center;
    justify-content: center;
    padding: var(--spacing-xs);

    span {
      // stylelint-disable-next-line
      width: min-content;
      margin: 0;
      text-align: center;

      svg {
        display: none;
      }
    }
  }
}
