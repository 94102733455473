.cartItem {
  width: 100%;

  .productTitle {
    grid-area: title;
  }

  .productAdvertBadge {
    width: 100%;
    max-width: @product-image-width-sm;
    grid-area: advert;
  }

  .productImage {
    max-width: @product-image-width-sm;
    max-height: @product-image-width-sm;
    grid-area: img;
  }

  .addToListsButtons {
    display: flex;
    justify-content: center;
    gap: var(--spacing-s);

    .productInfoIcon {
      margin: 0;
    }

    .product-order-items-button {
      width: var(--nav-icon-size);
    }
  }

  .productStatusContainer,
  .singlePrice,
  .sumPrice,
  .vpe {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    white-space: nowrap;
  }

  .productCountContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    white-space: nowrap;

    .badge-already-ordered {
      margin-top: 5px;
      float: left;
    }
  }

  .totalPrice {
    font-size: @text-sm;
  }

  @media screen and (min-width: @screen-md-min) {
    .productImage {
      display: block;
    }
  }

  // "remove" padding of last item to align icons
  td:last-child {
    padding-right: 0 !important;
  }

  @media only screen and (max-width: @screen-md-max) {
    .ant-table-cell {
      border-bottom: 1px solid var(--text-color) !important;
      box-shadow: none;
    }
  }
}

.itemInfo {
  /* stylelint-disable plugin/no-unsupported-browser-features */
  display: grid;
  align-items: start;
  column-gap: @spacing-s;
  grid-template-areas:
    "title"
    "attributes"
    "insignias";
  grid-template-columns: max-content;
  row-gap: 0;

  @media screen and (min-width: @screen-md-min) {
    grid-template-areas:
      "img title"
      "img attributes"
      "advert insignias"
      "advert orderNote";
    grid-template-columns: 160px max-content;
  }
  /* stylelint-enable plugin/no-unsupported-browser-features */
}

.itemInfo--small {
  display: grid;
  width: 100%;
  align-items: center;
  /* stylelint-disable-next-line */
  column-gap: var(--spacing-sm);
  grid-template-areas:
    "title              title               title"
    ".                  divider-top         divider-top"
    "img                attributes          attributes"
    "img                attributes          attributes"
    "advert             insignias           insignias"
    ".                  divider-bottom      divider-bottom"
    "actions            single-price        quantity"
    "actions            vpe                 quantity"
    "actions            recommended-price   ."
    "orderNote          orderNote           orderNote";
  /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
  grid-template-columns: auto;
  /* stylelint-disable-next-line declaration-block-no-redundant-longhand-properties */
  grid-template-rows: auto;

  h3.productTitle {
    margin-bottom: 0;
  }

  .divider-top {
    background-color: var(--table-borders);
    grid-area: divider-top;
  }

  .divider-bottom {
    background-color: var(--table-borders);
    grid-area: divider-bottom;
  }

  .productTitle a {
    color: var(--primary-color);
  }

  .productImage {
    max-width: @product-image-width-sm;
    max-height: @product-image-width-sm;
    align-self: flex-start;
  }

  .productAdvertBadge {
    width: 100%;
    max-width: @product-image-width-md;
    grid-area: advert;
  }

  .actions {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    gap: var(--spacing-xs);
    grid-area: actions;

    .favouriteListPriceTagListCombination {
      display: flex;
      justify-content: space-between;
    }

    .cartMoveOrDeleteItemButtonCombination {
      svg {
        margin: 0;
      }

      button:nth-child(2) {
        justify-content: flex-end;
        padding-right: var(--spacing-xs);
      }
    }
  }

  .productAttributes {
    margin-top: var(--spacing-xs);
  }

  .productInsignias {
    grid-area: insignias;
    justify-self: flex-start;
  }

  .single-price {
    display: flex;
    flex-direction: column;
    grid-area: single-price;
  }

  .quantity {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: flex-end;
    align-self: flex-end;
    grid-area: quantity;

    .productCountContainer,
    .productCount,
    .ant-spin-nested-loading,
    .ant-spin-container,
    .ant-input-number.productCountInner,
    .ant-input-number-input-wrap,
    .ant-input-number-input {
      min-height: calc(@line-height-custom * 3);
      max-height: 75px;
    }

    .ant-input-number-input {
      padding: 0 var(--spacing-xxs);
      font-size: var(--text-m);
      font-weight: bold;
      text-align: center;

      @media screen and (max-width: @screen-xs-max) {
        font-size: var(--text-s);
      }
    }
  }

  .vpe {
    display: flex;
    align-items: flex-start;
    font-size: var(--text-xs);
    grid-area: vpe;
  }

  .recommended-price {
    font-size: var(--text-xs);
    grid-area: recommended-price;
  }

  @media only screen and (min-width: @screen-sm) {
    width: calc(100% - @spacing-xs);
    /* stylelint-disable-next-line plugin/no-unsupported-browser-features */
    grid-template-columns: calc(@product-image-width-md + @spacing-xs) 1fr 1fr;

    .productImage {
      max-width: @product-image-width-md;
      max-height: @product-image-width-md;
    }
  }
}

.productOrderDateContainer {
  font-weight: @font-weight-bold;
}
